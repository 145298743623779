import React, { Component } from "react";
import { withStyles } from "@material-ui/styles";

const styles = (theme) => ({
  container: {
    margin: "30px 100px 0px",
    wordBreak: "break-word",
    fontFamily: "Poppins, sans-serif",

    [theme.breakpoints.down("sm")]: {
      margin: "30px 25px 0px",
    },
    "& > p": {
      textAlign: "justify",
      fontSize: "15px",
      paddingTop: "15px",
    },
    "& > h1": {
      textAlign: "center",
      color: "$theme - green",
    },
    "& > p > ol > li": {
      padding: "10px 0 10px",
    },
  },
});

class TermsAndCondition extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <h1>Terms and Condition</h1>
        <p>
          Please read the Terms and Conditions statement thoroughly before
          proceeding. The following govern your access to and use of our
          website, products, services etc. By clicking ‘I Agree’ the USER agrees
          to be bound by them.
        </p>
        <p>
          Use of and access to the Application/Website is offered to You upon
          the condition of acceptance of all the terms, conditions and notices
          contained in this Terms of Use and Privacy Policy, along with any
          amendments made by ‘Shopsapp’ at its sole discretion and posted on the
          Application/Website from time to time.
        </p>
        <p>
          For the purposes of these Terms of Use, the term 'Shopsapp' or 'Us' or
          'We' refers to Inventlabs Innovations Private Limited. The Users,
          Delivery Partners, Merchants shall together hereinafter be referred to
          as “You”, “Your” or “Yourself”.
        </p>
        <p>
          The term 'You' refers to the user or visitor of the Website and/or
          Application. When You use our services, You will be subject to the
          terms, guidelines and policies applicable to such service and as set
          forth in these Terms of Use. As long as you comply with these Terms of
          Use, We grant You a personal, non-exclusive, non-transferable, limited
          privilege to enter and use our Application/ Website and services.
        </p>
        <p>
          The Company reserves the right, in its sole discretion to make change
          or modifications to these Terms and Conditions at any time for any
          reason whatsoever. The Changes made to the Terms and Conditions will
          be alerted to the user and right of specific notification of each and
          every change is waived by the user. The User will be deemed to be
          aware of and to have accepted any changes in the revised Terms and
          Conditions by the User’s continued use of the site after such changes
          are posted.
        </p>
        <p>
          <strong>
            By using the site, User/s hereby Represents and Warrants that:-
          </strong>
        </p>
        <p>
          <ol>
            <li>
              All information that he/she submits for using the site shall be
              true, accurate and correct.
            </li>
            <li>
              User/s shall update such registration information as and when
              required so as to maintain it’s accuracy.
            </li>
            <li>
              User/s shall use the site only as per directions given by the
              company.
            </li>
          </ol>
        </p>
        <p>
          <strong>
            USER HAS TO REGISTER WITH THE APPLICATION/WEBSITE AFTER READING AND
            AGREEING THE TERMS AND CONDITIONS CAREFULLY:-
          </strong>
        </p>
        <p>
          <ol>
            <li>
              <strong>GENERAL</strong>
            </li>
            <ol>
              <li>
                Only Individuals with age 18 or above are eligible and competent
                to use the Application/Website. However, they can use it under
                strict guidance from guardians.
              </li>
              <li>
                The user/s hereby understands that only one ID for an individual
                with Phone number, Email ID, Bank Account, PAN shall be allowed,
                the same documents shall not be used for any other individual.
              </li>
              <li>
                I/We hereby declare and affirm that being the user, he/she is
                fully aware of the nature of business of the company and this
                application/website shall be used for serving it’s business
                purposes only.
              </li>
              <li>
                I/We hereby agree/s to register in this Application/Website for
                activities related to delivery of necessary items/articles to
                the public as per the terms and conditions of the company.
              </li>
              <li>
                I/We hereby agree/s that all the applicable terms and conditions
                as agreed between me/us and the company shall be followed.
              </li>
              <li>
                I/We hereby agrees and acknowledges that the company shall not
                be responsible for any damages to the items/articles
                transferred.
              </li>
              <li>
                I/We shall agree that all transactions shall be made vide cash
                on delivery only.
              </li>
              <li>
                The company shall not be responsible for any legal issues that
                arise due to any fault/damages to the articles/items
                transferred.
              </li>
              <li>
                The company shall not be responsible for any issues that occur
                to seller in any way.
              </li>
              <li>
                I/We personally agree and understand that it is my/our personal
                responsibility to pay the tax and the tax must be paid for the
                development of the country.
              </li>
              <li>
                I/We agree with absolute consciousness that the Company deserve
                all rights to freeze My account or to recall the same, or
                transfer to another person, without my/our consent in the event
                of any actions or verbatim or campaign from my/our side against
                the company interest, policy or work.
              </li>
              <li>
                I/We agree that I/We shall abide and comply with the rules and
                regulations of the company, Members are responsible for any
                legal proceeding that is arise out of any activities without the
                consent of them Company.
              </li>
              <li>
                I/We understand and agree that GST shall be applicable for the
                transactions as per the provision of Goods and Services Tax Act.
              </li>
              <li>
                I/We understand that the Company assumes no responsibility for
                the use of the application/website in any manner as not stated
                by the Company.
              </li>
              <li>
                The User/s agree to keep the account password confidential and
                shall be responsible for all use of the account and password.
                The company reserves the right to change or remove a username if
                the company determines, in it’s sole discretion that such
                username is inappropriate, obscene or objectionable in any
                manner.
              </li>
              <li>
                The User/s shall not access the site other than those purposes
                as determined by the company. The site shall not be used for any
                other commercial purpose other than those specifically endorsed
                or approved by the company.
              </li>
            </ol>
            <li>
              <strong>DATA PROTECTION:</strong>
            </li>
            <p>
              The data or contents of the application/website shall be the
              ‘Intellectual Property’ of the company only. No user/s shall use
              it’s contents for any other use. If any user/s makes use of the
              company’s data then, the company shall be forced to take
              appropriate actions against the particular user/s.
            </p>
            <li>
              <strong>DISCLAIMER:</strong>
            </li>
            <p>
              The user/s of this application/website hereby agrees that the use
              of the same will be at his/her sole risk. The company does not
              make any warranty or representation about the accuracy about the
              app/site’s content. The user may carry out his/her own means to
              verify the same. The company will not assume any liability or
              responsibility for any error, mistake, damages etc. that might be
              caused to the user/s due to the usage of the site.
            </p>
            <li>
              <strong>LIABILITY:</strong>
            </li>
            <p>
              The Company or it’s employees shall not be liable to a user/s for
              any loss that might be caused to him/her while using the
              application/website.
            </p>
            <li>
              <strong>FORCE MAJURE:</strong>
            </li>
            <p>
              {" "}
              Due to any event, accidents, acts or omission beyond its control,
              the company or any of it’s employees shall not be liable to any
              user/s for the losses/damages cause to him/her while using the
              application/website.
            </p>
            <li>
              <strong>ELIGIBILITY:</strong>
            </li>
            <p>
              The Services are not available to minors i.e. persons under the
              age of 18 years (unless they use the application/website under
              strict guidance of guardians) or to any Users suspended or removed
              by us from accessing the Application/Website for any reason
              whatsoever. You represent that You are of legal age to form a
              binding contract and are not a person barred from receiving using
              or availing Services under the applicable laws.
            </p>
            <li>
              <strong>TERMINATION:</strong>
            </li>
            <p>
              The company reserves the right to terminate an account of any
              user/s if it finds that the particular user violates any policy of
              the company. The company does have the right to deny access to the
              application/website to any user/s for any reason if it finds that
              the particular user has breached any covenants contained in terms
              mentioned in this agreement or in any agreement executed between
              the company and the user/s. You agree that any violation by You of
              these Terms of Use will constitute an unlawful and unfair business
              practice, and will cause irreparable harm to the Company, as the
              case may be, for which monetary damages would be inadequate, and
              You consent to the Company obtaining any injunctive or equitable
              relief that they deem necessary or appropriate in such
              circumstances. These remedies are in addition to any other
              remedies that the Company may have at law or in equity. If Company
              takes any legal action against You as a result of Your violation
              of these Terms of Use, they will be entitled to recover from You,
              and You agree to pay, all reasonable attorneys’ fees and costs of
              such action, in addition to any other relief that may be granted.
            </p>
            <li>
              <strong>ADDITIONAL TERMS:</strong>
            </li>
            <p>
              We may also require You to follow additional rules, guidelines or
              other conditions in order to participate in certain promotions or
              activities available through the Application/Website. These
              additional terms shall form a part of this Terms of Use, and You
              agree to comply with them when You participate in those
              promotions, or otherwise engage in activities governed by such
              additional terms.
            </p>
            <li>
              <strong>COMMUNICATIONS:</strong>
            </li>
            <p>
              You hereby expressly agree to receive communications by way of
              SMSs and/or e-mails from us, or other third parties. You can
              unsubscribe/ opt-out from receiving communications through SMS and
              e-mail anytime by contacting us for the same. However, You may
              still receive communications from our end with respect to Your use
              of the our Service.
            </p>
            <li>
              <strong>AMENDMENTS:</strong>
            </li>
            <p>
              These Terms of Use are subject to modifications at any time. We
              reserve the right to modify or change these Terms of Use and other
              policies at any time by posting changes on the
              Application/Website, and you shall be liable to update yourself of
              such changes, if any, by accessing the changes on the
              Application/Website. You shall, at all times, be responsible for
              regularly reviewing the Terms of Use and the other policies and
              note the changes made on the Application/Website. Your continued
              usage of the services after any change is posted constitutes your
              acceptance of the amended Terms of Use and other policies. As long
              as you comply with these Terms of Use, we grant you a personal,
              non-exclusive, non-transferable, limited privilege to access,
              enter, and use the Application/Website. By accepting these Terms
              of Use, you also accept and agree to be bound by the other terms
              and conditions and policies (including but not limited to
              Cancellation & Refund Policy, Privacy Policy and Notice and Take
              Down Policy) as may be posted on the Application/website from time
              to time.
            </p>
            <li>
              <strong>TREATMENT OF INFORMATION PROVIDED BY YOU:</strong>
            </li>
            <p>
              We process information provided by you to us in accordance with
              our Privacy Policy.
            </p>
            <li>
              <strong>GOVERNING LAW AND DISPUTE RESOLUTION:</strong>
            </li>
            <p>
              These Terms of Use are governed by the laws of India. In case of
              any dispute, the same shall be referred to a sole arbitrator
              appointed by the company and his decision shall be final and will
              be binding on the parties. Any action, suit, or other legal
              proceeding, which is commenced to resolve any matter arising under
              or relating to this Application/Website, shall be subject to the
              jurisdiction of the courts at Trivandrum, India.
            </p>
            <li>
              <strong>MISCELLANEOUS:</strong>
            </li>
            <p>
              These terms of use and any policies or operating rules posted by
              us on the site constitute an agreement and understanding between
              the user and the company. The company shall not be responsible or
              liable for any loss, damage, delay etc. which are beyond our
              control caused to the user/s while using the site.
            </p>
            <li>
              <strong>CONTACT US:</strong>
            </li>
            <p>
              Please contact us for any questions or comments (including all
              inquiries unrelated) regarding this Application/Website.
            </p>
          </ol>
        </p>
      </div>
    );
  }
}

export default withStyles(styles)(TermsAndCondition);
