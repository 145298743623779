import React, { Component } from 'react'
import { Button, Grid, Typography, withStyles, CircularProgress } from '@material-ui/core'
import { connect } from 'react-redux'
import { STATUS } from 'redux/constants/index'
import treeChanges from 'tree-changes'

import { getCookie } from 'services/cookie'
import { APIEndpoints } from 'redux/constants'
import { showAlert, downloadSampleFile } from 'redux/actions'

import Modal from 'views/Modal'
import styles from './styles'

class BulkImport extends Component {
  state = {
    isFileUploadSuccess: false,
    productsAdded: 0,
    productsUpdated: 0,
    isUploading: false
  }

  componentWillReceiveProps = newProps => {
    const { changedTo } = treeChanges(this.props, newProps)
    if (changedTo('downloadSampleFile.status', STATUS.READY)) {
      var link = document.createElement('a')
      link.setAttribute('download', 'demo.csv')
      link.href = newProps.downloadSampleFile.data.standard_template_url
      document.body.appendChild(link)
      link.click()
      link.remove()
    }
  }

  getSampleFile = () => {
    this.props.dispatch(downloadSampleFile())
  }

  handleFileUpload = async (e) => {
    e.preventDefault()
    const file = e.target.files[0]
    let data = new FormData()
    data.append('file', file, file.name)
    const authToken = getCookie('auth_token')
    const token = `Bearer ${authToken}`
    const shopId = getCookie('shop_id')
    const url = APIEndpoints.UPLOAD_BULK_PRODUCTS(shopId)
    const params = {
      method: 'POST',
      body: data,
      headers: {
        'Authorization': token
      }
    }
    this.setState({ isUploading: true })
    fetch(url, params).then(async response => {
      if (response.status > 299) {
        this.props.dispatch(showAlert('File upload failed. Please try again', { variant: 'error' }))
        this.setState({ isUploading: false })
      } else {
        const result = await response.json()
        this.setState({ isUploading: false, isFileUploadSuccess: true, productsAdded: result.products_added, productsUpdated: result.products_updated })
      }
    }, () => {
      this.setState({ isUploading: false })
      this.props.dispatch(showAlert('File upload failed. Please try again', { variant: 'error' }))
    })
  }

  onClickFileUpload = event => {
    event.stopPropagation()
    event.target.value = ''
  }

  onClose = () => {
    this.setState({
      isFileUploadSuccess: false,
      productsAdded: 0,
      productsUpdated: 0,
      isUploading: false
    })
    this.props.onCancel()
  }

  render () {
    const { classes, isOpen } = this.props
    const { isUploading, productsAdded, productsUpdated, isFileUploadSuccess } = this.state
    let child = (
      <Grid className={classes.container} justifyContent='center'>
        <Grid xs={12}>
          <Typography className={classes.heading}>Upload products from CSV file</Typography>
        </Grid>
        <Grid xs={12}>
          <Typography className={classes.sampleFileText}>You can download the sample file, add products required and then upload the file below</Typography>
        </Grid>
        <Grid xs={12}>
          <Button variant='outlined' className={classes.sampleButton} onClick={this.getSampleFile}>Download Sample File</Button>
        </Grid>
        <Grid xs={12}>
          {!isFileUploadSuccess ? (
            <div className={classes.upload} >
              <input
                accept='csv/*'
                className={classes.input}
                style={{ display: 'none' }}
                id='raised-button-file'
                onClick={this.onClickFileUpload}
                onChange={(e) => this.handleFileUpload(e)}
                type='file'
              />
              <label htmlFor='raised-button-file'>
                {isUploading ? (<div className={classes.uploadButton} ><CircularProgress /></div>) : (
                  <Button variant='outlined' component='span' className={classes.uploadButton} onClick={this.onClickFileUpload}>
                    Select file to upload
                  </Button>)}
              </label>
            </div>)
            : (
              <div className={classes.uploadSuccess}>
                <img src='/images/success-icon.png' alt='success' width='75px' />
                <Typography className={classes.successText}>Products uploaded Successfully</Typography>
                <Typography className={classes.addedProducts}>{productsAdded} new products added</Typography>
                <Typography className={classes.updatedProducts}>{productsUpdated} products updated</Typography>
              </div>
            )}
        </Grid>
      </Grid>
    )

    return (
      <div>
        <Modal isOpen={isOpen} title={'BULK IMPORT'} child={child} width={950} onCancel={this.onClose} onSubmit={this.onClose} />
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    downloadSampleFile: state.products.downloadSampleFile
  }
}

export default withStyles(styles)(connect(mapStateToProps)(BulkImport))
