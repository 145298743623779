export default theme => ({
  panel: {
    border: 'none',
    boxShadow: 'none',
    background: '#F6F8FA !important'
  },
  heading: {
    paddingTop: '2px',
    fontFamily: 'Poppins, sans-serif',
    fontSize: '20px',
    fontWeight: '500'
  },
  description: {
    paddingLeft: '50px',
    fontFamily: 'Montserrat',
    opacity: '0.7'
  }
})
