import React, { Component } from 'react'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

class VideoModal extends Component {
    handleClose = () => {
      this.props.handleClose()
    }
    render () {
      const { open, handleClose } = this.props
      return (
        <Dialog onClose={handleClose} aria-labelledby='customized-dialog-title' open={open} maxWidth='md'>
          <MuiDialogTitle disableTypography>
            <IconButton aria-label='close' onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </MuiDialogTitle>
          <MuiDialogContent dividers>
            <iframe title='Youtube' width='800px' height='500px' src='https://www.youtube.com/embed/n2WMhIijiqE?rel=0' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowfullscreen />
          </MuiDialogContent>
        </Dialog>
      )
    }
}

export default VideoModal
