import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import compose from 'recompose/compose'

import ShopsAppLogo from '../../assets/icons/ShopsAppLogo'

const styles = theme => ({
  container: {
    height: '70px',
    width: '100%',
    backgroundColor: '#32a05f',
    zIndex: '100',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 20px 0',
    color: 'white',
    fontFamily: 'Poppins, sans-serif',

    position: 'fixed',
    top: '0',
    left: '0'
  },
  logo: {
    fontFamily: 'Montserrat',
    fontWeight: '600',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '& p': {
      marginLeft: '5px',
      paddingTop: '3px',
      fontFamily: 'Poppins, sans-serif'
    }
  },
  navItems: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  navItem: {
    marginLeft: '20px',
    fontSize: '15px',
    cursor: 'pointer',
    fontFamily: 'Poppins, sans-serif'
  }
})

class Header extends Component {
  render () {
    const { classes, history } = this.props

    const gotoSignup = () => {
      history.push('/sign-up')
    }

    const gotoSignin = () => {
      history.push('/sign-in')
    }

    const gotoHome = () => {
      history.push('/')
    }

    const gotoPricing = () => {
      history.push('/pricing')
    }

    return (
      <div className={classes.container}>
        <div className={classes.logo} onClick={gotoHome}>
          <ShopsAppLogo color='white' small />
          <p>ShopsApp</p>
        </div>
        <div className={classes.navItems}>
          <div className={classes.navItem} onClick={gotoPricing}>
            Pricing
          </div>
          <div className={classes.navItem} onClick={gotoSignin}>
            Sign in
          </div>
          <div className={classes.navItem} onClick={gotoSignup}>
            Register now
          </div>
        </div>
      </div>

    )
  }
}

export default compose(
  withRouter,
  withStyles(styles)
)(Header)
