export default (theme) => ({
  previewCard: {
    border: '1px solid #eee',
    padding: '20px',
    borderRadius: '12px'
  },
  previewTitle: {
    fontSize: '12px',
    marginBottom: '10px',
    fontWeight: 600,
    opacity: 0.7
  },
  planTitle: {
    fontSize: '22px',
    fontWeight: 600,
    marginBottom: '5px'
  },
  planDescription: {
    opacity: 0.7,
    fontWeight: 600,
    marginBottom: '0px'
  },
  featuresTitle: {
    fontWeight: 600,
    marginTop: '10px'
  },
  featuresList: {
    padding: '5px 20px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '14px',
    opacity: 0.7
  },
  priceBox: {
    display: 'flex',
    margin: '20px 0px'
  },
  priceTitle: {
    fontSize: '18px',
    marginRight: '10px',
    fontWeight: 600
  },
  price: {
    fontSize: '25px',
    fontWeight: 600,
    '& span': {
      fontSize: '20px',
      textDecoration: 'line-through',
      fontWeight: 400,
      marginRight: ' 5px'
    }
  },
  buttonContainer: {
    marginTop: '15px',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  cancelButton: {
    background: 'grey',
    color: 'white',
    padding: '7px 20px',
    '&:hover': {
      background: 'grey',
      opacity: '0.7'
    }
  },
  proceedButton: {
    background: '#32a05f',
    color: 'white',
    marginLeft: '10px',
    padding: '7px 20px',
    '&:hover': {
      background: '#32a05f',
      opacity: '0.7'
    }
  }
})
