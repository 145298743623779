export default theme => ({
  container: {
    width: '100%',
    overflow: 'hidden',
    height: '100%',
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' %3E%3Cpath id='Path_2' data-name='Path 2' d='M 1799 487.247 s -416 -143.247 -879 33.753 S 0 525.743 0 525.743 V -4 H 1799 Z' transform='translate(0 4)' fill='%2332a05f'/%3E%3C/svg%3E%0A")`,
    backgroundSize: 'auto',
    background: '#f9fafb'
  },
  heading: {
    fontFamily: 'Poppins, sans-serif',
    paddingTop: '100px',
    textAlign: 'center',
    color: 'white'
  },
  rectangleIcon: {
    paddingTop: '10px',
    display: 'block',
    margin: 'auto'
  },
  description: {
    fontFamily: 'Poppins, sans-serif',
    paddingTop: '20px',
    textAlign: 'center',
    color: 'white'
  },
  priceCardContainer: {
    [theme.breakpoints.down('lg')]: {
      padding: '50px 100px 0px'

    },
    [theme.breakpoints.down('md')]: {
      padding: '50px 10px 0px'

    }
  },
  customisationHeading: {
    fontFamily: 'Poppins, sans-serif',
    textAlign: 'center',
    color: 'black',
    opacity: '0.8',
    padding: '100px 20px 15px'
  },
  customisationDescription: {
    fontFamily: 'Poppins, sans-serif',
    paddingTop: '20px',
    textAlign: 'center',
    color: '#545454',
    padding: '50px 10vw 20px'
  },
  customisationPrice: {
    fontFamily: 'Poppins, sans-serif',
    padding: '50px 0px 50px',
    color: '#545454',
    lineHeight: '30px',
    fontWeight: '500',
    display: 'flex',
    paddingLeft: '10vw'
  },
  customerSupport: {
    width: '120vw',
    backgroundColor: '#C6FF9A'
  },
  customerCareIcon: {
    paddingTop: '50px'
  },
  customerSupportHeading: {
    textAlign: 'center',
    fontSize: '16px',
    fontFamily: 'Montserrat',
    fontWeight: '600',
    color: '#0F0F0F'
  },
  customerSupportSubTitle: {
    textAlign: 'center',
    fontFamily: 'Montserrat',
    fontWeight: '600',
    color: '#0F0F0F',
    lineHeight: '45px',
    [theme.breakpoints.down('lg')]: {
      padding: '30px 30vw 30px',
      fontSize: '35px'
    },
    [theme.breakpoints.down('md')]: {
      padding: '30px 20vw 30px',
      fontSize: '30px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '30px 5vw 30px'
    }
  },
  button: {
    textAlign: 'center',
    border: '3px solid #206D3F',
    borderRadius: '10px',
    color: '#206D3F',
    fontFamily: 'Montserrat',
    marginBottom: '50px',
    '&:hover': {
      boxShadow: '0 10px 17px rgba(0, 0, 0, 0.14)',
      bottom: '1px',
      right: '1px'
    }
  },
  faqContainer: {
    background: 'white',
    padding: '100px 0 100px'
  },
  faqCard: {
    background: '#F6F8FA !important',
    [theme.breakpoints.down('lg')]: {
      padding: '100px',
      width: '80vw'
    },
    [theme.breakpoints.down('md')]: {
      padding: '60px',
      width: '90vw'

    },
    [theme.breakpoints.down('sm')]: {
      padding: '50px 10px 50px',
      width: '100vw'
    }
  }
})
