/**
 * @module Sagas/User
 * @desc User
 */

import { all, put, call, takeLatest } from 'redux-saga/effects'

import { ActionTypes, APIEndpoints } from 'redux/constants/index'
import { request } from 'services/client'
import { getCookie, setCookie } from 'services/cookie'

/**
 * Signup
 */
export function * getLocationFromGeocode ({ payload }) {
  try {
    const { location } = payload
    const response = yield call(request, APIEndpoints.GEO_LOCATION(location), { method: 'GET' })
    yield put({
      type: ActionTypes.GET_GEO_LOCATION_SUCCESS,
      payload: response.results && response.results.length ? response.results[0] : {}
    })
  } catch (err) {
    yield put({
      type: ActionTypes.GET_GEO_LOCATION_FAILURE,
      payload: err
    })
  }
}

export function * getShopCategories () {
  try {
    const response = yield call(request, APIEndpoints.GET_SHOP_CATEGORIES, { method: 'GET' })
    yield put({
      type: ActionTypes.GET_SHOP_CATEGORIES_SUCCESS,
      payload: response || []
    })
  } catch (err) {
    yield put({
      type: ActionTypes.GET_SHOP_CATEGORIES_FAILURE,
      payload: err
    })
  }
}

export function * getShopTypes () {
  try {
    const response = yield call(request, APIEndpoints.GET_SHOP_TYPES, { method: 'GET' })
    yield put({
      type: ActionTypes.GET_SHOP_TYPES_SUCCESS,
      payload: response || []
    })
  } catch (err) {
    yield put({
      type: ActionTypes.GET_SHOP_TYPES_FAILURE,
      payload: err
    })
  }
}

export function * addShopDetails ({ payload }) {
  try {
    const { requestObj } = payload
    const response = yield call(request, APIEndpoints.ADD_SHOP_DETAILS, {
      method: 'POST',
      payload: requestObj
    })
    setCookie('shop_id', response.id)
    setCookie('shop_url', response.shop_url)
    yield put({
      type: ActionTypes.SET_USER_INFO,
      payload: response
    })
    yield put({
      type: ActionTypes.ADD_SHOP_DETAILS_SUCCESS,
      payload: response || []
    })
  } catch (err) {
    yield put({
      type: ActionTypes.ADD_SHOP_DETAILS_FAILURE,
      payload: err
    })
  }
}

export function * addShopAddress ({ payload }) {
  try {
    const { requestObj, isAdd } = payload
    const shopId = getCookie('shop_id')
    const requestUrl = isAdd ? APIEndpoints.ADD_SHOP_ADDRESS(shopId) : APIEndpoints.EDIT_SHOP_ADDRESS(shopId)
    const response = yield call(request, requestUrl, {
      method: 'POST',
      payload: requestObj
    })

    yield put({
      type: ActionTypes.ADD_SHOP_ADDRESS_SUCCESS,
      payload: response
    })
  } catch (err) {
    yield put({
      type: ActionTypes.ADD_SHOP_ADDRESS_FAILURE,
      payload: err
    })
  }
}

/**
 * User Sagas
 */
export default function * root () {
  yield all([
    takeLatest(ActionTypes.GET_GEO_LOCATION, getLocationFromGeocode),
    takeLatest(ActionTypes.GET_SHOP_CATEGORIES, getShopCategories),
    takeLatest(ActionTypes.GET_SHOP_TYPES, getShopTypes),
    takeLatest(ActionTypes.ADD_SHOP_DETAILS, addShopDetails),
    takeLatest(ActionTypes.ADD_SHOP_ADDRESS, addShopAddress)
  ])
}
