import React, { Component } from 'react'
import { withStyles, Grid } from '@material-ui/core'

import { PriceCard } from 'views/Pricing/components'

import style from './styles'

class SubscriptionPlans extends Component {
  render () {
    const { classes, plans, onClickSubscribe } = this.props
    return (
      <Grid
        container
        justify='space-around'
        className={classes.priceCardContainer}
      >
        {plans.data.map((plan) => {
          return (
            <Grid>
              <PriceCard
                planType='Getting Started'
                planTitle={plan.name}
                planPrice={plan.price}
                actualPrice={plan.actual_price}
                idealFor={plan.ideal_for}
                planDescription={plan.description}
                features={plan.features}
                isMobile={false}
                background='light'
                onClickSubscribe={() => onClickSubscribe(plan)}
                buttonText='Subscribe'
              />
            </Grid>
          )
        })}
      </Grid>
    )
  }
}

export default withStyles(style)(SubscriptionPlans)
