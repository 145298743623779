// @flow
/**
 * @module Actions/User
 * @desc User Actions
 */
import { createActions } from 'redux-actions'

import { ActionTypes } from 'redux/constants/index'

export const { productGetList,productGetVariants,productGetDetails, uploadProductImage, updateProduct, deleteProduct, createProduct, productSearchResult, changeStockStatus, downloadSampleFile } = createActions({
  [ActionTypes.PRODUCT_GET_LIST]: (skip, limit, searchString, filter) => ({ skip, limit, searchString, filter }),
  [ActionTypes.PRODUCT_GET_VARIANTS]: (skip, limit, searchString, filter) => ({ skip, limit, searchString, filter }),
  [ActionTypes.PRODUCT_GET_DETAILS]: (id) => ({ id }),
  [ActionTypes.UPDATE_PRODUCT]: (product) => ({ product }),
  [ActionTypes.DELETE_PRODUCT]: (id) => ({ id }),
  [ActionTypes.CREATE_PRODUCT]: (product) => ({ product }),
  [ActionTypes.PRODUCT_SEARCH_RESULT]: (searchString) => ({ searchString }),
  [ActionTypes.CHANGE_STOCK_STATUS]: (statusRequest) => ({ statusRequest }),
  [ActionTypes.DOWNLOAD_SAMPLE_FILE]: () => ({ })
})
