const style = theme => ({
  container: {
    overflow: 'auto',
    maxHeight: '70vh',
    position: 'relative'
  },
  field: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 20
  },
  textField: {
    width: 'calc( 50% - 10px )',
    maxWidth: '100%'
  },
  bulk: {
    width: 'calc( 50% - 10px )',
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  bulkTextField: {
    width: '100%',
    '&:nth-child(2)': {
      marginTop: 20
    }
  },
  bulkCheckField: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 15
  },
  check: {
    width: 'calc( 50% - 40px )',
    display: 'flex'
  },
  checkHeading: {
    lineHeight: '38px',
    fontWeight: 'bold',
    width: 'calc( 100% - 40px )',
    fontSize: 15,
    paddingLeft: 10
  },
  imageHover: {
    display: 'none',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    background: '#00000069'
  },
  image: {
    paddingTop: '100%',
    boxShadow: '1px 0px 8px -5px #888888',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    position: 'relative',
    minWidth: 133,
    minHeight: 133,
    '&:hover': {
      '& > div': {
        display: 'block'
      }
    },
    '& button:first-child': {
      position: 'absolute',
      right: 10,
      top: 10,
      padding: 0,
      '& path:first-child': {
        fill: '#fff'
      }
    }
  },
  defaultStatus: {
    position: 'absolute',
    left: 20,
    bottom: 20
  },
  upload: {
    marginTop: 20
  },
  imageContainer: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    padding: 20,
    borderRadius: '4px',
    marginTop: 20
  },
  imageloader: {
    position: 'absolute',
    left: 48,
    top: 48
  },
  rank: {
    display: 'flex',
    justifyContent: 'space-between',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: '4px',
    padding: 20,
    marginTop: 20,
    marginBottom: 2
  },
  categories: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: '4px',
    padding: 20,
    marginTop: 20,
    marginBottom: 2
  },
  heading: {
    paddingBottom: 20,
    fontSize: 15
  },
  variationName: {
    marginTop: 10,
    marginBottom: 10,
    textAlign: 'center'
  },
  progressWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  editButton: {
    position: 'absolute',
    right: 16
  },
  imageLoadingText: {
    position: 'absolute',
    top: 14,
    width: '100%',
    textAlign: 'center',
    color: 'red',
    fontWeight: 'bold'
  }

})

export default style
