export default theme => ({
  container: {
    padding: 10,
    height: 498,
    overflow: 'auto',
    display: 'flex',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
  },
  progressWrapper: {
    width: '100%',
    paddingTop: '48px',
    paddingBottom: '24px',
    display: 'flex',
    justifyContent: 'center'
  },

  title: {
    display: 'flex',
    justifyContent: 'space-between',
    '& h4:last-child': {
      textAlign: 'right'
    }
  },
  titleText: {
    padding: '5px 10px 0px 20px',
    width: '100%',
    fontSize: '17px'
  },
  detailsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 10
  },
  subDetailsContainer: {
    border: '1px solid #d8d8d8',
    padding: 20,
    marginBottom: 10
  },
  locationButtons: {
    display: 'flex',
    padding: 10,
    justifyContent: 'space-between'
  },
  transactionButton: {
    display: 'flex',
    padding: 10,
    '& button': {
      width: '100%'
    }
  },
  locationButton: {
    width: 'calc( 40% - 10px )',
    '&:first-child': {
      width: 'calc( 60% - 10px )'
    }
  },
  item: {
    fontWeight: 'bold',
    float: 'right'
  },
  deliveryBy: {
    marginBottom: 10
  },
  products: {
    maxHeight: '50vh',
    overflow: 'auto',
    marginTop: 10
  },
  productCell: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  cellItem: {
    marginBottom: '15px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&:first-child': {
      width: '90%'
    }
  },
  dateText: {
    color: theme.palette.text.secondary
  },
  productPrice: {
    marginTop: '20px',
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'space-between',
    '& p': {
      fontWeight: 'bold'
    }
  },
  customerHeading: {
    fontWeight: 'bold'
  },
  customerDetails: {
    position: 'relative',
    marginTop: 10,
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  address: {
    display: 'flex',
    flexDirection: 'column'
  },
  subCustomerDetails: {
    marginBottom: 5
  },
  subAddress: {
    marginBottom: 2
  },
  location: {
    position: 'absolute',
    right: 5
  },
  bill: {
    [theme.breakpoints.down('sm')]: {
      borderTop: '1px solid #eee',
      paddingTop: '25px !important'
    }
  },
  billDetails: {
    width: '100%',
    '&:nth-child(5)': {
      marginTop: 8
    },
    '&:nth-child(2)': {
      marginTop: 10
    }
  },
  paymentDetails: {
    marginTop: 16,
    '&.not_paid span:last-child': {
      color: 'red'
    },
    '&.paid span:last-child': {
      color: 'green'
    }
  },

  paymentLinkButton: {
    width: '100%',
    marginTop: 10
  },
  billTitle: {
    fontWeight: 'bold',
    paddingBottom: 5
  },
  productHeading: {
    fontWeight: 'bold',
    marginBottom: 5
  },
  editButton: {
    marginLeft: '10px',
    top: '-5px',
    float: 'right',
    border: '1px solid #eee'
  },
  action: {
    boxShadow: '0px 1px 8px -3px #888888',
    width: '100%',
    display: 'flex',
    height: 56,
    justifyContent: 'space-between',
    padding: '10px 20px',
    '& .bill': {
      display: 'none'
    }
  },
  iconButtons: {
    position: 'absolute',
    right: 20,
    '& > button': {
      marginLeft: 10
    },
    '&> button:last-child': {
      marginLeft: 20
    }
  },
  footerIcons: {
    width: 30,
    height: 30
  },

  printBill: {
    padding: 40
  },
  billHeading: {
    padding: '20px 10px 20px',
    marginBottom: '20px',
    border: '1px solid gray',
    display: 'flex',
    justifyContent: 'space-between',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
  },
  invoiceHeading: {
    padding: '0px 20px 10px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '50px'

  },
  billLeftHeading: {
    width: '50%',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
  },
  billRightHeading: {
    display: 'flex',
    flexDirection: 'column',
    paddingRight: '20px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
  },
  billBody: {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
  },
  billCharges: {
    width: '100%',
    marginTop: 20,
    border: '1px solid gray',
    padding: '20px 0px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
  },
  billSubDetailsContainer: {
    width: '60%',
    marginLeft: '40%'
  },
  billDetailsCon: {
    display: 'flex',
    padding: '2px 20px',
    justifyContent: 'space-between',
    fontSize: '18px'
  },
  billSubHeading: {
    width: '50%',
    textAlign: 'right',
    fontSize: '18px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
  },
  billSubitem: {
    width: '30%',
    textAlign: 'left',
    fontWeight: 'bold',
    fontSize: '17px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
  },
  billSubHeadingText: {
    padding: '5px 10px 0px 20px',
    textAlign: 'left',
    width: '100%',
    fontSize: '17px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
  },
  tableCell: {
    lineHeight: '22px',
    fontSize: '17px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    '& svg': {
      width: 80,
      height: 40,
      marginRight: 5
    }
  },
  tableHeaderCell: {
    lineHeight: '18px !important',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    '&:first-child': {
      width: '60%'
    }
  },
  tableRow: {
    height: 40
  }

})
