import React, { Component } from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import {
  getPlanPricing,
  getSubscriptionPaymentLink,
  showAlert
} from 'redux/actions'

import { withStyles, Grid } from '@material-ui/core'

import SubscriptionPlans from '../SubscriptionPlans'
import SubscriptionPreview from '../SubscriptionPreview'
import {
  Portlet,
  PortletContent,
  PortletHeader,
  PortletLabel
} from 'components'

import style from './styles'

class Subscription extends Component {
  state = {
    isPlanSelected: false,
    selectedPlan: ''
  };
  componentDidMount () {
    this.props.dispatch(getPlanPricing())
  }
  onClickSubscribe = (plan) => {
    this.setState({ isPlanSelected: true, selectedPlan: plan })
    this.props.dispatch(getSubscriptionPaymentLink(plan.code))
  };
  onCancelSubscribe = () => {
    this.setState({ isPlanSelected: false, selectedPlan: '' })
  };
  onClickProceed = () => {
    if (this.props.paymentDetails.data.payment_link) {
      window.location.href = this.props.paymentDetails.data.payment_link
    } else {
      this.props.dispatch(
        showAlert('Failed to fetch payment link', { variant: 'error' })
      )
    }
  };
  render () {
    const { classes, plans, paymentDetails, ...rest } = this.props
    const { isPlanSelected, selectedPlan } = this.state
    return (
      <div className={classes.root}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Portlet {...rest} className={classes.planContainer}>
              <PortletHeader>
                <PortletLabel title='Choose your plan' />
              </PortletHeader>
              <PortletContent className={classes.planContentContainer}>
                {!isPlanSelected && !selectedPlan ? (
                  <SubscriptionPlans
                    plans={plans}
                    onClickSubscribe={this.onClickSubscribe}
                  />
                ) : (
                  <SubscriptionPreview
                    selectedPlan={selectedPlan}
                    onCancelSubscribe={this.onCancelSubscribe}
                    onClickProceed={this.onClickProceed}
                  />
                )}
              </PortletContent>
            </Portlet>
          </Grid>
        </Grid>
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    plans: state.plan.planPricing,
    paymentDetails: state.plan.paymentDetails
  }
}

export default compose(withStyles(style))(
  connect(mapStateToProps)(Subscription)
)
