import auth from './auth'
import app from './app'
import product from './product'
import order from './order'
import category from './category'
import analytics from './analytics'
import address from './address'
import settings from './settings'
import delivery from './delivery'
import cadets from './cadets'
import reservations from './reservations'
import plan from './plan'

export default {
  ...auth,
  ...app,
  ...product,
  ...order,
  ...category,
  ...analytics,
  ...address,
  ...settings,
  ...delivery,
  ...cadets,
  ...reservations,
  ...plan
}
