import React, { Component } from "react";
import { withStyles } from "@material-ui/styles";
import { Divider, Grid, Typography } from "@material-ui/core";
import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";
import YouTubeIcon from "@material-ui/icons/YouTube";

const style = (theme) => ({
  footerContainer: {
    background: "#32A05F",
    padding: "50px 0px 60px",
    marginTop: "100px",
  },
  section: {
    padding: "60px 0px 60px",
    paddingLeft: "100px",
    [theme.breakpoints.down("sm")]: {
      padding: "25px 50px 20px",
    },
  },
  heading: {
    color: "white",
    fontWeight: "600",
    fontFamily: "Poppins, sans-serif",
  },
  text: {
    cursor: "pointer",
    color: "white",
    paddingTop: "10px",
    fontSize: "14px",
    opacity: 0.7,
    lineHeight: "15px",
    fontFamily: "Poppins, sans-serif",
  },
  footer: {
    width: "100%",
    textAlign: "center",
  },
  socialMediaIcons: {
    marginTop: "20px",
  },
  socialMediaIcon: {
    textDecoration: "none",
    marginRight: "10px",
    "& svg": {
      fill: "white",
    },
  },
});

class Footer extends Component {
  render() {
    const { classes } = this.props;

    const openShopsApp = () => {
      window.open("https://www.shopsapp.in");
    };

    const openEmail = () => {
      window.open("mailto:hello@inventlabs.org");
    };

    const openPrivacyPolicy = () => {
      window.open("https://seller.shopsapp.in/privacy-policy");
    };

    const openTerms = () => {
      window.open("https://seller.shopsapp.in/terms-and-conditions");
    };

    return (
      <Grid
        className={classes.footerContainer}
        container
        spacing={8}
        direction="row"
        justify="space-around"
        alignItems="flex-start"
      >
        <Grid xs={12} sm={6} md={4} lg={4} className={classes.section}>
          <Typography className={classes.heading}>Developed By</Typography>
          <Typography className={classes.text}>
            Inventlabs Innovations Pvt. Ltd.
          </Typography>
          <Typography className={classes.text}>
            G3B, Thejaswini, Technopark Campus
          </Typography>
          <Typography className={classes.text}>
            Trivandrum, Kerala - 695581
          </Typography>
          <Typography className={classes.text} onClick={openShopsApp}>
            www.inventlabs.org
          </Typography>
        </Grid>
        <Grid xs={12} sm={6} md={4} lg={4} className={classes.section}>
          <Typography className={classes.heading}>Contact us</Typography>
          <Typography className={classes.text}>Call: +91 7736043121</Typography>
          <Typography className={classes.text} onClick={openEmail}>
            Email: hello@inventlabs.org
          </Typography>
          <Grid className={classes.socialMediaIcons}>
            <a
              className={classes.socialMediaIcon}
              href="https://www.instagram.com/shopsapponline/"
              target="blank"
            >
              <InstagramIcon />
            </a>
            <a
              className={classes.socialMediaIcon}
              href="https://www.facebook.com/shopsapponline"
              target="blank"
            >
              <FacebookIcon />
            </a>
            <a
              className={classes.socialMediaIcon}
              href="https://www.youtube.com/channel/UCMB23JvImH6DniU77nDFhxA"
              target="blank"
            >
              <YouTubeIcon />
            </a>
          </Grid>
        </Grid>
        <Grid xs={12} sm={6} md={4} lg={4} className={classes.section}>
          <Typography className={classes.heading}>Legal</Typography>
          <Typography className={classes.text} onClick={openPrivacyPolicy}>
            Privacy Policy
          </Typography>
          <Typography className={classes.text} onClick={openTerms}>
            Terms and Condition
          </Typography>
        </Grid>
        <Grid className={classes.footer}>
          <Divider />
          <Typography className={classes.text}>
            ©{new Date().getFullYear()} ShopsApp. All rights reserved
          </Typography>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(style)(Footer);
