import React, { Component } from 'react'
import { Grid, Typography, withStyles, Button } from '@material-ui/core'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { getPlanPricing } from 'redux/actions'

import Header from '../../components/Header'
import { PriceCard, FeaturesTable } from './components/index'
import Footer from '../Home/components/Footer'
import style from './style'

class Pricing extends Component {
  state = {
    isMobile: false
  };

  componentDidMount() {
    this.props.dispatch(getPlanPricing())
    const android = navigator.userAgent.match(/Android/i)
    const ios = navigator.userAgent.match(/iPhone|iPod/i)
    if (android || ios) {
      this.setState({ isMobile: true })
    }
  }

  gotoSignup = () => {
    const { history } = this.props
    history.push('/sign-up')
  };

  render() {
    const { classes, plans } = this.props
    const { isMobile } = this.state
    return (
      <>
        <Header />
        <div>
          <Grid container className={classes.container}>
            <Grid xs='12' justify='center'>
              <Typography variant='h2' className={classes.heading}>
                Pricing
              </Typography>
              <img
                src='/images/rectangle-icon.svg'
                alt=''
                className={classes.rectangleIcon}
              />
              <Typography variant='subtitle1' className={classes.description}>
                Lets find the right plan for you
              </Typography>
            </Grid>
            <Grid
              container
              justify='space-around'
              className={classes.priceCardContainer}
            >
              {plans.data.map((plan) => {
                return (
                  <Grid gutturBottom>
                    <PriceCard
                      planTitle={plan.name}
                      planPrice={plan.price}
                      actualPrice={plan.actual_price}
                      planDescription={plan.description}
                      idealFor={plan.ideal_for}
                      features={plan.features}
                      isMobile={isMobile}
                      onClickSubscribe={this.gotoSignup}
                      buttonText='Get started'
                    />
                  </Grid>
                )
              })}
            </Grid>
            {/* {!isMobile ? <FeaturesTable /> : null} */}
            <Grid
              container
              justify='center'
              className={classes.customerSupport}
            >
              <Grid className={classes.customerCareIcon} justify='center'>
                <img
                  src='/images/customer-care-icon.svg'
                  alt='customer-care'
                  width='35px'
                />
              </Grid>
              <Grid xs={12} justify='center'>
                <Typography className={classes.customerSupportHeading}>
                  TALK WITH OUR EXPERTS
                </Typography>
              </Grid>
              <Grid xs={12} justify='center'>
                <Typography className={classes.customerSupportSubTitle}>
                  Not sure what’s the best plan for you?
                </Typography>
              </Grid>
              <Grid justify='center'>
                <Button
                  href='tel:+917736043121'
                  variant='outlined'
                  size='large'
                  className={classes.button}
                >
                  Contact Us
                </Button>
              </Grid>
              <Grid xs={12}>
                <Footer />
              </Grid>
            </Grid>
          </Grid>
        </div>
      </>
    )
  }
}

function mapStateToProps(state) {
  return {
    plans: state.plan.planPricing
  }
}

export default compose(
  withRouter,
  withStyles(style)
)(connect(mapStateToProps)(Pricing))
