
export default theme => ({
  container: {
    height: '70vh',
    overflowY: 'auto',
    scrollbarWidth: 'thin',
    overflowX: 'hidden'
  },
  imageContainer: {
    width: '100%',
    maxHeight: 300,
    display: 'flex',
    justifyContent: 'center'
  },
  image: {
    maxHeight: 300,
    maxWidth: 300,
    objectFit: 'contain'
  },
  list: {
    display: 'flex',
    justifyContent: 'center',
    width: 140
  },
  imageListContainer: {
    alignItems: 'center',
    width: 100,
    overflow: 'auto',
    maxHeight: 300,
    padding: '0 10px',
    borderLeft: '1px solid #eee',
    '&::-webkit-scrollbar': { width: '0 !important' }
  },
  miniImage: {
    height: 80,
    width: 80,
    margin: '10px 0 0',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    cursor: 'pointer',
    border: '4px solid #e9e9e9',
    opacity: 0.6
  },
  selected: {
    opacity: 1
  },
  details: {
    marginTop: 30
  },
  detailsCell: {
    display: 'flex',
    marginBottom: 13
  },
  key: {
    width: 150,
    fontSize: 14
  },
  value: {
    marginLeft: 10,
    flex: 1,
    color: 'black',
    fontSize: 14,
    lineHeight: '18px'
  },
  valueBold: {
    fontWeight: 420
  },
  footer: {
    width: '100%',
    padding: '15px 20px',
    display: 'flex',
    justifyContent: 'space-between',
    boxShadow: '1px 0px 8px -5px #888888'
  },
  stock: {
    width: 280,
    fontWeight: 500,
    fontSize: 13,
    marginLeft: 10,
    display: 'flex',
    justifyContent: 'space-between'
  },
  stockHeading: {
    width: 50,
    lineHeight: '38px',
    fontSize: 15,
    fontWeight: 'bold'
  },
  switch: {
    color: '#7d58ff',
    '&$checked': {
      color: '#7d58ff'
    },
    '&$checked + $track': {
      backgroundColor: '#7d58ff'
    }
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 150
  },
  progressWrapper: {
    position: 'absolute',
    top: 160,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center'
  },
  variantbox:{
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 10,
  },
  variants: {
    marginLeft: 10,
    fontSize: 14,
    //height of variantbox
  }

})
