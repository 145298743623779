import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

class Deeplinks extends Component {
  componentDidMount () {
    const query = new URLSearchParams(this.props.location.search)
    const orderId = query.get('oid')
    if (orderId) {
      this.props.history.push(`/orders/${orderId}`)
    }
  }
  render () {
    return (
      <div />
    )
  }
}

export default withRouter(Deeplinks)
