// @flow
/**
 * @module Actions/App
 * @desc App Actions
 */

import { createActions } from 'redux-actions'

import { ActionTypes } from 'redux/constants/index'

export const { getPlanPricing, getSubscriptionPaymentLink } = createActions({
  [ActionTypes.GET_PLAN_PRICING]: () => ({}),
  [ActionTypes.GET_SUBSCRIPTION_PAYMENT_LINK]: (planId) => ({ planId })
})
