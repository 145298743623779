import { handleActions } from 'redux-actions'
import immutable from 'immutability-helper'
import { parseError } from 'services/client'
import _ from 'lodash'

import { ActionTypes, STATUS } from 'redux/constants/index'

export const settingsState = {
  addShopLogo: {
    message: '',
    data: {},
    status: STATUS.IDLE
  },
  shopDetails: {
    message: '',
    shop: {},
    user: {},
    status: STATUS.IDLE
  },
  updateShop: {
    message: '',
    data: {},
    status: STATUS.IDLE
  },
  updateUser: {
    message: '',
    data: {},
    status: STATUS.IDLE
  },
  editAddress: {
    message: '',
    data: {},
    status: STATUS.IDLE
  },
  deliveryDetails: {
    message: '',
    data: {},
    status: STATUS.IDLE
  },
  editDeliverySettings: {
    message: '',
    data: {},
    status: STATUS.IDLE
  },
  paymentDetails: {
    message: '',
    data: {},
    status: STATUS.IDLE
  },
  editPaymentSettings: {
    message: '',
    data: {},
    status: STATUS.IDLE
  },
  editAcceptStatus: {
    message: '',
    data: {},
    status: STATUS.IDLE
  },
  changePassword: {
    message: '',
    data: {},
    status: STATUS.IDLE
  },
  getOrderReport: {
    message: '',
    data: {},
    status: STATUS.IDLE
  },
  sendPaymentReminder: {
    message: '',
    data: {},
    status: STATUS.IDLE
  }
}

export default {
  settings: handleActions(
    {
      [ActionTypes.ADD_SHOP_LOGO]: (state, { payload }) =>
        immutable(state, {
          addShopLogo: {
            status: { $set: STATUS.RUNNING }
          }
        }),
      [ActionTypes.ADD_SHOP_LOGO_SUCCESS]: (state, { payload }) => {
        const shopDetails = _.cloneDeep(state.shopDetails)
        if (shopDetails.shop && shopDetails.shop.id) {
          shopDetails.shop.image_url = payload.url
        }
        return immutable(state, {
          shopDetails: { $set: shopDetails },
          addShopLogo: {
            data: { $set: payload },
            status: { $set: STATUS.READY }
          }
        })
      },
      [ActionTypes.ADD_SHOP_LOGO_FAILURE]: (state, { payload }) =>
        immutable(state, {
          addShopLogo: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        }),

      [ActionTypes.GET_SHOP_DETAILS]: (state, { payload }) =>
        immutable(state, {
          shopDetails: {
            status: { $set: STATUS.RUNNING }
          }
        }),
      [ActionTypes.GET_SHOP_DETAILS_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          shopDetails: {
            shop: { $set: payload.shop },
            user: { $set: payload.user },
            status: { $set: STATUS.READY }
          }
        }),
      [ActionTypes.GET_SHOP_DETAILS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          shopDetails: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        }),

      [ActionTypes.UPDATE_SHOP_SETTINGS]: (state) =>
        immutable(state, {
          updateShop: {
            status: { $set: STATUS.RUNNING }
          }
        }),
      [ActionTypes.UPDATE_SHOP_SETTINGS_SUCCESS]: (state) => {
        return immutable(state, {
          updateShop: {
            status: { $set: STATUS.READY }
          }
        })
      },
      [ActionTypes.UPDATE_SHOP_SETTINGS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          updateShop: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        }),

      [ActionTypes.UPDATE_USER_SETTINGS]: (state) =>
        immutable(state, {
          updateUser: {
            status: { $set: STATUS.RUNNING }
          }
        }),
      [ActionTypes.UPDATE_USER_SETTINGS_SUCCESS]: (state) => {
        return immutable(state, {
          updateUser: {
            status: { $set: STATUS.READY }
          }
        })
      },
      [ActionTypes.UPDATE_USER_SETTINGS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          updateUser: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        }),

      [ActionTypes.EDIT_SHOP_ADDRESS]: (state) =>
        immutable(state, {
          editAddress: {
            status: { $set: STATUS.RUNNING }
          }
        }),
      [ActionTypes.EDIT_SHOP_ADDRESS_SUCCESS]: (state, { payload }) => {
        // let { shop } = _.cloneDeep(state.shopDetails)
        // shop.address = payload
        return immutable(state, {
          // shopDetails: {
          //   shop: { $set: shop },
          //   status: { $set: STATUS.READY }
          // },
          editAddress: {
            status: { $set: STATUS.READY }
          }
        })
      },
      [ActionTypes.EDIT_SHOP_ADDRESS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          editAddress: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        }),

      [ActionTypes.GET_DELIVERY_DETAILS]: (state, { payload }) =>
        immutable(state, {
          deliveryDetails: {
            status: { $set: STATUS.RUNNING }
          }
        }),
      [ActionTypes.GET_DELIVERY_DETAILS_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          deliveryDetails: {
            data: { $set: payload.delivery_option },
            status: { $set: STATUS.READY }
          }
        }),
      [ActionTypes.GET_DELIVERY_DETAILS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          deliveryDetails: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        }),

      [ActionTypes.UPDATE_DELIVERY_SETTINGS]: (state) =>
        immutable(state, {
          editDeliverySettings: {
            status: { $set: STATUS.RUNNING }
          }
        }),
      [ActionTypes.UPDATE_DELIVERY_SETTINGS_SUCCESS]: (state, { payload }) => {
        let { data } = _.cloneDeep(state.deliveryDetails)
        data = payload
        return immutable(state, {
          deliveryDetails: {
            data: { $set: data },
            status: { $set: STATUS.READY }
          },
          editDeliverySettings: {
            status: { $set: STATUS.READY }
          }
        })
      },
      [ActionTypes.UPDATE_DELIVERY_SETTINGS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          editDeliverySettings: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        }),

      [ActionTypes.GET_PAYMENT_DETAILS]: (state, { payload }) =>
        immutable(state, {
          paymentDetails: {
            status: { $set: STATUS.RUNNING }
          }
        }),
      [ActionTypes.GET_PAYMENT_DETAILS_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          paymentDetails: {
            data: { $set: payload },
            status: { $set: STATUS.READY }
          }
        }),
      [ActionTypes.GET_PAYMENT_DETAILS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          paymentDetails: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        }),

      [ActionTypes.UPDATE_PAYMENT_SETTINGS]: (state) =>
        immutable(state, {
          editPaymentSettings: {
            status: { $set: STATUS.RUNNING }
          }
        }),
      [ActionTypes.UPDATE_PAYMENT_SETTINGS_SUCCESS]: (state, { payload }) => {
        let { data } = _.cloneDeep(state.paymentDetails)
        data = payload
        return immutable(state, {
          paymentDetails: {
            data: { $set: data },
            status: { $set: STATUS.READY }
          },
          editPaymentSettings: {
            status: { $set: STATUS.READY }
          }
        })
      },
      [ActionTypes.UPDATE_PAYMENT_SETTINGS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          editPaymentSettings: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        }),

      [ActionTypes.SEND_PAYMENT_REMINDER]: (state, { payload }) =>
        immutable(state, {
          sendPaymentReminder: {
            status: { $set: STATUS.RUNNING }
          }
        }),
      [ActionTypes.SEND_PAYMENT_REMINDER_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          sendPaymentReminder: {
            data: { $set: payload.payment_option },
            status: { $set: STATUS.READY }
          }
        }),
      [ActionTypes.SEND_PAYMENT_REMINDER_FAILURE]: (state, { payload }) =>
        immutable(state, {
          sendPaymentReminder: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        }),

      [ActionTypes.CHANGE_ACCEPT_STATUS]: (state) =>
        immutable(state, {
          editAcceptStatus: {
            status: { $set: STATUS.RUNNING }
          }
        }),
      [ActionTypes.CHANGE_ACCEPT_STATUS_SUCCESS]: (state, { payload }) => {
        let { shop } = _.cloneDeep(state.shopDetails)
        shop.is_accepting_orders = payload.is_accepting_orders
        return immutable(state, {
          shopDetails: {
            shop: { $set: shop },
            status: { $set: STATUS.READY }
          },
          editAcceptStatus: {
            status: { $set: STATUS.READY }
          }
        })
      },
      [ActionTypes.CHANGE_ACCEPT_STATUS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          editAcceptStatus: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        }),

      [ActionTypes.CHANGE_PASSWORD]: (state) =>
        immutable(state, {
          changePassword: {
            status: { $set: STATUS.RUNNING }
          }
        }),
      [ActionTypes.CHANGE_PASSWORD_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          changePassword: {
            status: { $set: STATUS.READY }
          }
        })
      },
      [ActionTypes.CHANGE_PASSWORD_FAILURE]: (state, { payload }) =>
        immutable(state, {
          changePassword: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        }),

      [ActionTypes.GET_ORDER_REPORT]: (state) =>
        immutable(state, {
          getOrderReport: {
            status: { $set: STATUS.RUNNING }
          }
        }),
      [ActionTypes.GET_ORDER_REPORT_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          getOrderReport: {
            status: { $set: STATUS.READY },
            data: { $set: payload }
          }
        })
      },
      [ActionTypes.GET_ORDER_REPORT_FAILURE]: (state, { payload }) =>
        immutable(state, {
          getOrderReport: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        })

    },
    settingsState
  )
}
