import { all, fork } from 'redux-saga/effects'

import auth from './auth'
import app from './app'
import product from './product'
import order from './order'
import category from './category'
import analytics from './analytics'
import address from './address'
import settings from './settings'
import delivery from './delivery'
import cadets from './cadets'
import reservations from './reservations'
import plan from './plan'

/**
 * rootSaga
 */
export default function * root () {
  yield all([
    fork(auth),
    fork(app),
    fork(product),
    fork(order),
    fork(category),
    fork(analytics),
    fork(address),
    fork(settings),
    fork(delivery),
    fork(cadets),
    fork(reservations),
    fork(plan)
  ])
}
