import React, { Component } from 'react'
import { Typography, withStyles, Grid, Button } from '@material-ui/core'

import style from './styles'

class SubscriptionPreview extends Component {
  render () {
    const {
      classes,
      selectedPlan,
      onCancelSubscribe,
      onClickProceed
    } = this.props
    return (
      <Grid container justifyContent='center'>
        <Grid className={classes.previewCard}>
          <Typography className={classes.previewTitle}>
            SUBSCRIPTION PREVIEW
          </Typography>
          <Typography className={classes.planTitle}>
            {selectedPlan.name}
          </Typography>
          <Typography className={classes.planDescription}>
            {selectedPlan.description}
          </Typography>
          <Typography className={classes.featuresTitle}>Ideal for</Typography>
          <ul className={classes.featuresList}>
            {selectedPlan.ideal_for.map((shop) => {
              return <li key={shop}>{shop}</li>
            })}
          </ul>
          <Typography className={classes.featuresTitle}>Features</Typography>
          <ul className={classes.featuresList}>
            {selectedPlan.features.map((feature) => {
              return <li key={feature}>{feature}</li>
            })}
          </ul>
          <div className={classes.priceBox}>
            <Typography className={classes.priceTitle}>Price:</Typography>
            <Typography className={classes.price}>
              <span>₹{selectedPlan.actual_price}</span>₹{selectedPlan.price}
            </Typography>
          </div>
          <div className={classes.buttonContainer}>
            <Button
              className={classes.cancelButton}
              onClick={onCancelSubscribe}
            >
              Cancel
            </Button>
            <Button className={classes.proceedButton} onClick={onClickProceed}>
              Proceed to pay
            </Button>
          </div>
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(style)(SubscriptionPreview)
