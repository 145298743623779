import React, { Component } from "react";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  withStyles,
  Button,
} from "@material-ui/core";
import { FreePlanTable, BasicPlanTable, EnterprisePlanTable } from "..";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";

import style from "./style";

class PriceCard extends Component {
  state = {
    openFreePlanFeatures: false,
    openBasicPlanFeatures: false,
    openEnterprisePlanFeatures: false,
  };

  openFreePlan = () => {
    this.setState({ openFreePlanFeatures: !this.state.openFreePlanFeatures });
  };

  openBasicPlan = () => {
    this.setState({ openBasicPlanFeatures: !this.state.openBasicPlanFeatures });
  };

  openEnterprisePlan = () => {
    this.setState({
      openEnterprisePlanFeatures: !this.state.openEnterprisePlanFeatures,
    });
  };

  render() {
    const {
      classes,
      planType,
      planTitle,
      planPrice,
      planDescription,
      features,
      isMobile,
      background,
      onClickSubscribe,
      buttonText,
      actualPrice,
      idealFor,
    } = this.props;
    const {
      openFreePlanFeatures,
      openBasicPlanFeatures,
      openEnterprisePlanFeatures,
    } = this.state;
    return (
      <div>
        <Card
          className={[
            classes.priceCard,
            background === "light" && classes.priceCardBackground,
          ]}
        >
          <CardContent>
            <Typography className={classes.planType}>{planType}</Typography>
            <Grid container>
              <Grid xs="10">
                <Typography className={classes.planTitle}>
                  {planTitle}
                </Typography>
              </Grid>
              <Grid xs="2">
                <div className={classes.icon} />
              </Grid>
            </Grid>
            <Typography className={classes.planPrice}>
              {actualPrice ? <span>₹{actualPrice}</span> : null}₹{planPrice}
            </Typography>
            <Typography className={classes.planDescription}>
              {planDescription}
            </Typography>
            {idealFor && (
              <Typography className={classes.planFeatures}>
                <Typography className={classes.subHeading}>
                  Ideal for
                </Typography>
                <ul>
                  {idealFor.map((shop) => {
                    return <li key={shop}>{shop}</li>;
                  })}
                </ul>
              </Typography>
            )}
            {features && (
              <Typography className={classes.planFeatures}>
                <Typography className={classes.subHeading}>Features</Typography>
                <ul>
                  {features.map((feature) => {
                    return <li>{feature}</li>;
                  })}
                </ul>
              </Typography>
            )}
            <Button
              className={classes.subscribeButton}
              onClick={onClickSubscribe}
            >
              {buttonText}
            </Button>
          </CardContent>
          {/* {this.props.planTitle === 'Silver Plan' && isMobile ? (
            !openFreePlanFeatures ? (
              <Typography
                className={classes.planShowButton}
                onClick={this.openFreePlan}
              >
                {' '}
                <AddIcon className={classes.plusIcon} />
                Show plan features
              </Typography>
            ) : (
              <Typography
                className={classes.planShowButton}
                onClick={this.openFreePlan}
              >
                <RemoveIcon className={classes.plusIcon} /> Hide plan features
              </Typography>
            )
          ) : null}
          {this.props.planTitle === 'Gold Plan' && isMobile ? (
            !openBasicPlanFeatures ? (
              <Typography
                className={classes.planShowButton}
                onClick={this.openBasicPlan}
              >
                {' '}
                <AddIcon className={classes.plusIcon} />
                Show plan features
              </Typography>
            ) : (
              <Typography
                className={classes.planShowButton}
                onClick={this.openBasicPlan}
              >
                <RemoveIcon className={classes.plusIcon} /> Hide plan features
              </Typography>
            )
          ) : null}
          {this.props.planTitle === 'Platinum Plan' && isMobile ? (
            !openEnterprisePlanFeatures ? (
              <Typography
                className={classes.planShowButton}
                onClick={this.openEnterprisePlan}
              >
                {' '}
                <AddIcon className={classes.plusIcon} />
                Show plan features
              </Typography>
            ) : (
              <Typography
                className={classes.planShowButton}
                onClick={this.openEnterprisePlan}
              >
                <RemoveIcon className={classes.plusIcon} /> Hide plan features
              </Typography>
            )
          ) : null}

          {this.props.planTitle === 'Silver Plan' && openFreePlanFeatures ? (
            <FreePlanTable />
          ) : null}
          {this.props.planTitle === 'Gold Plan' && openBasicPlanFeatures ? (
            <BasicPlanTable />
          ) : null}
          {this.props.planTitle === 'Platinum Plan' && openEnterprisePlanFeatures ? <EnterprisePlanTable /> : null} */}
        </Card>
      </div>
    );
  }
}

export default withStyles(style)(PriceCard);
