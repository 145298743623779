import React, { Component } from 'react'
import { Button } from '@material-ui/core'

import { requestVerification, showAlert, getAccountStatus } from 'redux/actions'
import { connect } from 'react-redux'
import treeChanges from 'tree-changes'
import { STATUS } from 'redux/constants'
import { getCookie } from 'services/cookie'

class Submit extends Component {
  componentWillReceiveProps = newProps => {
    const { changedTo } = treeChanges(this.props, newProps)
    if (changedTo('requestVerification.status', STATUS.READY)) {
      this.props.dispatch(showAlert('Requested for review', { variant: 'success' }))
      this.props.dispatch(getAccountStatus())
    }
    if (changedTo('updateProduct.status', STATUS.ERROR)) {
      this.props.dispatch(showAlert('Unable to submit request', { variant: 'error' }))
    }
  }

  onSubmit = () => {
    const shopId = getCookie('shop_id')
    this.props.dispatch(requestVerification(shopId))
  }
  render () {
    return (
      <div>
        <Button
          variant='contained'
          color='primary'
          onClick={this.onSubmit}
          disabled={this.props.requested}
        >
          {this.props.requested ? 'Wating for review' : 'Sumbit for review'}
        </Button>
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    requestVerification: state.analytics.requestVerification
  }
}

export default (connect(mapStateToProps)(Submit))
