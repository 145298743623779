import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles, Typography, Link } from '@material-ui/core'
import Modal from 'views/Modal'

const styles = theme => ({
  container: {
    // display: 'flex',
    '& .react-datetime-picker': {
      width: '100%',
      height: 35,
      marginTop: 20,
      '& > div': {
        borderRadius: '2px'
      }
    }
  },
  storeImage: {
    height: '60px',
    marginRight: '20px',
    [theme.breakpoints.down('xs')]: {
      height: 45
    }
  },
  appStoreImage: {
    height: '60px',
    [theme.breakpoints.down('xs')]: {
      height: 45
    }
  },
  store: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 40
  },
  mobile: {
    marginBottom: 20
  },
  desktop: {
    color: 'red'
  },
  storeContainer: {
    display: 'flex'
  },
  userAppItem: {
    marginBottom: '10px'
  }
})

const shopPlayStore = 'https://play.google.com/store/apps/details?id=org.inventlabs.shopsapp.business'
const appStore = 'https://apps.apple.com/in/app/shopsapp-for-merchants/id1538128400'

class MobileWarning extends Component {
  render () {
    const { classes, onCancel, isOpen, isAndroid, isIOs } = this.props
    const child = <div className={classes.container} >
      {isAndroid ? <div className={classes.store} >
        <Typography className={classes.mobile} variant='p'>Use desktop browser for better experience.
          <br />
          Download ShopsApp mobile app.</Typography>
        <div className={classes.storeContainer}>
          <Link
            href={shopPlayStore}
            className={classes.userAppItem}
            rel='nofollow noopener'
          >
            <img
              src='images/play-store.png'
              alt='Play Store'
              className={classes.storeImage}
            />
          </Link>
        </div>
      </div>
        : null}
      {isIOs ? <div className={classes.store} >
        <Typography className={classes.mobile} variant='p'>Use desktop browser for better experience.
          <br />
          Download ShopsApp mobile app.</Typography>
        <div className={classes.storeContainer}>
          <Link
            href={appStore}
            className={classes.userAppItem}
            rel='nofollow noopener'
          >
            <img
              src='images/app-store-logo.png'
              alt='Play Store'
              className={classes.appStoreImage}
            />
          </Link>
        </div>
      </div>
        : null}
      {!isAndroid && !isIOs ? <div className={classes.store} >
        <Typography className={classes.mobile} variant='p'>Use desktop browser for better experience.
          <br />
          Download ShopsApp mobile app.</Typography>
        <div className={classes.storeContainer}>
          <Link
            href={shopPlayStore}
            className={classes.userAppItem}
            rel='nofollow noopener'
          >
            <img
              src='images/play-store.png'
              alt='Play Store'
              className={classes.storeImage}
            />
          </Link>
          <Link
            href={appStore}
            className={classes.userAppItem}
            rel='nofollow noopener'
          >
            <img
              src='images/app-store-logo.png'
              alt='Play Store'
              className={classes.appStoreImage}
            />
          </Link>
        </div>
      </div>
        : null}
      {/* <Typography className={classes.desktop} variant='p'>Please use desktops/laptops for better experience.</Typography> */}
    </div>
    return (
      <Modal isOpen={isOpen} title={'Notice'} onCancel={onCancel} okText={'Done'} onSubmit={onCancel} child={child} width={'95vw'} />
    )
  }
}

MobileWarning.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired
}

export default withStyles(styles)(MobileWarning)
