import React, { Component } from 'react'

// Externals
import PropTypes from 'prop-types'

import { Modal as MUIModal, Button, withStyles } from '@material-ui/core'

// Component styles
const styles = theme => ({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    outline: 'none !important',
    overflow: 'scroll'
  },
  paper: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: 4,
    outline: 'none !important',
    minWidth: '1100px',
    [theme.breakpoints.down('sm')]: {
      minWidth: '100vw'
    }
  },
  header: {
    width: '100%',
    padding: '15px 20px',
    boxShadow: '0px 1px 8px -5px #888888'
    // borderBottom: '1px solid gray'
  },
  body: {
    width: '100%',
    minHeight: 150,
    padding: 20
  },
  footer: {
    width: '100%',
    padding: '15px 20px',
    display: 'flex',
    justifyContent: 'flex-end',
    boxShadow: '1px 0px 8px -5px #888888'
  },
  button: {
    marginRight: 20
  }
})

class Modal extends Component {
  render () {
    const { classes, isOpen, onCancel, okText, cancelText, title, description, child, modalClass, width, hideCancel, onSubmit, footer } = this.props
    return (
      <MUIModal
        disableBackdropClick
        open={!!isOpen}
        className={`${classes.modal} ${modalClass || ''}`}
        onClose={onCancel}
        aria-labelledby='modal-title'
        aria-describedby='modal-description'
      >
        <div className={classes.paper} style={{ width: width }}>
          <div className={classes.header} >
            <h3 id='modal-title'>
              {title}
            </h3>
          </div>
          <div className={classes.body} >
            {description ? <p id='modal-description' /> : null}
            {child || null}
          </div>

          {!footer ? <div className={classes.footer} >
            {!hideCancel ? <Button className={classes.button} size='small' variant='contained' onClick={onCancel} > {cancelText || 'Cancel'} </Button> : null}
            <Button size='small' variant='contained' color='primary' onClick={onSubmit} > {okText || 'OK'} </Button>
          </div> : null}
          {footer}
        </div>

      </MUIModal>
    )
  }
}

Modal.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(Modal)
