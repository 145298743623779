/**
 * @module Sagas/User
 * @desc User
 */

import { all, put, call, takeLatest } from 'redux-saga/effects'

import { ActionTypes, APIEndpoints } from 'redux/constants/index'
import { getCookie } from 'services/cookie'
import { request } from 'services/client'

export function * getPlanPricing (action) {
  try {
    const response = yield call(request, APIEndpoints.GET_PLAN_PRICING, {
      method: 'GET'
    })
    yield put({
      type: ActionTypes.GET_PLAN_PRICING_SUCCESS,
      payload: response.plans
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_PLAN_PRICING_FAILURE,
      payload: err
    })
  }
}

export function * getSubscriptionPaymentLink ({ payload }) {
  const { planId } = payload
  const shopId = getCookie('shop_id')
  try {
    const response = yield call(
      request,
      APIEndpoints.GET_SUBSCRIPTION_PAYMENT_LINK(shopId, planId),
      {
        method: 'GET'
      }
    )
    yield put({
      type: ActionTypes.GET_SUBSCRIPTION_PAYMENT_LINK_SUCCESS,
      payload: response
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_SUBSCRIPTION_PAYMENT_LINK_FAILURE,
      payload: err
    })
  }
}

export default function * root () {
  yield all([
    takeLatest(ActionTypes.GET_PLAN_PRICING, getPlanPricing),
    takeLatest(ActionTypes.GET_SUBSCRIPTION_PAYMENT_LINK, getSubscriptionPaymentLink)
  ])
}
