import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles'

const styles = theme => ({
  container: {
    margin: '30px 100px 0px',
    wordBreak: 'break-word',
    fontFamily: 'Poppins, sans-serif',

    [theme.breakpoints.down('sm')]: {
      margin: '30px 25px 0px'
    },
    '& > p': {
      textAlign: 'justify',
      fontSize: '15px',
      paddingTop: '15px'
    },
    '& > h1': {
      textAlign: 'center',
      color: '$theme - green'
    },
    '& > p > ol > li': {
      padding: '10px 0 10px'
    }
  }
})

class PrivacyAndPolicy extends Component {
  render() {
    const { classes } = this.props
    return (
      <div className={classes.container}>
        <h1>Privacy Policy</h1>
        <p>Please ensure that this Privacy Policy is perused by you before availing any services from us. This Privacy Policy shall be updated from time to time and to stay abreast with our methods of using your information and protecting your privacy, please keep reviewing this Policy.</p>
        <p>INVENTLABS INNOVATIONS PRIVATE LIMITED, a private limited company with its registered office at Kazhakuttom, Nalumukku, Trivandrum-695582, Kerala, India (“Company”, "We", "Us", "Our" and terms of similar meaning) is committed to protecting your privacy. For the purposes of this Privacy Policy, the Users, Delivery Partners, Merchants (as defined in the Terms of Use) shall together hereinafter be referred to as “You”, “Your” or “Yourself”.</p>
        <p>The Company has adopted this Privacy Policy to set out the manner in which personal data and other information is collected, received, stored, processed, disclosed, transferred, dealt with and handled by the Company in relation to your use of our services through the website www. www.shopsapp.net (“Website”) or the mobile application Shops (“App”), (collectively referred to as the “Platform”) or initiating or undertaking any transaction on the Platform. This Privacy Policy does not apply to information that You provide to, or that is collected by, any third-party through the Platform, and any third-party site that You access or use in connection with the Services offered on the Platform.</p>
        <p>Please read the Privacy Policy carefully before using or registering on the Platform or accessing any material, information or availing any services (“Services”) through the Platform. By clicking on the “I accept” button on the landing page, you accept this Privacy Policy and agree to be legally bound by the same.</p>
        <p>This Privacy Policy is incorporated into and subject to Our Terms of Use (“Terms”) and shall be read harmoniously and in conjunction with the Terms. In thello@inventlabs.orgonflict between the Terms and this Privacy Policy, the provisions of the Terms shall supersede the Privacy Policy. All capitalized terms not defined under this Privacy Policy shall have the meaning ascribed to it under the applicable Terms.</p>
        <p>Please feel free to direct any questions or concerns regarding this Privacy Policy by contacting us through this Platform or by writing to us at <strong>hello@inventlabs.org</strong>.</p>
        <p><strong>STATEMENT OF PRIVACY PRACTICE</strong></p>
        <p>Your information is collected and processed in accordance with the terms and conditions of this Privacy Policy.</p>
        <p><strong>INFORMATION COLLECTION AND USAGE</strong></p>
        <p>
          <ul>
            <li>Information you provide to us</li>
          </ul>
        </p>
        <p>Personally identifiable information: Create or update your Shop account, which may include your name, email, phone number, login name and password, address, payment or banking information, date of birth and profile picture.</p>
        <p>Provide content to us, which may include reviews, ordering details and history, favorite vendors, special merchant requests, contact information of people you refer to us and other information you provide on the Platform (“Your Content”).</p>
        <p>The app does use third party services that may collect information used to identify you.</p>
        <p>
          <ul>
            <li>Log Data</li>
          </ul>
        </p>
        <p>We want to inform you that whenever you use our Service, in a case of an error in the app we collect data and information (through third party products) on your phone called Log Data. This Log Data may include information such as your device Internet Protocol (“IP”) address, device name, operating system version, the configuration of the app when utilizing our Service, the time and date of your use of the Service, and other statistics.</p>
        <p>
          <ul>
            <li>
              Cookies
            </li>
          </ul>
        </p>
        <p>Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites that you visit and are stored on your device's internal memory.</p>
        <p>This Service does not use these “cookies” explicitly. However, the app may use third party code and libraries that use “cookies” to collect information and improve their services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this Service.</p>
        <p>
          <ul>
            <li>
              Service Providers
            </li>
          </ul>
        </p>
        <p>We may employ third-party companies and individuals due to the following reasons:</p>
        <p>
          <ul>
            <li>To facilitate our Service</li>
            <li>To provide the Service on our behalf</li>
            <li>To perform Service-related services, or</li>
            <li>To assist us in analyzing how our Service is used.</li>
          </ul>
        </p>
        <p>We want to inform users of this Service that these third parties have access to your Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.</p>
        <p>
          <ul>
            <li>Uses of your Information</li>
          </ul>
        </p>
        <p>We use the information we collect for the purposes, including:</p>
        <p>
          <ul>
            <li>To provide, personalise, maintain and improve our products and services, such as to enable deliveries and other services, enable features to personalise your account;</li>
            <li>To carry out our obligations arising from any contracts entered into between you and us and to provide you with the relevant information and services;</li>
            <li>To administer and enhance the security of our Platform and for internal operations, including troubleshooting, data analysis, testing, research, statistical and survey purposes;</li>
            <li>To understand our users (what they do on our Services, what features they like, how they use them, etc.), improve the content and features of our Services (such as by personalizing content to your interests), process and complete your transactions, make special offers, provide customer support, process and respond to your queries;</li>
            <li>To generate and review reports and data about, and to conduct research on, our user base and Service usage patterns;</li>
            <li>To allow you to participate in interactive features of our Services, if any; or</li>
            <li>To measure or understand the effectiveness of advertising we serve to you and others, and to deliver relevant advertising to you.</li>
            <li>If you are a partner or merchant or delivery partner, to track the progress of delivery or status of the order placed by our customers.</li>
            <li>We may share your information with any present or future member of our “Group” (as defined below)or affiliates for our internal business purposes. The term “Group” means, with respect to any person, any entity that is controlled by such person, or any entity that controls such person, or any entity that is under common control with such person, whether directly or indirectly, or, in the case of a natural person, any Relative (as such term is defined in the Companies Act, 1956 and Companies Act, 2013 to the extent applicable) of such person.</li>
          </ul>
        </p>
        <p><strong>OPT-OUT</strong></p>
        <p>When you sign up for an account, you are opting in to receive emails from us. You can log in to manage your email preferences or you can follow the “unsubshello@inventlabs.orgns in commercial email messages, but note that you cannot opt out of receiving certain administrative notices, service notices, or legal notices from us.</p>
        <p>If you wish to withdraw your consent for the use and disclosure of your personal information in the manner provided in this Policy, please write to us at [hello@inventlabs.org]. Please note that we may take time to process such requests, and your request shall take effect no later than 7 (Seven) business days from the receipt of such request, after which we will not use your personal data for any processing unless required by us to comply with our legal obligations. We may not be able offer you any or all Services upon such withdrawal of your consent.</p>
        <p><strong>SECURITY</strong></p>
        <p>We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.</p>
        <p><strong>LINKS TO OTHER SITES</strong></p>
        <p>This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.</p>
        <p><strong>TRANSPARENCY AND CHOICE</strong></p>
        <p>Your acknowledgement.; All information disclosed by You shall be deemed to be disclosed willingly and without any coercion. No liability pertaining to the authenticity/genuineness/ misrepresentation/ fraud/ negligence, etc. of the information disclosed shall lie on the Company nor will the Company in any way be responsible to verify any information obtained from You.</p>
        <p><strong>CHILDREN’S PRIVACY</strong></p>
        <p>These Services do not address anyone under the age of 18. We do not knowingly collect personally identifiable information from children under 18. In the case we discover that a child under 18 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to do necessary actions.</p>
        <p><strong>COMPLAINTS</strong></p>hello@inventlabs.org
        <p>In case of any dissatisfaction in relation to the Platform, You shall first file a formal complaint with the customer service of the Company, prior to pursuing any other recourse. The complaints can be lodged at hello@inventlabs.org, and upon lodging a complaint You agree to provide complete support to the customer service team with such reasonable information as may be sought by them from You. The decision of the Company on the complaints shall be final and You agree to be bound by the same.</p>
        <p><strong>CHANGES TO THIS PRIVACY POLICY</strong></p>
        <p>We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately after they are posted on this page.</p>
        <p><strong>GRIEVANCES</strong></p>
        <p>In the event You have any grievances relating to the Privacy Policy, please inform within 24 hours of occurrence of the instance from which the grievance has arisen, by writing an email to the Grievance Officer at grievances@ shopsapp.net.</p>
      </div>
    )
  }
}

export default withStyles(styles)(PrivacyAndPolicy)
