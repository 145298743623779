import React, { Component } from 'react'

import classNames from 'classnames'
import PropTypes from 'prop-types'

import { Typography, withStyles } from '@material-ui/core'
import { Paper } from 'components'
import styles from './styles'

class TotalOrders extends Component {
  render () {
    const { classes, className, heading, count, today, week, month, ...rest } = this.props

    const rootClassName = classNames(classes.root, className)

    return (
      <Paper
        {...rest}
        className={rootClassName}
      >
        <div className={classes.content}>
          <div className={classes.details}>
            <Typography
              className={classes.title}
              variant='body2'
            >
                            Today
            </Typography>
            <Typography
              className={classes.value}
              variant='h3'
            >
                            No of orders : {today?.number_of_orders ? today.number_of_orders : 0}
            </Typography>
            <Typography
              className={classes.value}
              variant='h3'
            >
                            Total amount : ₹{today?.total_amount ? today.total_amount : 0}
            </Typography>
          </div>
          <div className={classes.details}>
            <Typography
              className={classes.title}
              variant='body2'
            >
                            This week
            </Typography>
            <Typography
              className={classes.value}
              variant='h3'
            >
                            No of orders : {week?.number_of_orders ? week.number_of_orders : 0}
            </Typography>
            <Typography
              className={classes.value}
              variant='h3'
            >
                            Total amount : ₹{week?.total_amount ? week.total_amount : 0}
            </Typography>
          </div>
          <div className={classes.details}>
            <Typography
              className={classes.title}
              variant='body2'
            >
                            This month
            </Typography>
            <Typography
              className={classes.value}
              variant='h3'
            >
                            No of orders : {month?.number_of_orders_month ? month.number_of_orders_month : 0}
            </Typography>
            <Typography
              className={classes.value}
              variant='h3'
            >
                            Total amount : ₹{month?.total_amount_month ? month.total_amount_month : 0}
            </Typography>
          </div>
        </div>
      </Paper>
    )
  }
}

TotalOrders.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(TotalOrders)
