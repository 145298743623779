// @flow
/**
 * @module Actions/User
 * @desc User Actions
 */
import { createActions } from 'redux-actions'

import { ActionTypes } from 'redux/constants/index'

export const { orderGetList, completedOrderGetList, confirmOrder, confirmPacked, confirmDelivery, confirmDelivered, updateDelivery, cancelOrder, orderGetDetails, getOrderLocation, changePaymentStatus, changeItemQuantity } = createActions({
  [ActionTypes.ORDER_GET_LIST]: (skip, limit, status, searchString, startingDate, endingDate) => ({ skip, limit, status, searchString, startingDate, endingDate }),
  [ActionTypes.COMPLETED_ORDER_GET_LIST]: (skip, limit, status, searchString, startingDate, endingDate) => ({ skip, limit, status, searchString, startingDate, endingDate }),
  [ActionTypes.CONFIRM_ORDER]: (orderId, message, deliveryBy, productPrice) => ({ orderId, message, deliveryBy, productPrice }),
  [ActionTypes.CONFIRM_PACKED]: (orderId, message) => ({ orderId, message }),
  [ActionTypes.CONFIRM_DELIVERY]: (orderId, message) => ({ orderId, message }),
  [ActionTypes.CONFIRM_DELIVERED]: (orderId, message) => ({ orderId, message }),
  [ActionTypes.UPDATE_DELIVERY]: (orderId, message, deliveryBy) => ({ orderId, message, deliveryBy }),
  [ActionTypes.CANCEL_ORDER]: (orderId, reason) => ({ orderId, reason }),
  [ActionTypes.ORDER_GET_DETAILS]: (orderId) => ({ orderId }),
  [ActionTypes.GET_ORDER_LOCATION]: () => ({}),
  [ActionTypes.CHANGE_PAYMENT_STATUS]: (orderId, requestObj) => ({ orderId, requestObj }),
  [ActionTypes.CHANGE_ITEM_QUANTITY]: (shopId, orderId, requestObj) => ({ shopId, orderId, requestObj })
})
