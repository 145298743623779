import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles, Grid, CircularProgress, Typography, Button, TextField, Popover } from '@material-ui/core'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { STATUS } from 'redux/constants/index'
import { OrderListCell, OrderDetails } from '..'
import { orderGetList } from 'redux/actions/index'
import PerfectScrollbar from 'react-perfect-scrollbar'
import treeChanges from 'tree-changes'
import Select from 'components/Select'
import { SearchInput } from 'components'

const itemsPerPage = 20

const statusList = [{
  id: 'all',
  value: 'All'
}, {
  id: 'paid',
  value: 'Paid'
}, {
  id: 'not_paid',
  value: 'Payment Pending'
}, {
  id: 'confirmed',
  value: 'New'
}, {
  id: 'shop_confirmed',
  value: 'Confirmed'
}, {
  id: 'shop_packed',
  value: 'Packed'
}, {
  id: 'shop_delivery',
  value: 'Out for Delivery'
}
]

const styles = theme => ({
  root: {
    padding: `${theme.spacing.unit * 2}px 0px`,
    height: 'calc( 100vh - 170px )',
    overflowY: 'auto',
    paddingBottom: 0
  },
  progressWrapper: {
    position: 'absolute',
    top: '48px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    '&>div': {
      zIndex: 100
    }
  },
  innerContainer: {
    padding: 0,
    margin: 0,
    width: '100%'
  },
  rootcontainer: {
    position: 'relative'
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginTop: 10
  },
  filterContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    width: '100%',
    '& > div': {
      width: 180,
      marginRight: 20
    }
  },
  dateFilterButton: {
    margin: '0px 10px 0'
  },
  dateFilterContainer: {
    '& .MuiPopover-paper': {
      overflow: 'hidden',
      padding: '20px 30px 20px'
    }
  }
})

class ActiveOrders extends Component {
  state = {
    tabIndex: 0,
    page: 0,
    status: 'all',
    openFilter: null,
    startingDate: null,
    endingDate: null
  };

  componentDidMount () {
    this.signal = true
    this.setState({ isLoading: true })
    this.props.dispatch(orderGetList(0, itemsPerPage))
    if (this.props.match.params.id) {
      this.setState({ selectedOrder: this.props.match.params.id, showDetails: true })
    }
  }

  componentWillReceiveProps = newProps => {
    const { changedTo } = treeChanges(this.props, newProps)
    if (changedTo('orders.status', STATUS.READY)) {
      this.setState({ isLoading: false })
    }
  }

  onSelectOrder = id => {
    this.setState({ selectedOrder: id, showDetails: true })
  }

  hideDetails = () => {
    this.setState({ selectedOrder: null, showDetails: false })
  }

  handleFieldChange = (field, value) => {
    this.setState({ [field]: value })
  }

  onChangeSearchString = event => {
    const { value } = event.target
    this.setState({ searchString: value })
  }

  addHoursToDate = (prevDate) => {
    return new Date(prevDate).setHours(23, 59, 59, 0)
  }

  getTimeStamp = (date) => {
    var myDate = date
    myDate = myDate.split('-')
    var newDate = new Date(myDate[0], myDate[1] - 1, myDate[2])
    return newDate.getTime()
  }

  handlefilter = () => {
    const { startingDate, endingDate } = this.state
    if (startingDate && endingDate) {
      this.props.dispatch(orderGetList(0, itemsPerPage, '', '', this.getTimeStamp(startingDate), this.addHoursToDate(endingDate)))
      this.setState({ openFilter: false })
    }
  }

  keyPressed = event => {
    if (event.key === 'Enter') {
      this.onClickSearch()
    }
  }

  onClickSearch = () => {
    const { isLoading, status, searchString } = this.state
    if (!isLoading) {
      this.setState({ page: 0, isLoading: true }, () => {
        this.props.dispatch(orderGetList(0, itemsPerPage, status, searchString))
      })
    }
  }

  onReachedBottom = () => {
    const { orders } = this.props
    const { isLoading, page, status, searchString, startingDate, endingDate } = this.state
    const nextIndex = (page + 1) * itemsPerPage
    if (!isLoading && !orders.reachedEnd) {
      this.setState({ page: page + 1, isLoading: true }, () => {
        this.props.dispatch(orderGetList(nextIndex, itemsPerPage, status, searchString, this.getTimeStamp(startingDate), this.addHoursToDate(endingDate)))
      })
    }
  }

  onClickRefresh = () => {
    this.props.dispatch(orderGetList(0, itemsPerPage))
  }

  handleOpenFilter = (e) => {
    this.setState({ openFilter: e.currentTarget })
  }

  render () {
    const { classes, orders } = this.props
    const { showDetails, selectedOrder, status, searchString } = this.state
    const open = Boolean(this.state.openFilter)
    const id = open ? 'simple-popover' : undefined

    return (
      <div className={classes.rootcontainer} >
        {orders.status === STATUS.RUNNING ? <div className={classes.progressWrapper}>
          <CircularProgress />
        </div> : null}
        {orders.data.length === 0 && orders.status === STATUS.READY
          ? <div className={classes.progressWrapper}>
            <Typography variant='h6'>There are no active orders.</Typography>
          </div> : null}
        <div className={classes.actionsContainer}>
          <div className={classes.filterContainer}>
            <SearchInput
              className={classes.searchInput}
              placeholder='Search Order'
              value={searchString}
              onKeyPress={this.keyPressed}
              onChange={this.onChangeSearchString}
            />
            <Select placeholder='Status' options={statusList} valueParam='value' keyParam='id' selectedValue={status} handleChange={(event) => this.handleFieldChange('status', event.target.value)} />
            <Button className={classes.searchButton} color='primary' size='small' variant='outlined' onClick={this.onClickSearch} >
              Search
            </Button>
          </div>
          <div>
            <Button className={classes.dateFilterButton} color='primary' size='small' variant='outlined' onClick={this.handleOpenFilter}>
              Filter
            </Button>
            <Popover
              id={id}
              open={id}
              anchorEl={this.state.openFilter}
              onClose={this.handleCloseFilter}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
              className={classes.dateFilterContainer}
            >
              <Grid container spacing={2} alignItems='center'>
                <Grid xs={5} item>
                  <TextField
                    id='date'
                    label='Starting Date'
                    type='date'
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={this.state.statingDate}
                    onChange={e => this.setState({ startingDate: e.target.value })}
                  />
                </Grid>
                <Grid xs={5} item>
                  <TextField
                    id='date'
                    label='Ending Date'
                    type='date'
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={this.state.endingDate}
                    onChange={e => this.setState({ endingDate: e.target.value })}
                  />
                </Grid>
                <Grid xs={2} item>
                  <Button color='primary' size='small' variant='contained' onClick={this.handlefilter} disableElevation>
                    Done
                  </Button>
                </Grid>
              </Grid>
            </Popover>
          </div>
          <div>
            <Button className={classes.refreshButton} color='primary' size='small' variant='outlined' onClick={this.onClickRefresh} >
              Refresh
            </Button>
          </div>
        </div>
        <div className={classes.root}>
          {orders.data.length ? <PerfectScrollbar onYReachEnd={this.onReachedBottom}>
            <Grid
              container
              spacing={2}
              className={classes.innerContainer}
            >
              {orders.data.map(order => (
                <Grid
                  key={order.id}
                  item
                  lg={4}
                  md={6}
                  xl={4}
                  sm={6}
                  xs={12}
                >
                  <OrderListCell order={order} onSelectOrder={this.onSelectOrder} />
                </Grid>
              ))}
            </Grid>
          </PerfectScrollbar> : null}
        </div>
        <OrderDetails isOpen={showDetails} orderId={selectedOrder} onSubmit={this.hideDetails} />
      </div >
    )
  }
}

ActiveOrders.propTypes = {
  classes: PropTypes.object.isRequired
}

function mapStateToProps (state) {
  return {
    orders: state.orders.list
  }
}

export default compose(
  withRouter,
  withStyles(styles)
)(connect(mapStateToProps)(ActiveOrders))
