export default theme => ({
  root: {},
  details: {
    display: 'flex'
  },
  info: {
    overflow: 'hidden',
    '& > h3': {
      maxWidth: '100%',
      wordWrap: 'break-word'
    }
  },
  locationText: {
    marginTop: theme.spacing.unit,
    color: theme.palette.text.secondary
  },
  dateText: {
    color: theme.palette.text.secondary
  },
  avatar: {
    position: 'relative',
    marginLeft: 'auto',
    height: '110px',
    width: '110px',
    flexShrink: 0,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    flexGrow: 0,
    '&:hover > div': {
      display: 'flex'
    }
  },
  edit: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'none',
    zIndex: 5,
    background: 'black',
    opacity: 0.5,
    justifyContent: 'center',
    alignItems: 'center'
  },
  editIcon: {

  },
  progressWrapper: {
    marginTop: theme.spacing.unit * 2
  },
  uploadButton: {
    marginRight: theme.spacing.unit * 2
  },
  editLogo: {
    height: 20,
    width: 100
  },
  logobutton: {
    height: 36,
    width: 140,
    lineHeight: '26px',
    padding: '5px 10px',
    borderRadius: 4,
    border: '1px solid gray',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: 14,
    textAlign: 'center',
    cursor: 'pointer'
  },
  acceptStatus: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  statusTitle: {
    lineHeight: '38px'
  },
  loading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    background: '#8080809e'
  }

})
