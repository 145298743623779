/**
 * @module Sagas/User
 * @desc User
 */

import { all, put, call, takeLatest } from 'redux-saga/effects'

import { ActionTypes, APIEndpoints } from 'redux/constants/index'
import { request } from 'services/client'
import { getCookie } from 'services/cookie'

/**
 * Signup
 */

export function * orderGetList ({ payload }) {
  try {
    const { limit, skip, searchString, status, startingDate, endingDate } = payload
    const shopId = getCookie('shop_id')
    const response = yield call(request, APIEndpoints.ORDER_LIST(shopId, limit, skip, status, searchString, startingDate, endingDate), {
      method: 'GET'
    })
    yield put({
      type: ActionTypes.ORDER_GET_LIST_SUCCESS,
      payload: response.orders
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.ORDER_GET_LIST_FAILURE,
      payload: err
    })
  }
}

export function * completedOrderGetList ({ payload }) {
  try {
    const { limit, skip, searchString, status, startingDate, endingDate } = payload
    const shopId = getCookie('shop_id')
    const response = yield call(request, APIEndpoints.COMPLETED_ORDER_LIST(shopId, limit, skip, status, searchString, startingDate, endingDate), {
      method: 'GET'
    })
    yield put({
      type: ActionTypes.COMPLETED_ORDER_GET_LIST_SUCCESS,
      payload: response.orders
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.COMPLETED_ORDER_GET_LIST_FAILURE,
      payload: err
    })
  }
}

export function * orderGetDetails ({ payload }) {
  try {
    const { orderId } = payload
    const shopId = getCookie('shop_id')
    const response = yield call(request, APIEndpoints.ORDER_GET_DETAILS(shopId, orderId), {
      method: 'GET'
    })
    yield put({
      type: ActionTypes.ORDER_GET_DETAILS_SUCCESS,
      payload: response.order
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.ORDER_GET_DETAILS_FAILURE,
      payload: err
    })
  }
}

export function * confirmOrder ({ payload }) {
  try {
    const { orderId, message, deliveryBy } = payload
    const shopId = getCookie('shop_id')
    const response = yield call(request, APIEndpoints.CONFIRM_ORDER(shopId, orderId), {
      method: 'POST',
      payload: {
        comment: message,
        delivery_by: deliveryBy,
        product_price: payload.productPrice
      }
    })
    yield put({
      type: ActionTypes.CONFIRM_ORDER_SUCCESS,
      payload: response.order
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.CONFIRM_ORDER_FAILURE,
      payload: err.message
    })
  }
}

export function * confirmPacked ({ payload }) {
  try {
    const { orderId, message } = payload
    const shopId = getCookie('shop_id')
    const response = yield call(request, APIEndpoints.CONFIRM_PACKED(shopId, orderId), {
      method: 'POST',
      payload: {
        comment: message
      }
    })
    yield put({
      type: ActionTypes.CONFIRM_PACKED_SUCCESS,
      payload: response.order
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.CONFIRM_PACKED_FAILURE,
      payload: err.message
    })
  }
}

export function * confirmDelivery ({ payload }) {
  try {
    const { orderId, message } = payload
    const shopId = getCookie('shop_id')
    const response = yield call(request, APIEndpoints.CONFIRM_DELIVERY(shopId, orderId), {
      method: 'POST',
      payload: {
        comment: message
      }
    })
    yield put({
      type: ActionTypes.CONFIRM_DELIVERY_SUCCESS,
      payload: response.order
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.CONFIRM_DELIVERY_FAILURE,
      payload: err.message
    })
  }
}

export function * confirmDelivered ({ payload }) {
  try {
    const { orderId, message } = payload
    const shopId = getCookie('shop_id')
    const response = yield call(request, APIEndpoints.CONFIRM_DELIVERED(shopId, orderId), {
      method: 'POST',
      payload: {
        comment: message
      }
    })
    yield put({
      type: ActionTypes.CONFIRM_DELIVERED_SUCCESS,
      payload: response.order
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.CONFIRM_DELIVERED_FAILURE,
      payload: err.message
    })
  }
}

export function * cancelOrder ({ payload }) {
  try {
    const { orderId, reason } = payload
    const shopId = getCookie('shop_id')
    const response = yield call(request, APIEndpoints.CANCEL_ORDER(shopId, orderId), {
      method: 'POST',
      payload: {
        comment: reason,
        reason
      }
    })
    yield put({
      type: ActionTypes.CANCEL_ORDER_SUCCESS,
      payload: response.order
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.CANCEL_ORDER_FAILURE,
      payload: err.message
    })
  }
}

export function * updateDelivery ({ payload }) {
  try {
    const { orderId, message, deliveryBy } = payload
    const shopId = getCookie('shop_id')
    const response = yield call(request, APIEndpoints.UPDATE_DELIVERY(shopId, orderId), {
      method: 'POST',
      payload: {
        comment: message,
        delivery_by: deliveryBy
      }
    })
    yield put({
      type: ActionTypes.UPDATE_DELIVERY_SUCCESS,
      payload: response.order
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.UPDATE_DELIVERY_FAILURE,
      payload: err.message
    })
  }
}

export function * getOrderLocation ({ payload }) {
  try {
    const shopId = getCookie('shop_id')
    const response = yield call(request, APIEndpoints.GET_ORDER_LOCATION(shopId), {
      method: 'GET'
    })
    yield put({
      type: ActionTypes.GET_ORDER_LOCATION_SUCCESS,
      payload: response.location
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_ORDER_LOCATION_FAILURE,
      payload: err
    })
  }
}

export function * changePaymentStatus ({ payload }) {
  try {
    const { orderId, requestObj } = payload
    const shopId = getCookie('shop_id')
    const response = yield call(request, APIEndpoints.CHANGE_PAYMENT_STATUS(shopId, orderId), {
      method: 'POST',
      payload: requestObj
    })
    yield put({
      type: ActionTypes.CHANGE_PAYMENT_STATUS_SUCCESS,
      payload: response.order
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.CHANGE_PAYMENT_STATUS_FAILURE,
      payload: err.message
    })
  }
}

export function * changeItemQuantity ({ payload }) {
  try {
    const { shopId, orderId, requestObj } = payload
    const response = yield call(request, APIEndpoints.CHANGE_ITEM_QUANTITY(shopId, orderId), {
      method: 'POST',
      payload: requestObj
    })
    yield put({
      type: ActionTypes.CHANGE_ITEM_QUANTITY_SUCCESS,
      payload: response.order
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.CHANGE_ITEM_QUANTITY_FAILURE,
      payload: err.message
    })
  }
}
/**
 * User Sagas
 */
export default function * root () {
  yield all([
    takeLatest(ActionTypes.ORDER_GET_LIST, orderGetList),
    takeLatest(ActionTypes.COMPLETED_ORDER_GET_LIST, completedOrderGetList),
    takeLatest(ActionTypes.ORDER_GET_DETAILS, orderGetDetails),
    takeLatest(ActionTypes.CONFIRM_ORDER, confirmOrder),
    takeLatest(ActionTypes.CONFIRM_PACKED, confirmPacked),
    takeLatest(ActionTypes.CONFIRM_DELIVERY, confirmDelivery),
    takeLatest(ActionTypes.CONFIRM_DELIVERED, confirmDelivered),
    takeLatest(ActionTypes.CANCEL_ORDER, cancelOrder),
    takeLatest(ActionTypes.UPDATE_DELIVERY, updateDelivery),
    takeLatest(ActionTypes.GET_ORDER_LOCATION, getOrderLocation),
    takeLatest(ActionTypes.CHANGE_PAYMENT_STATUS, changePaymentStatus),
    takeLatest(ActionTypes.CHANGE_ITEM_QUANTITY, changeItemQuantity)
  ])
}
