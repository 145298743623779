export default theme => ({
  root: {
    padding: '20px 20px',
    marginBottom: '10px',
    display: 'inline-block',
    width: '100%'
  },
  heading: {
    fontWeight: 'bold',
    fontSize: '16px',
    marginBottom: '10px'
  },
  shopUrl: {
    fontSize: '14px',
    color: 'grey',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    cursor: 'pointer',
    wordBreak: 'break-all'
  },
  copyIcon: {
    width: '30px',
    height: '30px',
    cursor: 'pointer'
  },
  icons: {
    paddingTop: '15px'
  },
  facebookIcon: {
    color: '#4267B2',
    width: '30px',
    height: '30px',
    cursor: 'pointer'
  },
  whatsappIcon: {
    color: '#25D366',
    width: '30px',
    height: '30px',
    cursor: 'pointer'
  },
  twitterIcon: {
    color: '#1DA1F2',
    width: '30px',
    height: '30px',
    cursor: 'pointer'
  }
})
