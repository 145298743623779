import { handleActions } from 'redux-actions'
import immutable from 'immutability-helper'
import _ from 'lodash'

import { parseError } from 'services/client'

import { ActionTypes, STATUS } from 'redux/constants/index'

export const analyticsState = {
  accountStatus: {
    data: {},
    message: '',
    showAlert: false
  },
  requestVerification: {
    data: {},
    message: '',
    status: STATUS.IDLE
  },
  totalOrders: {
    data: {},
    message: '',
    status: STATUS.IDLE
  }
}

export default {
  analytics: handleActions(
    {
      [ActionTypes.GET_ACCOUNT_STATUS]: (state, { payload }) =>
        immutable(state, {
          accountStatus: {
            status: { $set: STATUS.RUNNING }
          }
        }),
      [ActionTypes.GET_ACCOUNT_STATUS_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          accountStatus: {
            data: { $set: payload },
            status: { $set: STATUS.READY }
          }
        }),
      [ActionTypes.GET_ACCOUNT_STATUS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          accountStatus: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        }),

      [ActionTypes.CHANGE_ACCEPT_STATUS_SUCCESS]: (state, { payload }) => {
        let { data } = _.cloneDeep(state.accountStatus)
        if (data.details) {
          data.details.is_accepting_orders = payload.is_accepting_orders
        }
        return immutable(state, {
          accountStatus: {
            data: { $set: data },
            status: { $set: STATUS.READY }
          }
        })
      },
      [ActionTypes.REQUEST_VERIFICATION]: (state, { payload }) =>
        immutable(state, {
          requestVerification: {
            status: { $set: STATUS.RUNNING }
          }
        }),
      [ActionTypes.REQUEST_VERIFICATION_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          requestVerification: {
            data: { $set: payload },
            status: { $set: STATUS.READY }
          }
        }),
      [ActionTypes.REQUEST_VERIFICATION_FAILURE]: (state, { payload }) =>
        immutable(state, {
          requestVerification: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        }),

      [ActionTypes.GET_TOTAL_ORDERS]: (state, { payload }) =>
        immutable(state, {
          totalOrders: {
            status: { $set: STATUS.RUNNING }
          }
        }),
      [ActionTypes.GET_TOTAL_ORDERS_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          totalOrders: {
            data: { $set: payload },
            status: { $set: STATUS.READY }
          }
        }),
      [ActionTypes.GET_TOTAL_ORDERS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          totalOrders: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        })
    },
    analyticsState
  )
}
