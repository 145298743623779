import React, { Component } from 'react'
import { withStyles, Paper, TableRow, TableHead, TableContainer, TableCell, TableBody, Table, Grid } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import style from './style.js'

class EnterprisePlanTable extends Component {
  render() {
    const { classes } = this.props

    return (
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table aria-label='simple table' className={classes.featureTable}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.emptyHead} />
              <TableCell align='center' className={classes.tableHead}>
                <Grid container>
                  <Grid xs={12}>Silver Plan</Grid>
                  <Grid xs={12} className={classes.priceContainer}>
                    <Grid className={classes.priceSection}>
                      <sup>₹</sup>
                      <span className={classes.price}>200</span>
                    </Grid>
                    <Grid className={classes.billingPeriodContainer}>
                      <p className={classes.billingPeriod}>INR</p><br />
                      <p className={classes.billingPeriod}>3mo</p>
                    </Grid>
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell align='center' className={classes.tableHead}>
                <Grid container>
                  <Grid xs={12}>Gold Plan</Grid>
                  <Grid xs={12} className={classes.priceContainer}>
                    <Grid className={classes.priceSection}>
                      <sup>₹</sup>
                      <span className={classes.price}>500</span>
                    </Grid>
                    <Grid className={classes.billingPeriodContainer}>
                      <p className={classes.billingPeriod}>INR</p><br />
                      <p className={classes.billingPeriod}>/mon</p>
                    </Grid>
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell align='center' className={classes.tableHead}>
                <Grid container>
                  <Grid xs={12}>Platinum Plan</Grid>
                  <Grid xs={12} className={classes.priceContainer}>
                    <Grid className={classes.priceSection}>
                      <sup>₹</sup>
                      <span className={classes.price}>700</span>
                    </Grid>
                    <Grid className={classes.billingPeriodContainer}>
                      <p className={classes.billingPeriod}>INR</p><br />
                      <p className={classes.billingPeriod}>/mon</p>
                    </Grid>
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow className={classes.tableSubHeadingRow}>
              <TableCell className={classes.tableSubHeading} component='th' scope='col' colSpan={4}>
                FEATURES
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component='th' scope='row' align='left' className={classes.futureTitle}>
                Number of products
              </TableCell>
              <TableCell align='center' className={classes.futureCell}>500</TableCell>
              <TableCell align='center' className={classes.futureCell}>1000</TableCell>
              <TableCell align='center' className={classes.futureCell}>10000</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component='th' scope='row' align='left' className={classes.futureTitle}>
                Excel export
              </TableCell>
              <TableCell align='center' className={classes.futureCell}><CheckIcon /></TableCell>
              <TableCell align='center' className={classes.futureCell}><CheckIcon /></TableCell>
              <TableCell align='center' className={classes.futureCell}><CheckIcon /></TableCell>
            </TableRow>
            <TableRow>
              <TableCell component='th' scope='row' align='left' className={classes.futureTitle}>
                Number of orders
              </TableCell>
              <TableCell align='center' className={classes.futureCell}>400</TableCell>
              <TableCell align='center' className={classes.futureCell}>Unlimited</TableCell>
              <TableCell align='center' className={classes.futureCell}>Unlimited</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component='th' scope='row' align='left' className={classes.futureTitle}>
                Linking custom domain
              </TableCell>
              <TableCell align='center' className={classes.futureCell}>-</TableCell>
              <TableCell align='center' className={classes.futureCell}>-</TableCell>
              <TableCell align='center' className={classes.futureCell}><CheckIcon /></TableCell>
            </TableRow>
            <TableRow>
              <TableCell component='th' scope='row' align='left' className={classes.futureTitle}>
                Android application
              </TableCell>
              <TableCell align='center' className={classes.futureCell}>-</TableCell>
              <TableCell align='center' className={classes.futureCell}>-</TableCell>
              <TableCell align='center' className={classes.futureCell}><CheckIcon /></TableCell>
            </TableRow>
            <TableRow>
              <TableCell component='th' scope='row' align='left' className={classes.futureTitle}>
                Analytics
              </TableCell>
              <TableCell align='center' className={classes.futureCell}><CheckIcon /></TableCell>
              <TableCell align='center' className={classes.futureCell}><CheckIcon /></TableCell>
              <TableCell align='center' className={classes.futureCell}><CheckIcon /></TableCell>
            </TableRow>
            <TableRow>
              <TableCell component='th' scope='row' align='left' className={classes.futureTitle}>
                Advanced analytics
              </TableCell>
              <TableCell align='center' className={classes.futureCell}>-</TableCell>
              <TableCell align='center' className={classes.futureCell}><CheckIcon /></TableCell>
              <TableCell align='center' className={classes.futureCell}><CheckIcon /></TableCell>
            </TableRow>
            <TableRow>
              <TableCell component='th' scope='row' align='left' className={classes.futureTitle}>
                Inventory management
              </TableCell>
              <TableCell align='center' className={classes.futureCell}><CheckIcon /></TableCell>
              <TableCell align='center' className={classes.futureCell}><CheckIcon /></TableCell>
              <TableCell align='center' className={classes.futureCell}><CheckIcon /></TableCell>
            </TableRow>
            <TableRow>
              <TableCell component='th' scope='row' align='left' className={classes.futureTitle}>
                Order management
              </TableCell>
              <TableCell align='center' className={classes.futureCell}><CheckIcon /></TableCell>
              <TableCell align='center' className={classes.futureCell}><CheckIcon /></TableCell>
              <TableCell align='center' className={classes.futureCell}><CheckIcon /></TableCell>
            </TableRow>
            <TableRow>
              <TableCell component='th' scope='row' align='left' className={classes.futureTitle}>
                Customer review and feedback
              </TableCell>
              <TableCell align='center' className={classes.futureCell}><CheckIcon /></TableCell>
              <TableCell align='center' className={classes.futureCell}><CheckIcon /></TableCell>
              <TableCell align='center' className={classes.futureCell}><CheckIcon /></TableCell>
            </TableRow>
            <TableRow>
              <TableCell component='th' scope='row' align='left' className={classes.futureTitle}>
                Product ratings and reviews
              </TableCell>
              <TableCell align='center' className={classes.futureCell}><CheckIcon /></TableCell>
              <TableCell align='center' className={classes.futureCell}><CheckIcon /></TableCell>
              <TableCell align='center' className={classes.futureCell}><CheckIcon /></TableCell>
            </TableRow>
            <TableRow>
              <TableCell component='th' scope='row' align='left' className={classes.futureTitle}>
                Billing software integration
              </TableCell>
              <TableCell align='center' className={classes.futureCell}>-</TableCell>
              <TableCell align='center' className={classes.futureCell}>-</TableCell>
              <TableCell align='center' className={classes.futureCell}><CheckIcon /></TableCell>
            </TableRow>
            <TableRow>
              <TableCell component='th' scope='row' align='left' className={classes.futureTitle}>
                Push notification to customers
              </TableCell>
              <TableCell align='center' className={classes.futureCell}>-</TableCell>
              <TableCell align='center' className={classes.futureCell}>-</TableCell>
              <TableCell align='center' className={classes.futureCell}><CheckIcon /></TableCell>
            </TableRow>
            <TableRow>
              <TableCell component='th' scope='row' align='left' className={classes.futureTitle}>
                Visit times slot booking
              </TableCell>
              <TableCell align='center' className={classes.futureCell}>-</TableCell>
              <TableCell align='center' className={classes.futureCell}><CheckIcon /></TableCell>
              <TableCell align='center' className={classes.futureCell}><CheckIcon /></TableCell>
            </TableRow>
            <TableRow>
              <TableCell component='th' scope='row' align='left' className={classes.futureTitle}>
                3rd party delivery agency integration
              </TableCell>
              <TableCell align='center' className={classes.futureCell}>-</TableCell>
              <TableCell align='center' className={classes.futureCell}><CheckIcon /></TableCell>
              <TableCell align='center' className={classes.futureCell}><CheckIcon /></TableCell>
            </TableRow>
            <TableRow>
              <TableCell component='th' scope='row' align='left' className={classes.futureTitle}>
                Courier serivice integration
              </TableCell>
              <TableCell align='center' className={classes.futureCell}>-</TableCell>
              <TableCell align='center' className={classes.futureCell}><CheckIcon /></TableCell>
              <TableCell align='center' className={classes.futureCell}><CheckIcon /></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    )
  }
}

export default withStyles(style)(EnterprisePlanTable)
