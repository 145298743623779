import React from 'react'

function CashIcon () {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='45' height='45' viewBox='0 0 116.77 134.273'>
      <g id='Group_1742' data-name='Group 1742' transform='translate(-957 -1257.618)'>
        <path id='Path_107' data-name='Path 107' d='M87.135,0A43.568,43.568,0,0,1,43.568,43.568,43.568,43.568,0,0,1,0,0' transform='translate(994.731 1294.646) rotate(60)' fill='#1b293a' opacity='0.05' />
        <path id='Path_106' data-name='Path 106' d='M87.135,43.568A43.568,43.568,0,1,0,0,43.568' transform='translate(1030.203 1257.618) rotate(60)' fill='#1b293a' opacity='0.1' />
        <g id='cash-payment' transform='translate(995.74 1301.976)'>
          <path id='Path_122' data-name='Path 122' d='M106.508,71.2l-3.388,1.061-2.928.916H84.815l-2.988-9.547L72,32.23l9.827-3.076L92.32,25.87l10.8,34.505Z' transform='translate(-72 -25.679)' fill='#379a6b' />
          <path id='Path_123' data-name='Path 123' d='M141.752,109.871l.734,2.345-9.379,2.935-.734-2.345-2.345.734L119.266,79.152l2.345-.734-.734-2.345,9.379-2.935.734,2.345,2.345-.734L144.1,109.137Z' transform='translate(-114.427 -68.108)' fill='#44c086' />
          <path id='Path_124' data-name='Path 124' d='M168,24h21.293V71.5H168Z' transform='translate(-158.173 -24)' fill='#3ba673' />
          <path id='Path_125' data-name='Path 125' d='M212.284,94.49v2.457h-9.827V94.49H200V58.457h2.457V56h9.827v2.457h2.457V94.49Z' transform='translate(-186.897 -52.724)' fill='#44c086' />
          <ellipse id='Ellipse_28' data-name='Ellipse 28' cx='3.5' cy='4' rx='3.5' ry='4' transform='translate(16.833 19.685)' fill='#3ba673' />
          <path id='Path_126' data-name='Path 126' d='M266.552,181.1v19.655H260.82a8.189,8.189,0,0,1-8.189-8.189h.016l-5.313-7.862a3.276,3.276,0,0,1,5.429-3.668l4.782,6.617H260V168l6.166,11.562A3.276,3.276,0,0,1,266.552,181.1Z' transform='translate(-228.881 -153.259)' fill='#f5cbaa' />
        </g>
      </g>
    </svg>
  )
}

export default CashIcon
