export default theme => ({
  form: {
    paddingLeft: '100px',
    paddingRight: '50px',
    paddingBottom: '125px',
    paddingTop: '25px',
    flexBasis: '700px',
    maxWidth: '100vw',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing.unit * 2,
      paddingRight: theme.spacing.unit * 2
    }
  },
  img: {
    width: '100%'
  },
  title: {
    marginTop: theme.spacing.unit * 4,
    marginBottom: theme.spacing.unit * 2,
    fontSize: '24px'
  },
  subtitle: {
    marginTop: theme.spacing.unit * 0.5,
    fontWeight: 'bold'
  },
  fields: {
    marginTop: theme.spacing.unit * 2
  },
  textField: {
    width: '100%',
    '& + & ': {
      marginTop: theme.spacing.unit * 2
    }
  },
  policy: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing.unit * 2
  },
  policyCheckbox: {
    marginLeft: '-14px'
  },
  policyText: {
    display: 'inline'
  },
  policyUrl: {
    color: theme.palette.primary.main,
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary.main
    }
  },
  progress: {
    display: 'block',
    marginTop: theme.spacing.unit * 2,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  signUpButton: {
    marginTop: 20,
    width: '100%'
  },
  signIn: {
    marginTop: theme.spacing.unit * 2,
    color: theme.palette.text.secondary
  },
  signInUrl: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    '&:hover': {
      color: theme.palette.primary.main
    }
  },
  fieldError: {
    color: theme.palette.danger.main,
    marginBottom: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit
  },
  submitError: {
    color: theme.palette.danger.main,
    alignText: 'center',
    marginBottom: theme.spacing.unit,
    marginTop: theme.spacing.unit * 2
  },
  totalCustomer: {
    color: 'rgb(84, 85, 100)',
    fontSize: '18px',
    fontWeight: '600',
    paddingTop: '15px'
  }
})
