import React, { Component } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Button, withStyles, TextField, CircularProgress } from '@material-ui/core'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import treeChanges from 'tree-changes'
import _ from 'lodash'

import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter
} from 'components'
import styles from './style'
// import Select from 'components/Select'
import { getPaymentDetails, showAlert, updatePaymentSettings, sendPaymentReminder } from 'redux/actions'
import { STATUS } from 'redux/constants'

class PaymentDetails extends Component {
  constructor (props) {
    super(props)
    const paymentOptions = props.paymentOptionsData.data || {}
    this.state = {
      paymentOptions,
      touched: false
    }
  }

  componentDidMount = () => {
    const { dispatch } = this.props
    dispatch(getPaymentDetails())
  }

  componentWillReceiveProps = newProps => {
    const { paymentOptionsData, editPaymentSettings } = _.cloneDeep(newProps)
    const { changed, changedTo } = treeChanges(this.props, newProps)
    if (changed('paymentOptionsData') && editPaymentSettings.status !== STATUS.RUNNING) {
      this.setState({ paymentOptions: _.cloneDeep(paymentOptionsData.data) })
    }
    if (changedTo('editPaymentSettings.status', STATUS.ERROR)) {
      this.props.dispatch(showAlert(editPaymentSettings.message, { variant: 'error' }))
    } else if (changedTo('editPaymentSettings.status', STATUS.READY)) {
      this.setState({ touched: false })
      this.props.dispatch(showAlert('Updated Payment Settings successfully', { variant: 'success' }))
    }

    if (changedTo('sendPaymentReminder.status', STATUS.ERROR)) {
      this.props.dispatch(showAlert(sendPaymentReminder.message, { variant: 'error' }))
    } else if (changedTo('sendPaymentReminder.status', STATUS.READY)) {
      this.setState({ touched: false })
      this.props.dispatch(showAlert('Sent Payment Reminder Successfully', { variant: 'success' }))
    }
  }

  handleFieldChange = (field, value) => {
    let { paymentOptions, touched } = this.state
    paymentOptions[field] = value
    touched = true
    this.setState({ paymentOptions, touched }, this.validateForm)
  }

  sendPaymentReminder = () => {
    this.props.dispatch(sendPaymentReminder())
  }

  handleSaveAction = e => {
    const { paymentOptions } = this.state
    if (paymentOptions.upi_payment && paymentOptions.upi_payment.status && !paymentOptions.upi_payment.upi_code) {
      this.props.dispatch(showAlert('UPI code should be valid', { variant: 'error' }))
      return
    }
    if (paymentOptions.cash_on_delivery && !paymentOptions.cash_on_delivery.status && paymentOptions.upi_payment && !paymentOptions.upi_payment.status) {
      this.props.dispatch(showAlert('One payment method should be selected', { variant: 'error' }))
      return
    }
    const request = {
      vpa: paymentOptions.vpa
    }
    this.props.dispatch(updatePaymentSettings(request))
  }

  render () {
    const { classes, className, editPaymentSettings, paymentOptionsData, ...rest } = this.props
    const { paymentOptions, touched } = this.state
    const rootClassName = classNames(classes.root, className)
    return (
      <Portlet
        {...rest}
        className={rootClassName}
      >
        <PortletHeader>
          <PortletLabel
            title='Payment Settings'
          />
        </PortletHeader>
        {paymentOptionsData.status === STATUS.READY ? <PortletContent noPadding>
          <form
            autoComplete='off'
            noValidate
          >
            <div className={classes.field}>
              <TextField
                className={classes.textField}
                label='UPI code'
                margin='dense'
                required
                value={paymentOptions ? paymentOptions.vpa : null}
                variant='outlined'
                onChange={(e) => this.handleFieldChange('vpa', e.target.value)}
              />
            </div>
          </form>
        </PortletContent> : null}
        {paymentOptionsData.status === STATUS.RUNNING
          ? <PortletContent>
            <div className={classes.progressWrapper}>
              <CircularProgress />
            </div>
          </PortletContent> : null}
        <PortletFooter className={classes.portletFooter}>
          <Button
            color='primary'
            variant='contained'
            onClick={this.handleSaveAction}
            disabled={!touched || editPaymentSettings.status === STATUS.RUNNING}
          >
            Save details
          </Button>
        </PortletFooter>
      </Portlet>
    )
  }
}

PaymentDetails.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
}

function mapStateToProps (state) {
  return {
    paymentOptionsData: _.cloneDeep(state.settings.paymentDetails),
    editPaymentSettings: state.settings.editPaymentSettings,
    sendPaymentReminder: state.settings.sendPaymentReminder
  }
}

export default compose(
  withStyles(styles)
)(connect(mapStateToProps)(PaymentDetails))
