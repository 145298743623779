import React, { Component } from 'react'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import { Button } from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'

const style = theme => ({
  container: {
    paddingTop: '150px',
    paddingBottom: '100px'
  },
  heading: {
    fontFamily: 'Poppins, sans-serif',

    textAlign: 'center',
    margin: '0 30px 10px'
  },
  subtitle: {
    fontFamily: 'Poppins, sans-serif',

    fontWeight: 500,
    textAlign: 'center',
    margin: '0px 20vw 0px',
    [theme.breakpoints.down('md')]: {
      margin: '0px 10vw 0px'
    }
  },
  boxContainer: {
    paddingTop: '50px',
    width: '100%'
  },
  Button: {
    backgroundColor: '#32a05f',
    marginRight: '10px',
    padding: '10px 20px 10px',
    borderRadius: '16px',
    color: 'white',
    cursor: 'pointer'
  },
  icon: {
    marginRight: '10px'
  }
})

class ContactUs extends Component {
  gotoEmail = () => {
    const link = `mailto:hello@inventlabs.org`
    window.open(link)
  }

  gotoWhatsApp = () => {
    const link = `https://api.whatsapp.com/send/?phone=917736043121`
    window.open(link)
  }

  render() {
    const { classes } = this.props

    return (
      <div className={classes.container}>
        <Typography variant='h2' component='h2' gutterBottom className={classes.heading}>
          Contact Us
        </Typography>
        <Typography variant='subtitle1' component='h2' gutterBottom className={classes.subtitle}>
          Whether you have a question about features, trials, pricing, need a demo, or anything else, our team is ready to answer all your questions        </Typography>
        <Grid container spacing={2} className={classes.boxContainer} justify='center'>
          <Grid justify='center'>
            <Button className={classes.Button} onClick={this.gotoEmail}><EmailIcon className={classes.icon} />Email Us</Button>
          </Grid>
          <Grid justify='center'>
            <Button className={classes.Button} onClick={this.gotoWhatsApp}><WhatsAppIcon className={classes.icon} />WhatsApp</Button>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default withStyles(style)(ContactUs)
