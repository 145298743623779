export default theme => ({
  root: {
    backgroundColor: theme.palette.background.default
  },
  quoteWrapper: {
    height: 'fit-content',
    paddingLeft: '50px',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '50px'
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '20px'
    }
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px'
  },
  name: {
    marginTop: theme.spacing.unit * 3,
    color: theme.palette.common.white
  },
  bio: {
    color: theme.palette.common.white
  },
  contentWrapper: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing.unit * 5,
    paddingBototm: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2
  },
  backButton: {},
  logoImage: {
    marginLeft: theme.spacing.unit * 4
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  signUpButton: {
    marginTop: 20,
    width: '100%'
  },
  signIn: {
    marginTop: theme.spacing.unit * 2,
    color: theme.palette.text.secondary
  },
  signInUrl: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    '&:hover': {
      color: theme.palette.primary.main
    }
  },
  fieldError: {
    color: theme.palette.danger.main,
    marginBottom: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit
  },
  submitError: {
    color: theme.palette.danger.main,
    alignText: 'center',
    marginBottom: theme.spacing.unit,
    marginTop: theme.spacing.unit * 2
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: '30px'
  },
  logoTitle: {
    marginTop: '10px',
    marginLeft: '5px',
    fontFamily: 'Montserrat',
    fontWeight: '600',
    color: '#32a05f'
  },
  quoteContainer: {
    marginTop: '50px',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      marginTop: '50px'
    }
  },
  quoteText: {
    color: 'rgb(84, 85, 100)',
    fontWeight: 600,
    fontSize: '48px',
    width: '350px',
    fontFamily: 'Montserrat',
    lineHeight: '60px',
    [theme.breakpoints.down('md')]: {
      fontSize: '44px',
      width: '330px'
    }
  },
  underLine: {
    width: '75px',
    height: '6px',
    marginTop: '15px',
    background: 'rgb(84, 85, 100)'
  },
  arrowIcon: {
    width: '300px',
    marginLeft: '50px',
    marginTop: '150px',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  playVideoContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '50px',
    cursor: 'pointer'
  },
  playIcon: {
    width: '75px',
    marginRight: '20px'
  },
  playText: {
    fontSize: '24px',
    fontFamily: 'Montserrat',
    color: 'rgb(84, 85, 100)',
    fontWeight: '500'
  },
  sponserContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '30px'
  },
  sponserLogo: {
    marginLeft: '20px'
  }
})
