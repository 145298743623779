export default theme => ({
  container: {
    height: '70vh',
    overflowY: 'auto',
    scrollbarWidth: 'thin',
    overflowX: 'hidden',
    textAlign: 'center'
  },
  heading: {
    fontSize: '24px',
    fontWeight: '700',
    textAlign: 'center',
    marginTop: '50px'
  },
  sampleFileText: {
    fontSize: '18px',
    fontWeight: '500',
    textAlign: 'center',
    marginTop: '20px'
  },
  sampleButton: {
    marginTop: '20px'
  },
  uploadButton: {
    marginTop: '50px',
    padding: '15px'
  },
  uploadSuccess: {
    marginTop: '30px'
  },
  successText: {
    fontSize: '22px',
    fontWeight: '600',
    textAlign: 'center',
    marginTop: '10px'
  },
  addedProducts: {
    fontSize: '16px',
    fontWeight: '500',
    textAlign: 'center',
    marginTop: '30px'
  },
  updatedProducts: {
    fontSize: '16px',
    fontWeight: '500',
    textAlign: 'center',
    marginTop: '10px'
  }
})
