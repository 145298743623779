import React, { Component } from 'react'

import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { withStyles, Tabs, Tab, CircularProgress } from '@material-ui/core'
import { Dashboard as DashboardLayout } from 'layouts'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import EventNoteIcon from '@material-ui/icons/EventNote'
import AccountBoxIcon from '@material-ui/icons/AccountBox'
import LocalMallIcon from '@material-ui/icons/LocalMall'
import PaymentIcon from '@material-ui/icons/Payment'
import LocalShippingIcon from '@material-ui/icons/LocalShipping'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'

import { getShopDetails, getShopCategories, getShopTypes } from 'redux/actions'
import style from './style'
import { ShopSettings, UserSettings, DeliverySettings, PaymentSettings, Subscription } from './components'
import { STATUS } from 'redux/constants'
import Reports from './components/Reports'
// Component styles

class Settings extends Component {
  constructor (props) {
    super(props)
    let stage = 0
    const { location } = props
    const params = new URLSearchParams(location.search)
    const selected = params.get('selected')
    if (selected) {
      stage = selected === 'delivery' ? 2 : selected === 'payment' ? 3 : 0
    }
    this.state = {
      stage
    }
  }

    componentDidMount = () => {
      const { dispatch } = this.props
      dispatch(getShopCategories())
      dispatch(getShopTypes())
      dispatch(getShopDetails())
    }

    handleChange = (event, stage) => {
      this.setState({ stage })
    }

    render () {
      const { classes, shopDetails } = this.props
      const { stage } = this.state

      return (
        <DashboardLayout title='Settings'>
          <div className={classes.root}>

            <Tabs
              value={stage}
              onChange={this.handleChange}
              indicatorColor='primary'
              textColor='primary'
              aria-label='icon tabs example'
            >
              <Tab className={classes.tab} icon={<LocalMallIcon />} label='Shop' aria-label='shop' />
              <Tab className={classes.tab} icon={<AccountBoxIcon />} label='User' aria-label='user' />
              <Tab className={classes.tab} icon={<LocalShippingIcon />} label='Delivery' aria-label='delivery' />
              <Tab className={classes.tab} icon={<PaymentIcon />} label='Payment' aria-label='payment' />
              <Tab className={classes.tab} icon={<EventNoteIcon />} label='Reports' aria-label='reports' />
              <Tab className={classes.tab} icon={<AttachMoneyIcon />} label='Subscription' aria-label='subscription' />

            </Tabs>
            {shopDetails.status === STATUS.RUNNING ? <div className={classes.progressWrapper}>
              <CircularProgress />
            </div> : null}
            { shopDetails.status === STATUS.READY && stage === 0 ? <ShopSettings /> : null }
            { shopDetails.status === STATUS.READY && stage === 1 ? <UserSettings /> : null }
            { shopDetails.status === STATUS.READY && stage === 2 ? <DeliverySettings /> : null }
            { shopDetails.status === STATUS.READY && stage === 3 ? <PaymentSettings /> : null }
            { shopDetails.status === STATUS.READY && stage === 4 ? <Reports /> : null }
            { shopDetails.status === STATUS.READY && stage === 5 ? <Subscription /> : null }
          </div>
        </DashboardLayout>
      )
    }
}

Settings.propTypes = {
  classes: PropTypes.object.isRequired
}

function mapStateToProps (state) {
  return {
    shopDetails: state.settings.shopDetails
  }
}

export default compose(
  withStyles(style), withRouter
)(connect(mapStateToProps)(Settings))
