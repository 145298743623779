import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@material-ui/core/CircularProgress';

import {
  TextField,
  withStyles
} from '@material-ui/core'
import styles from './style'
import MapContainer from 'components/MapContainer'
import MarkerIcon from 'assets/icons/MarkerIcon'

class ShopAddress extends Component {
  render() {
    const { classes, getUpdatedCenter, latlng, handleFieldChange, level1, level2 } = this.props
    return (<div className={classes.form}>

      <div className={classes.container} >
        <MapContainer
          isMarkerShown
          googleMapURL='https://maps.googleapis.com/maps/api/js?key=AIzaSyCXNvB8u-tKjRrAE52gHvWVqhBm5yDXd6k&v=3.exp&v=3.exp'
          // googleMapURL='https://maps.googleapis.com/maps/api/js?key=AIzaSyBujK5bEmXsfoAFjcA2rmF6PFBSoHziQ-I&v=3.exp&v=3.exp'
          loadingElement={<div style={{ height: '300px' }} />}
          containerElement={<div className={classes.mapContainer} style={{ height: '300px', width: '100%' }} />}
          mapElement={<div style={{ height: '300px', zIndex: 9 }} />}
          latlng={latlng}
          getUpdatedCenter={getUpdatedCenter}
        />
        <CircularProgress className={classes.loader} />
        <MarkerIcon />
      </div>
      <TextField className={classes.textField} value={level1} label='Address' name='name' variant='outlined' onChange={event => handleFieldChange('level1', event.target.value)} />
      <TextField className={classes.textField} value={level2} label='Landmark' name='textAddress' variant='outlined' onChange={event => handleFieldChange('level2', event.target.value)} />

    </div>)
  }
}

ShopAddress.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ShopAddress)
