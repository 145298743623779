// @flow
/**
 * @module Actions/App
 * @desc App Actions
 */

import { createActions } from 'redux-actions'

import { ActionTypes } from 'redux/constants/index'

export const { getAccountStatus, requestVerification, getTotalOrders } = createActions({
  [ActionTypes.GET_ACCOUNT_STATUS]: () => ({}),
  [ActionTypes.REQUEST_VERIFICATION]: (shopId) => ({ shopId }),
  [ActionTypes.GET_TOTAL_ORDERS]: (shopId) => ({ shopId })
})
