/**
 * @module Sagas/User
 * @desc User
 */

import { all, put, call, takeLatest } from 'redux-saga/effects'

import { ActionTypes, APIEndpoints } from 'redux/constants/index'
import { request } from 'services/client'
import { getCookie } from 'services/cookie'

/**
 * Signup
 */
export function * getAccountStatus (action) {
  try {
    const shopId = getCookie('shop_id')
    const response = yield call(request, APIEndpoints.GET_ACCOUNT_STATUS(shopId), {
      method: 'GET'
    })
    yield put({
      type: ActionTypes.GET_ACCOUNT_STATUS_SUCCESS,
      payload: response
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_ACCOUNT_STATUS_FAILURE,
      payload: err
    })
  }
}

export function * requestVerification ({ payload }) {
  try {
    const { shopId } = payload
    const response = yield call(request, APIEndpoints.REQUEST_VERIFICATION(shopId), {
      method: 'POST',
      payload: { }
    })
    yield put({
      type: ActionTypes.REQUEST_VERIFICATION_SUCCESS,
      payload: response.shops
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.REQUEST_VERIFICATION_FAILURE,
      payload: err
    })
  }
}

export function * getTotalOrders ({ payload }) {
  try {
    const shopId = getCookie('shop_id')
    const response = yield call(request, APIEndpoints.GET_TOTAL_ORDERS(shopId), {
      method: 'GET',
      payload: { }
    })
    yield put({
      type: ActionTypes.GET_TOTAL_ORDERS_SUCCESS,
      payload: response
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_TOTAL_ORDERS_FAILURE,
      payload: err
    })
  }
}

/**
 * User Sagas
 */
export default function * root () {
  yield all([
    takeLatest(ActionTypes.GET_ACCOUNT_STATUS, getAccountStatus),
    takeLatest(ActionTypes.REQUEST_VERIFICATION, requestVerification),
    takeLatest(ActionTypes.GET_TOTAL_ORDERS, getTotalOrders)
  ])
}
