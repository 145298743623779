import React, { Component } from 'react'
import { withStyles, Paper, TableRow, TableContainer, TableCell, TableBody, Table } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import style from './style.js'

class FreePlanTable extends Component {
  render () {
    const { classes } = this.props

    return (
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table aria-label='simple table' className={classes.featureTable}>
          <TableBody>
            <TableRow className={classes.tableSubHeadingRow}>
              <TableCell className={classes.tableSubHeading} component='th' scope='col' colSpan={4}>
                                FEATURES
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component='th' scope='row' align='left' className={classes.futureTitle}>
                                Number of products
              </TableCell>
              <TableCell align='center' className={classes.futureCell}>50</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component='th' scope='row' align='left' className={classes.futureTitle}>
                                Excel export
              </TableCell>
              <TableCell align='center' className={classes.futureCell}><CheckIcon /></TableCell>
            </TableRow>
            <TableRow>
              <TableCell component='th' scope='row' align='left' className={classes.futureTitle}>
                                Number of orders
              </TableCell>
              <TableCell align='center' className={classes.futureCell}>400</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component='th' scope='row' align='left' className={classes.futureTitle}>
                                Linking custom domain
              </TableCell>
              <TableCell align='center' className={classes.futureCell}>-</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component='th' scope='row' align='left' className={classes.futureTitle}>
                                Android application
              </TableCell>
              <TableCell align='center' className={classes.futureCell}>-</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component='th' scope='row' align='left' className={classes.futureTitle}>
                                Analytics
              </TableCell>
              <TableCell align='center' className={classes.futureCell}><CheckIcon /></TableCell>
            </TableRow>
            <TableRow>
              <TableCell component='th' scope='row' align='left' className={classes.futureTitle}>
                                Advanced analytics
              </TableCell>
              <TableCell align='center' className={classes.futureCell}>-</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component='th' scope='row' align='left' className={classes.futureTitle}>
                                Inventory management
              </TableCell>
              <TableCell align='center' className={classes.futureCell}><CheckIcon /></TableCell>
            </TableRow>
            <TableRow>
              <TableCell component='th' scope='row' align='left' className={classes.futureTitle}>
                                Order management
              </TableCell>
              <TableCell align='center' className={classes.futureCell}><CheckIcon /></TableCell>
            </TableRow>
            <TableRow>
              <TableCell component='th' scope='row' align='left' className={classes.futureTitle}>
                                Customer review and feedback
              </TableCell>
              <TableCell align='center' className={classes.futureCell}><CheckIcon /></TableCell>
            </TableRow>
            <TableRow>
              <TableCell component='th' scope='row' align='left' className={classes.futureTitle}>
                                Product ratings and reviews
              </TableCell>
              <TableCell align='center' className={classes.futureCell}><CheckIcon /></TableCell>
            </TableRow>
            <TableRow>
              <TableCell component='th' scope='row' align='left' className={classes.futureTitle}>
                                Billing software integration
              </TableCell>
              <TableCell align='center' className={classes.futureCell}>-</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component='th' scope='row' align='left' className={classes.futureTitle}>
                                Push notification to customers
              </TableCell>
              <TableCell align='center' className={classes.futureCell}>-</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component='th' scope='row' align='left' className={classes.futureTitle}>
                                Visit times slot booking
              </TableCell>
              <TableCell align='center' className={classes.futureCell}>-</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component='th' scope='row' align='left' className={classes.futureTitle}>
                                3rd party delivery agency integration
              </TableCell>
              <TableCell align='center' className={classes.futureCell}>-</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component='th' scope='row' align='left' className={classes.futureTitle}>
                                Courier serivice integration
              </TableCell>
              <TableCell align='center' className={classes.futureCell}>-</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    )
  }
}

export default withStyles(style)(FreePlanTable)
