export default theme => ({
  container: {
    height: 300,
    width: '100%',
    position: 'relative',
    marginBottom: 20,
    '& svg:not(.MuiCircularProgress-svg)': {
      position: 'absolute',
      top: 'calc( 50% - 12px)',
      left: 'calc( 50% - 12px)',
      zIndex: 10,
    }
  },
  loader: {
    position: 'absolute',
    top: 'calc( 50% - 20px)', 
    left: 'calc( 50% - 20px)',
    zIndex: 8,
  },
  mapContainer: {
    marginBottom: 20
  },
  textField: {
    width: '100%',
    '& + & ': {
      marginTop: theme.spacing.unit * 2
    }
  }
})
