import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles, Typography, Link } from '@material-ui/core'
import compose from 'recompose/compose'

const styles = theme => ({
  root: {
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit}px`,
    maxHeight: '50vh',
    overflowY: 'auto',
    paddingBottom: 0
  },
  rootcontainer: {
    marginTop: 20,
    position: 'relative',
    width: '100%'
  },
  storeImage: {
    width: 200,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 10
    }
  },
  subHeadingTypography: {
    marginTop: theme.spacing(1),
    marginBottom: 10,
    lineHeight: '20px',
    paddingRight: '10px',
    fontSize: '16px'
  },
  userApp: {
    '& a:first-child': {
      marginRight: 20
    }
  },
  selectTitle: {
    marginTop: 60
  },
  last: {
    marginTop: 20,
    fontSize: 16
  }
})

const externalLinks = {
  customerAppStore: 'https://apps.apple.com/in/app/shopsapp/id1505960267',
  customerPlayStore: 'https://play.google.com/store/apps/details?id=org.inventlabs.shopsapp'
}

class RegisterSuccess extends Component {
  render () {
    const { classes } = this.props
    return (
      <div className={classes.rootcontainer} >

        <Typography className={classes.selectTitle} variant='h4'>Successfully Registered for booking</Typography>
        <div className={classes.root}>
          <Typography
            color='textSecondary'
            className={classes.subHeadingTypography}
          >
               Download this app to book or order from CPC
          </Typography>
          <div className={classes.userApp}>

            <Link
              href={externalLinks.customerPlayStore}
              className={classes.userAppItem}
              target='_blank'
              rel='nofollow noopener'
            >
              <img
                src='images/user-store.png'
                alt='Play Store'
                className={classes.storeImage}
              />
            </Link>
            <Link
              href={externalLinks.customerAppStore}
              className={classes.userAppItem}
              target='_blank'
              rel='nofollow noopener'
            >
              <img
                src='images/app-store-logo.png'
                alt='App Store'
                className={classes.storeImage}
              />
            </Link>
          </div>
        </div>
        <Typography className={classes.last} variant='h4' >If you have already registered in the application and are not able to access CPC, please logout and login.</Typography>
      </div>
    )
  }
}

RegisterSuccess.propTypes = {
  classes: PropTypes.object.isRequired
}

export default compose(
  withStyles(styles)
)(RegisterSuccess)
