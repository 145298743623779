import React, { Component, Fragment } from 'react'
import { Button, Typography, withStyles } from '@material-ui/core'
import { Link } from 'react-router-dom'

const styles = theme => ({
  infoText: {
    marginTop: '10px'
  }
})

class AddProducts extends Component {
  render () {
    const { classes } = this.props
    return (
      <Fragment>
        <Link to='/products'>
          <Button color='primary' size='small' variant='outlined'>
            Add Product
          </Button>
        </Link >
        <Typography variant='body1' className={classes.infoText}>Upload products that you want to sell through ShopsApp. You can continue uploading products after verification</Typography>
      </Fragment>
    )
  }
}

export default withStyles(styles)(AddProducts)
