export default theme => ({
  root: {
    padding: '20px',
    border: '1px solid #ebebeb',
    borderRadius: '5px'
  },
  actionsContainer: {
    marginTop: '10px'
  },
  finishedText: {
    padding: '0px 25px 20px'
  },
  heading: {
    fontSize: '20px',
    paddingBottom: '10px'
  },
  customerCare: {
    fontWeight: '500',
    paddingBottom: '10px'
  },
  verificationSteps: {
    '& .MuiStepIcon-completed': {
      color: 'green !important'
    }
  },
  submittedHeading: {
    fontSize: '16px',
    padding: '0px 25px 10px',
    fontWeight: '600'
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#21C474 !important',
    '& img': {
      marginRight: '5px'
    }
  }
})
