export default theme => ({
  root: {
    padding: '24px'
  },
  details: {
    marginBottom: '15px'
  },
  title: {
    fontSize: '18px',
    fontWeight: '500',
    marginBottom: '10px'
  },
  value: {
    fontSize: '14px',
    fontWeight: '500'
  }
})
