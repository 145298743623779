import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import {
  Button,
  CircularProgress,
  TextField,
  Typography,
  withStyles
} from '@material-ui/core'
// Component styles
import Select from 'components/Select'

import styles from './style'

class ShopDetails extends Component {
  render () {
    const { classes, values, shopCategories, shopTypes, isValid, submitShopDetails, handleFieldChange, errors, showShopNameError, showCustomerCareError, showCategoryError, showTypeError, isLoading, submitError, showPhoneError } = this.props

    return (<form className={classes.form}>
      <Typography className={classes.title} variant='h2' > Shop Details </Typography>
      <Typography className={classes.subtitle} variant='body1' > Create a new account</Typography>
      <div className={classes.fields}>
        <TextField className={classes.textField} label='Shop name' name='shopName' variant='outlined' required onChange={event => handleFieldChange('shopName', event.target.value)} />
        {showShopNameError && (
          <Typography className={classes.fieldError} variant='body2' > {errors.shopName[0]} </Typography>
        )}
        <TextField className={classes.textField}
          label='Contact Number'
          onChange={event => handleFieldChange('phone', event.target.value)}
          type='text'
          required
          variant='outlined'
          value={values.phone}
        />
        {showPhoneError && (
          <Typography className={classes.fieldError} variant='body2' > {errors.phone[0]} </Typography>
        )}
        <TextField className={classes.textField}
          label='Customer Care Number'
          onChange={event => handleFieldChange('customerCare', event.target.value)}
          type='text'
          required
          variant='outlined'
          value={values.customerCare}
        />
        {showCustomerCareError && (
          <Typography className={classes.fieldError} variant='body2' > {errors.customerCare[0]} </Typography>
        )}
        <div className={classes.selectContainer} >
          <Select placeholder='Shop Type *' options={shopTypes.data} valueParam='type' keyParam='id' selectedValue={values.type} handleChange={(event) => handleFieldChange('type', event.target.value)} />
        </div>
        {showTypeError && (
          <Typography className={classes.fieldError} variant='body2' > {errors.category[0]} </Typography>
        )}
        <div className={classes.selectContainer} >
          <Select placeholder='Shop Categroy *' multiple options={shopCategories.data} valueParam='category' keyParam='id' selectedValue={values.category} handleChange={(event) => handleFieldChange('category', event.target.value)} />
        </div>
        {showCategoryError && (
          <Typography className={classes.fieldError} variant='body2' > {errors.category[0]} </Typography>
        )}

      </div>
      {submitError && (
        <Typography className={classes.submitError} variant='body2' > {submitError} </Typography>
      )}
      {isLoading ? (
        <CircularProgress className={classes.progress} />
      ) : (
        <Button className={classes.signUpButton} color='primary' disabled={!isValid} onClick={submitShopDetails} size='large' variant='contained'>CONTINUE </Button>
      )}
    </form>
    )
  }
}

ShopDetails.propTypes = {
  classes: PropTypes.object.isRequired
}

function mapStateToProps (state) {
  return {
    location: state.address.location,
    shopCategories: state.address.categories,
    shopTypes: state.address.types
  }
}

export default withStyles(styles)(connect(mapStateToProps)(ShopDetails))
