import React, { Component } from 'react'

import PropTypes from 'prop-types'
import classNames from 'classnames'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import CameraAltIcon from '@material-ui/icons/CameraAlt'

import { withStyles, CircularProgress } from '@material-ui/core'
import { Portlet, PortletContent, PortletFooter } from 'components'
import style from './style'
import { getCookie } from 'services/cookie'
import { APIEndpoints, STATUS } from 'redux/constants'
import { showAlert, addShopLogo, getShopDetails } from 'redux/actions'
import { getResizedImage } from 'services/utility'
import treeChanges from 'tree-changes'

class AddShopLogo extends Component {
  state = {
    isUploadingImage: false,
    updatedLogo: null
  }

  componentDidMount = () => {
    const { dispatch } = this.props
    dispatch(getShopDetails())
  }

  componentWillReceiveProps = newProps => {
    const { changedTo } = treeChanges(this.props, newProps)

    if (changedTo('addShopLogo.status', STATUS.ERROR)) {
      this.setState({ isUploadingImage: false })
      this.props.dispatch(showAlert(newProps.addShopLogo.message, { variant: 'error' }))
    } else if (changedTo('addShopLogo.status', STATUS.READY)) {
      this.setState({ isUploadingImage: false })
      this.props.dispatch(showAlert('Changed shop logo successfully', { variant: 'success' }))
    }
  }

  getMaxDimentions = (width, height) => {
    const max = 1000
    let maxWidth, maxHeight
    if (width > height) {
      maxWidth = max
      maxHeight = parseInt(max * height / width)
    } else {
      maxHeight = max
      maxWidth = parseInt(max * width / height)
    }
    return { maxWidth, maxHeight }
  }

  handleImageChange = (e) => {
    e.preventDefault()
    const fileName = e.target.files[0].name
    const initialFile = e.target.files[0]
    const reader = new FileReader()
    reader.readAsDataURL(e.target.files[0])
    reader.onload = event => {
      const img = new Image()
      img.src = event.target.result
      img.onload = (fileLoadEvent) => {
        const { width, height } = fileLoadEvent.target
        const { maxWidth, maxHeight } = this.getMaxDimentions(width, height)
        if (maxWidth > width) {
          this.handleImageUpload(initialFile)
          return
        }
        const elem = document.createElement('canvas')
        elem.width = maxWidth
        elem.height = maxHeight
        const ctx = elem.getContext('2d')
        // img.width and img.height will contain the original dimensions
        ctx.drawImage(img, 0, 0, maxWidth, maxHeight)
        ctx.canvas.toBlob((blob) => {
          const file = new File([blob], fileName, {
            type: 'image/jpeg',
            lastModified: Date.now()
          })
          if (file.size > initialFile.size) {
            this.handleImageUpload(initialFile)
            return
          }
          this.handleImageUpload(file)
        }, 'image/jpeg', 1)
      }
      reader.onerror = error => console.log(error)
    }
  }

  handleImageUpload = async (file) => {
    let data = new FormData()
    data.append('image', file, file.name)
    const authToken = getCookie('auth_token')
    const token = `Bearer ${authToken}`
    const shopId = getCookie('shop_id')
    const url = APIEndpoints.UPLOAD_SHOP_LOGO(shopId)
    const params = {
      method: 'POST',
      body: data,
      headers: {
        'Authorization': token
      }
    }
    this.setState({ isUploadingImage: true })
    fetch(url, params).then(async response => {
      if (response.status > 299) {
        this.props.dispatch(showAlert('Image upload failed. Please try again', { variant: 'error' }))
        this.setState({ isUploadingImage: false })
      } else {
        const result = await response.json()
        const imageUrl = result.url
        this.props.dispatch(addShopLogo(imageUrl))
      }
    }, () => {
      this.setState({ isUploadingImage: false })
      this.props.dispatch(showAlert('Image upload failed. Please try again', { variant: 'error' }))
    })
  }

  onClickFileUpload = event => {
    event.stopPropagation()
    event.target.value = ''
  }

  render () {
    const { classes, className, shopDetails, ...rest } = this.props
    const { isUploadingImage } = this.state
    const rootClassName = classNames(classes.root, className)
    return (
      <Portlet
        {...rest}
        className={rootClassName}
      >
        <PortletContent>
          <div className={classes.details}>
            <div
              className={classes.avatar}
              style={{ backgroundImage: `url(${shopDetails.shop.image_url ? getResizedImage(shopDetails.shop.image_url, 200, 200) : 'images/avatar.png'})` }}
            >
              {isUploadingImage ? <div className={classes.loading} >
                <CircularProgress />
              </div> : null}
              <div className={classes.edit} >
                <input
                  accept='image/*'
                  className={classes.input}
                  style={{ display: 'none' }}
                  id='raised-button-file'
                  onClick={this.onClickFileUpload}
                  onChange={(e) => this.handleImageChange(e)}
                  type='file'
                />
                <label htmlFor='raised-button-file'>
                  <CameraAltIcon className={classes.editIcon} variant='outline' style={{ color: 'white' }} />
                </label>
              </div>
            </div>
          </div>
        </PortletContent>
        <PortletFooter>
          <input
            accept='image/*'
            className={classes.input}
            style={{ display: 'none' }}
            id='raised-button-file-2'
            onClick={this.onClickFileUpload}
            onChange={(e) => this.handleImageChange(e)}
            type='file'
          />
          <label className={classes.editLogo} htmlFor='raised-button-file-2'>
            <div className={classes.logobutton}>UPLOAD SHOP LOGO</div>
          </label>
        </PortletFooter>
      </Portlet>
    )
  }
}

AddShopLogo.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
}

function mapStateToProps (state) {
  return {
    shopDetails: state.settings.shopDetails,
    addShopLogo: state.settings.addShopLogo
  }
}

export default compose(
  withStyles(style)
)(connect(mapStateToProps)(AddShopLogo))
