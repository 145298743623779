import React, { Component } from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import styles from './style'
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import DateTimePicker from 'react-datetime-picker'
import { Button, withStyles, CircularProgress, Typography } from '@material-ui/core'
import moment from 'moment'

import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter
} from 'components'
import { getOrderReport, showAlert } from 'redux/actions'
import { STATUS } from 'redux/constants'
import Select from 'components/Select'
import treeChanges from 'tree-changes'

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
const fileExtension = '.xlsx'
const statusList = [
  {
    id: 'all',
    value: 'All Orders'
  },
  {
    id: 'paid',
    value: 'Paid Orders'
  },
  {
    id: 'unpaid',
    value: 'Unpaid Orders'
  }
]

class Report extends Component {
    state = {
      orderRequest: {
        from: new Date(moment().startOf('day')),
        to: new Date(moment().endOf('day')),
        status: 'all'
      }
    }

    componentWillReceiveProps = newProps => {
      const { getOrderReport } = newProps
      const { changed } = treeChanges(this.props, newProps)
      if (changed('getOrderReport') && getOrderReport.status === STATUS.READY) {
        this.exportToCSV(this.formatOrders(getOrderReport.data.orders, getOrderReport.data.total_price))
        this.props.dispatch(showAlert('Download started successfully', { variant: 'success' }))
      }
    }

    formatOrders = (orders, total) => {
      let modifiedData = orders.map(item => {
        return {
          'Order code': item.code,
          'User name': item.name,
          'User Phone number': item.user_phone,
          'Member ID': item.member_id,
          'Transaction ID': item.payment_details.transaction_id,
          'Bill amount': item.bill_details.product_price,
          'Order total amount': item.bill_details.total_price
        }
      })
      const totalRow = {
        'Bill amount': 'Total Amount:',
        'Order total amount': total
      }
      modifiedData.push(totalRow)
      return modifiedData
    }

    exportToCSV = (csvData) => {
      const { orderRequest } = this.state
      const fileName = `${orderRequest.status}-orders-${moment(orderRequest.from).format('DD/MM/YYYY')}-${moment(orderRequest.to).format('DD/MM/YYYY')}`
      const ws = XLSX.utils.json_to_sheet(csvData)
      const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] }
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
      const data = new Blob([excelBuffer], { type: fileType })
      FileSaver.saveAs(data, fileName + fileExtension)
    }

    handleSaveAction = () => {
      const { orderRequest } = this.state
      this.props.dispatch(getOrderReport(orderRequest.from.getTime(), orderRequest.to.getTime(), orderRequest.status))
    }

    handleStatusChange= value => {
      let { orderRequest } = this.state
      orderRequest.status = value
      this.setState({ orderRequest })
    }

    handleFromDateChange = value => {
      let { orderRequest } = this.state
      orderRequest.from = value
      this.setState({ orderRequest })
    }

    handletoDateChange = value => {
      let { orderRequest } = this.state
      orderRequest.to = value
      this.setState({ orderRequest })
    }

    render () {
      const { classes, getOrderReport } = this.props
      const { orderRequest } = this.state

      return <div className={classes.root}>
        <Portlet
          className={classes.orderReport}
        >
          <PortletHeader>
            <PortletLabel
              title='Order Reports'
            />
          </PortletHeader>
          <PortletContent noPadding>
            <form
              autoComplete='off'
              noValidate
            >
              <div className={classes.formContainer} >
                <div className={classes.datePicker} >
                  <Typography className={classes.date} variant='p'>From Date</Typography>
                  <DateTimePicker
                    disableClock
                    onChange={this.handleFromDateChange}
                    value={orderRequest.from}
                    className={classes.datetime}
                  />
                </div>
                <div className={classes.datePicker} >
                  <Typography className={classes.date} variant='p'>To Date</Typography>
                  <DateTimePicker
                    disableClock
                    onChange={this.handletoDateChange}
                    value={orderRequest.to}
                    className={classes.datetime}
                  />
                </div>
                <div className={classes.selectContainer} >
                  <Select placeholder='Status' options={statusList} valueParam='value' keyParam='id' selectedValue={orderRequest.status} handleChange={(event) => this.handleStatusChange(event.target.value)} />
                </div>
              </div>

            </form>
          </PortletContent>
          {getOrderReport.status === STATUS.RUNNING
            ? <PortletContent className={classes.loaderContainer}>
              <div className={classes.progressWrapper}>
                <CircularProgress />
              </div>
            </PortletContent> : null}
          <PortletFooter className={classes.portletFooter}>
            <Button
              color='primary'
              variant='contained'
              onClick={this.handleSaveAction}
              disabled={getOrderReport.status === STATUS.RUNNING}
            >
                        Download
            </Button>
          </PortletFooter>
        </Portlet>
      </div>
    }
}

function mapStateToProps (state) {
  return {
    getOrderReport: state.settings.getOrderReport
  }
}

export default compose(
  withStyles(styles)
)(connect(mapStateToProps)(Report))
