import React, { Component } from "react";

import classNames from "classnames";
import compose from "recompose/compose";
import { withRouter } from "react-router-dom";
import { getCookie } from "services/cookie";
import { showAlert } from "redux/actions";
import { connect } from "react-redux";

import FacebookIcon from "@material-ui/icons/Facebook";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import TwitterIcon from "@material-ui/icons/Twitter";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import { Typography, withStyles, Grid } from "@material-ui/core";

import { Paper } from "components";

import style from "./style";

class ShareStore extends Component {
  gotoWhatsApp = () => {
    const shopId = getCookie("shop_url");
    window.open(
      `https://api.whatsapp.com/send/?phone&text=Hello ! You can now order online from ${this.props.shopName} using this link: https://www.shopsapp.in/shops/${shopId}`,
      "blank"
    );
  };

  gotoFacebook = () => {
    const shopId = getCookie("shop_url");
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=https://www.shopsapp.in/shops/${shopId}`,
      "blank"
    );
  };

  gotoTwitter = () => {
    const shopId = getCookie("shop_url");
    window.open(
      `https://twitter.com/intent/tweet?text=Hello ! You can now order online from ${this.props.shopName} using this link: https://www.shopsapp.in/shops/${shopId}`,
      "blank"
    );
  };

  getSharableLink = (shopLink) => {
    const link = shopLink;
    let textField = document.createElement("textarea");
    textField.innerText = link;
    document.body.appendChild(textField);
    textField.select();
    let success = document.execCommand("copy");
    textField.remove();
    success = success || document.execCommand("copy", false, link);
    if (success) {
      this.props.dispatch(
        showAlert("Copied shop link to clipboard!", { variant: "success" })
      );
    } else {
      this.props.dispatch(
        showAlert("Failed to copy shop link to clipboard!", {
          variant: "error",
        })
      );
    }
  };

  render() {
    const { classes, className, shopLink, ...rest } = this.props;
    const rootClassName = classNames(classes.root, className);

    return (
      <Paper {...rest} className={rootClassName}>
        <div className={classes.storeLink}>
          <Typography className={classes.heading}>
            Share your shop with your customers
          </Typography>
          <div
            className={classes.shopUrl}
            onClick={() => this.getSharableLink(shopLink)}
          >
            {shopLink}
          </div>
          <Grid
            container
            justify="center"
            spacing={2}
            className={classes.icons}
          >
            <Grid item xs>
              <FileCopyOutlinedIcon
                className={classes.copyIcon}
                onClick={() => this.getSharableLink(shopLink)}
              />
            </Grid>
            <Grid item xs>
              <FacebookIcon
                className={classes.facebookIcon}
                onClick={this.gotoFacebook}
              />
            </Grid>
            <Grid item xs>
              <WhatsAppIcon
                className={classes.whatsappIcon}
                onClick={this.gotoWhatsApp}
              />
            </Grid>
            <Grid item xs>
              <TwitterIcon
                className={classes.twitterIcon}
                onClick={this.gotoTwitter}
              />
            </Grid>
          </Grid>
        </div>
      </Paper>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default compose(
  withStyles(style),
  withRouter
)(connect(mapStateToProps)(ShareStore));
