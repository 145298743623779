import React, { Component } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";
import { Grid, withStyles, Button } from "@material-ui/core";
import compose from "recompose/compose";
import { withRouter } from "react-router-dom";
import { getCookie } from "services/cookie";

import style from "./style";
import ContactDetailsModal from "views/Common/ContactDetailsModal";
import { showAlert } from "redux/actions";

class ShopActions extends Component {
  state = {
    showContact: false,
  };
  getSharableLink = () => {
    const shopId = getCookie("shop_url");
    const link = `https://shopsapp.in/shops/${shopId}`;
    let textField = document.createElement("textarea");
    textField.innerText = link;
    document.body.appendChild(textField);
    textField.select();
    let success = document.execCommand("copy");
    textField.remove();
    success = success || document.execCommand("copy", false, link);
    if (success) {
      this.props.dispatch(
        showAlert("Copied shop link to clipboard!", { variant: "success" })
      );
    } else {
      this.props.dispatch(
        showAlert("Failed to copy shop link to clipboard!", {
          variant: "error",
        })
      );
    }
  };

  copyContact = (type) => {
    let link = "hello@inventlabs.org";
    if (type === "phone") {
      link = "+917736043121";
    }
    let textField = document.createElement("textarea");
    textField.innerText = link;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    // document.execCommand('copy', false, link)
    this.props.dispatch(
      showAlert(
        `Copied ${
          type === "phone" ? "Phone Number" : "Email Address"
        } Successfully`,
        { variant: "success" }
      )
    );
  };

  openContact = () => {
    this.setState({ showContact: true });
  };

  closeContact = () => {
    this.setState({ showContact: false });
  };

  render() {
    const { classes } = this.props;
    const { showContact } = this.state;

    return (
      <React.Fragment>
        <Grid
          item
          lg={3}
          md={6}
          xl={3}
          xs={12}
          className={classes.buttonContainer}
        >
          <Button
            className={classes.button}
            color="primary"
            size="large"
            variant="contained"
            onClick={this.getSharableLink}
          >
            {" "}
            Get shop link{" "}
          </Button>
        </Grid>
        <Grid
          item
          lg={3}
          md={6}
          xl={3}
          xs={12}
          className={classes.buttonContainer}
        >
          <Button
            className={classes.button}
            color="secondary"
            size="large"
            variant="contained"
            onClick={this.openContact}
          >
            Contact ShopsApp
          </Button>
        </Grid>
        {/* <Grid
          item
          lg={3}
          md={6}
          xl={3}
          xs={12}
          className={classes.buttonContainer}
        >
          <Button className={classes.button} color='primary' size='large' variant='contained' onClick={this.showChangePassword} >Change Password</Button>
        </Grid> */}
        {/* <Grid
          item
          lg={3}
          md={6}
          xl={3}
          xs={12}
          className={classes.buttonContainer}
        >
          <Button className={classes.button} color='secondary' size='large' variant='contained' onClick={this.goToCategory} >Add Categories</Button>
        </Grid> */}
        <ContactDetailsModal
          isOpen={showContact}
          onCancel={this.closeContact}
          copyContact={this.copyContact}
        />
      </React.Fragment>
    );
  }
}

ShopActions.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {};
}

export default compose(
  withStyles(style),
  withRouter
)(connect(mapStateToProps)(ShopActions));
