import { handleActions } from 'redux-actions'
import immutable from 'immutability-helper'

import { parseError } from 'services/client'

import { ActionTypes, STATUS } from 'redux/constants/index'

export const planState = {
  planPricing: {
    data: [],
    message: '',
    showAlert: false
  },
  paymentDetails: {
    data: [],
    message: '',
    showAlert: false
  }
}

export default {
  plan: handleActions(
    {
      [ActionTypes.GET_PLAN_PRICING]: (state, { payload }) =>
        immutable(state, {
          planPricing: {
            status: { $set: STATUS.RUNNING }
          }
        }),
      [ActionTypes.GET_PLAN_PRICING_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          planPricing: {
            data: { $set: payload },
            status: { $set: STATUS.READY }
          }
        }),
      [ActionTypes.GET_PLAN_PRICING_FAILURE]: (state, { payload }) =>
        immutable(state, {
          planPricing: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        }),

      [ActionTypes.GET_SUBSCRIPTION_PAYMENT_LINK]: (state, { payload }) =>
        immutable(state, {
          paymentDetails: {
            status: { $set: STATUS.RUNNING }
          }
        }),
      [ActionTypes.GET_SUBSCRIPTION_PAYMENT_LINK_SUCCESS]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          paymentDetails: {
            data: { $set: payload },
            status: { $set: STATUS.READY }
          }
        }),
      [ActionTypes.GET_SUBSCRIPTION_PAYMENT_LINK_FAILURE]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          paymentDetails: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        })
    },
    planState
  )
}
