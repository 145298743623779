export default theme => ({
  root: {},
  field: {
    margin: theme.spacing.unit * 3,
    marginTop: '10px',
    '& > div': {
      width: `calc( 50% - ${theme.spacing.unit * 1.5}px )`,
      '&:first-child': {
        marginRight: theme.spacing.unit * 3
      }
    }
  },
  textField: {
    width: `calc( 50% - ${theme.spacing.unit * 1.5}px )`,
    '&:first-child': {
      marginRight: theme.spacing.unit * 3
    }
  },
  progressWrapper: {
    width: '100%',
    height: 300,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  portletFooter: {
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    '& button': {
      marginRight: 20
    }
  },
  acceptStatus: {
    marginBottom: 0,
    padding: theme.spacing.unit * 3,
    paddingBottom: 20,
    display: 'flex'
  },
  statusTitle: {
    fontWeight: 'bold',
    fontSize: 17,
    lineHeight: '38px',
    marginRight: 20
  }
})
