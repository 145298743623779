import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import {
  Button,
  CircularProgress,
  TextField,
  Typography,
  withStyles,
  InputAdornment
} from '@material-ui/core'
// Component styles

import styles from './style'

class UserDetails extends Component {
  render () {
    const { classes, values, isValid, submitUserDetails, handleFieldChange, errors, showFirstNameError, showLastNameError, showEmailError, showPasswordError, isLoading, submitError, referCodeDisable } = this.props

    const onNumberChange = (event) => {
      const { value } = event.target
      const reg = /^-?\d*(\.\d*)?$/
      if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
        handleFieldChange('phoneNumber', value)
      }
    }
    return (
      <form className={classes.form}>
        <Typography className={classes.title} variant='h2'> Create a new account </Typography>
        <div className={classes.fields}>
          <TextField className={classes.textField} label='First name' name='firstName' variant='outlined'
            onChange={event => handleFieldChange('firstName', event.target.value)} />
          {showFirstNameError && (
            <Typography className={classes.fieldError} variant='body2'> {errors.firstName[0]} </Typography>
          )}
          <TextField className={classes.textField}
            label='Phone Number'
            onChange={onNumberChange}
            type='text'
            variant='outlined'
            value={values.phoneNumber}
            InputProps={{
              startAdornment: <InputAdornment position='start'>+91</InputAdornment>
            }}
          />
          {showLastNameError && (
            <Typography className={classes.fieldError} variant='body2'> {errors.phoneNumber[0]} </Typography>
          )}
          <TextField
            className={classes.textField}
            label='Email address'
            name='email'
            onChange={event =>
              handleFieldChange('email', event.target.value)
            }
            variant='outlined'
            value={values.email}
          />
          {showEmailError && (
            <Typography className={classes.fieldError} variant='body2'> {errors.email[0]} </Typography>
          )}
          <TextField
            className={classes.textField}
            label='Password'
            onChange={event =>
              handleFieldChange('password', event.target.value)
            }
            type='password'
            variant='outlined'
            value={values.password}
          />
          {showPasswordError && (
            <Typography className={classes.fieldError} variant='body2'> {errors.password[0]} </Typography>
          )}
          <TextField className={classes.textField}
            label='Referral Code (Optional)'
            onChange={event =>
              handleFieldChange('referCode', event.target.value)
            }
            type='text'
            variant='outlined'
            value={values.referCode}
            disabled={referCodeDisable}
          />
          <div className={classes.policy}>
            <Typography className={classes.policyText} variant='body1'> By creating an account, I accept the {' '}
              <Link
                className={classes.policyUrl}
                to='privacy-policy'
                target='_blank'
              >
                Privacy Policy
              </Link>
              {' '} and {' '}
              <Link
                className={classes.policyUrl}
                to='terms-and-conditions'
                target='_blank'
              >
                Terms and Conditions
              </Link>
            </Typography>
          </div>
        </div>
        {submitError && (
          <Typography className={classes.submitError} variant='body2'> {submitError} </Typography>
        )}
        {isLoading ? (
          <CircularProgress className={classes.progress} />
        ) : (
          <Button className={classes.signUpButton} color='primary' disabled={!isValid} onClick={submitUserDetails}
            size='large' variant='contained'>SIGNUP </Button>
        )}
        <Typography className={classes.signIn} variant='body1'>
          Have an account?{' '}
          <Link className={classes.signInUrl} to='/sign-in'>Sign In</Link>
        </Typography>
        <Typography className={classes.totalCustomer}>
          80,000 + customers | 400 + Shops and counting....
        </Typography>
      </form>
    )
  }
}

UserDetails.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(UserDetails)
