export default theme => ({
  tableContainer: {
    width: '90%',
    margin: 'auto',
    marginTop: '20px',
    marginBottom: '30px',
    boxShadow: 'none'
  },
  featureTable: {
    border: '2px solid rgb(240, 241, 242)'
  },
  tableHead: {
    fontSize: '1.125em',
    fontWeight: '500',
    fontFamily: 'Poppins',
    textAlign: 'center',
    border: '1px solid rgb(240, 241, 242)'
  },
  emptyHead: {
    background: '#f9fafb',
    border: 'none'
  },
  priceContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '5px',
    marginBottom: '10px'
  },
  priceSection: {
    display: 'inline-block',
    lineHeight: '1',
    verticalAlign: 'top',
    '& sup': {
      display: 'inline-block',
      verticalAlign: 'top',
      marginTop: '0.3em',
      fontSize: '1em'
    }
  },
  price: {
    paddingTop: '10px',
    fontSize: '2.65em',
    fontWeight: '700'
  },
  billingPeriodContainer: {
    lineHeight: '8px'
  },
  billingPeriod: {
    fontSize: '13px',
    color: 'grey'
  },
  tableSubHeadingRow: {
    height: '30px',
    background: '#f0f1f2'
  },
  tableSubHeading: {
    fontFamily: 'Poppins',
    fontWeight: '600'
  },
  futureTitle: {
    border: '1px solid rgb(240, 241, 242)',
    fontFamily: 'Poppins'
  },
  futureCell: {
    border: '1px solid rgb(240, 241, 242)',
    fontFamily: 'Poppins'
  }
})
