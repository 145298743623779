import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles, TextField, Typography, Switch } from '@material-ui/core'
import Modal from 'views/Modal'
import moment from 'moment'
import DateTimePicker from 'react-datetime-picker'
import treeChanges from 'tree-changes'

const heading = {
  'confirmed': 'Confirm Order',
  'shop_confirmed': 'Set as Packed',
  'shop_packed': 'Mark out for delivery',
  'shop_delivery': 'Mark as delivered',
  'edit_delivery': 'Update Delivery Time',
  'cancel': 'Cancel Order'
}

const buttonText = {
  'confirmed': 'Confirm',
  'shop_confirmed': 'Set as Packed',
  'shop_packed': 'Delivery Ready',
  'shop_delivery': 'Mark as delivered',
  'edit_delivery': 'Update',
  'cancel': 'Confirm'
}

const styles = theme => ({
  container: {
    // display: 'flex',
    '& .react-datetime-picker': {
      width: '100%',
      height: 35,
      marginTop: 20,
      '& > div': {
        borderRadius: '2px'
      }
    }
  },
  priceContainer: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'space-between'
  },
  textField: {
    width: '100%'
  },
  priceTextField: {
    width: 'calc( 50% - 10px)'
  },
  switchContainer: {
    width: 'calc( 50% - 10px)',
    display: 'flex',
    flexDirection: 'column'
    // justifyContent: 'center'
  },
  error: {
    color: 'red'
  },
  editableDesc: {
    fontSize: 13
  }
})

class ConfirmOrder extends Component {
  state = {
    message: '',
    isEditable: false,
    price: this.props.price,
    time: this.props.delivery ? new Date(this.props.delivery) : moment().add(4, 'hour')
    // time: moment(this.props.delivery).format('YYYY-MM-DTHH:mm:ss') || moment().add(2, 'days').format('YYYY-MM-DTHH:mm:ss')
  }

  componentWillReceiveProps = nextProps => {
    const { changed } = treeChanges(this.props, nextProps)
    if (changed('delivery') || changed('price')) {
      this.setState({ time: new Date(nextProps.delivery), price: nextProps.price })
    }
  }

  handleChange = e => {
    const { value } = e.target
    this.setState({ message: value })
  }

  handleChangePrice = e => {
    const { value } = e.target
    const price = value || 0
    this.setState({ price })
  }

  handleDateChange = value => {
    this.setState({ time: value })
  }

  toggleEditable = (event) => {
    const { checked } = event.target
    this.setState({ isEditable: checked })
  }

  onSubmit = () => {
    const { message, time, price } = this.state
    const { onSubmit, type } = this.props
    this.setState({ error: false })
    const deliveryTime = new Date(time).getTime()
    if ((type === 'edit_delivery' || type === 'confirmed') && !deliveryTime) {
      this.setState({ error: true })
      return
    }
    onSubmit(message, deliveryTime, parseFloat(price))
  }

  render () {
    const { classes, type, onCancel, isOpen } = this.props
    const { message, time, error, price, isEditable } = this.state
    const child = <div className={classes.container}>
      {/* <Typography className={classes.warning} variant='p'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. of Lorem Ipsum.</Typography> */}
      <TextField
        className={classes.textField}
        label={type === 'cancel' ? 'Reason' : 'Message'}
        margin='dense'
        onChange={this.handleChange}
        value={message}
        variant='outlined'
      />
      {type === 'confirmed' || type === 'edit_delivery' ? <DateTimePicker
        onChange={this.handleDateChange}
        value={time}
      /> : null}
      {error ? <Typography className={classes.error} variant='p'>Please Fill the date</Typography> : null}
      {type === 'confirmed' ? <div className={classes.priceContainer} >
        <TextField
          className={classes.priceTextField}
          label={'Acutal Price'}
          margin='dense'
          onChange={this.handleChangePrice}
          value={price}
          disabled={!isEditable}
          variant='outlined'
        />
        <div className={classes.switchContainer}>
          <Typography className={classes.editableDesc} variant='p'>Edit Price</Typography>
          <Switch
            className={classes.switch}
            checked={isEditable}
            onChange={this.toggleEditable}
            value='checkedA'
            // disabled={isLoading}
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        </div>
      </div> : null}
    </div>
    return (
      <Modal isOpen={isOpen} title={heading[type]} onCancel={onCancel} okText={buttonText[type]} onSubmit={this.onSubmit} child={child} />
    )
  }
}

ConfirmOrder.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired
}

export default withStyles(styles)(ConfirmOrder)
