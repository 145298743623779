export default (theme) => ({
  priceCard: {
    borderRadius: '16px',
    width: '320px',
    marginBottom: '10px',
    boxShadow: '0 10px 6px -6px #777',
    border: '1px solid #f0f0f0'
  },
  priceCardBackground: {
    background: 'ghostwhite;'
  },
  planType: {
    color: '#0B6430',
    fontSize: '14px',
    fontFamily: 'Poppins, sans-serif',
    fontWeight: '500'
  },
  planTitle: {
    color: '#707070',
    fontSize: '22px',
    fontFamily: 'Poppins, sans-serif',

    paddingTop: '10px'
  },
  icon: {
    width: '33px',
    height: '33px',
    borderRadius: '50px',
    background: 'linear-gradient(to bottom right, #39BE6F, #548A6A)'
  },
  planPrice: {
    fontSize: '24px',
    fontFamily: 'Montserrat',
    paddingTop: '10px',
    '& span': {
      fontSize: '18px',
      textDecoration: 'line-through',
      marginRight: '5px'
    }
  },
  planDescription: {
    fontSize: '15px',
    fontFamily: 'Poppins, sans-serif',
    paddingTop: '5px',
    color: '#707070'
  },
  subHeading: {
    fontSize: '15px',
    fontFamily: 'Poppins, sans-serif',
    paddingTop: '5px',
    color: '#707070',
    fontWeight: 600,
    paddingBottom: '5px'
  },
  planFeatures: {
    fontSize: '13px',
    fontFamily: 'Poppins, sans-serif',
    color: '#707070',
    lineHeight: '25px',
    '& ul': {
      padding: '0px 15px'
    }
  },
  planShowButton: {
    margin: '10px 0 20px',
    color: '#0B6430',
    cursor: 'pointer',
    fontFamily: 'Poppins, sans-serif',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  plusIcon: {
    fontSize: '10px'
  },
  subscribeButton: {
    color: 'white',
    cursor: 'pointer',
    padding: '10px 50px',
    borderRadius: '16px',
    backgroundColor: '#32a05f',
    margin: '15px auto',
    display: 'block',
    '&:hover': {
      backgroundColor: '#32a05f !important'
    }
  }
})
