import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles'
import { Typography, Button, Grid } from '@material-ui/core/'
import { withRouter } from 'react-router-dom'
import compose from 'recompose/compose'

import Dots from '../../../../assets/icons/Dots'

const styles = theme => ({
  container: {
    background: '#EEEEEE'
  },
  heading: {
    paddingTop: '50px',
    fontFamily: 'Poppins, sans-serif',

    textAlign: 'center',
    margin: '0 30px 0'
  },
  gridContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: '100px 80px 100px'
  },
  dots: {
    display: 'flex',
    paddingTop: '6% !important'
  },
  title: {
    paddingTop: '30px',
    fontFamily: 'Poppins, sans-serif',

    textAlign: 'center',
    width: '150px'
  },
  storeContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& img': {
      marginRight: '10px',
      marginBottom: '75px',
      cursor: 'pointer'
    }
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    margin: '0px 0 40px'
  },
  signButton: {
    backgroundColor: '#32a05f',
    marginRight: '10px',
    padding: '10px 20px 10px',
    borderRadius: '16px',
    color: 'white',
    cursor: 'pointer'
  }
})

class RegistrationProcess extends Component {
  render () {
    const { classes, history } = this.props

    const gotoPlayStore = () => {
      window.open('https://play.google.com/store/apps/details?id=org.inventlabs.shopsapp.business')
    }

    const gotoAppStore = () => {
      window.open('https://apps.apple.com/in/app/shopsapp-for-merchants/id1538128400')
    }

    const gotoSignup = () => {
      history.push('/sign-up')
    }
    return (
      <div className={classes.container}>
        <Typography variant='h2' component='h2' gutterBottom className={classes.heading}>
          How it works?
        </Typography>
        <Grid container spacing={1} className={classes.gridContainer}>
          <Grid item>
            <img src='images/home/RegistrationIcon.png' alt='regicon' width='auto' height='140px' />
            <Typography variant='h4' component='h2' className={classes.title}>
              Registration
            </Typography>
          </Grid>
          <Grid item className={classes.dots}>
            <Dots />
          </Grid>
          <Grid item>
            <img src='images/home/AddProductIcon.png' alt='AddProductIcon' width='auto' height='140px' />
            <Typography variant='h4' component='h2' className={classes.title}>
              Add products
            </Typography>
          </Grid>
          <Grid item className={classes.dots}>
            <Dots />
          </Grid>
          <Grid item>
            <img src='images/home/VerificationIcon.png' alt='VerificationIcon' width='auto' height='140px' />
            <Typography variant='h4' component='h2' className={classes.title}>
              Add payment and delivery details
            </Typography>
          </Grid>
          <Grid item className={classes.dots}>
            <Dots />
          </Grid>
          <Grid item>
            <img src='images/home/SellingIcon.png' alt='SellingIcon' width='auto' height='140px' />
            <Typography variant='h4' component='h2' className={classes.title}>
              Start selling
            </Typography>
          </Grid>
        </Grid>
        <Grid className={classes.buttonContainer}>
          <Button variant='contained' className={classes.signButton} onClick={gotoSignup}>Register now</Button>
        </Grid>
        <Grid className={classes.storeContainer}>
          <img src='/images/badge-google.png' alt='play-store' width='148px' height='58px' onClick={gotoPlayStore} />
          <img src='/images/app-store-logo.png' alt='app-store' width='130px' height='40px' onClick={gotoAppStore} />
        </Grid>

      </div>
    )
  }
}

export default compose(
  withRouter,
  withStyles(styles)
)(RegistrationProcess)
