import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Avatar from '@material-ui/core/Avatar'

import Curve from '../../../../assets/icons/Curve'

const styles = theme => ({

  container: {
    margin: '0 calc(100vw - 85vw) 0',
    marginTop: '-40em'
  },
  heading: {
    fontFamily: 'Poppins, sans-serif',
    fontWeight: 500,
    textAlign: 'center'
  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
    maxWidth: 900,
    display: 'block',
    borderRadius: '25px'
  },
  avatar: {
    margin: 'auto'
  },
  subtitle1: {
    textAlign: 'center',
    fontFamily: 'Poppins, sans-serif',
    paddingTop: '20px'
  },
  subtitle2: {
    textAlign: 'center',
    fontFamily: 'Poppins, sans-serif',
    paddingBottom: '20px',
    paddingTop: '20px',
    fontWeight: '600'

  }
})

class Testimonials extends Component {
  render () {
    const { classes } = this.props
    return (
      <>
        <Curve />
        <div className={classes.container}>
          <Grid container spacing={6} className={classes.boxContainer}>
            <Grid item xs={12} sm={12} lg={12} md={12}>
              <Typography variant='h2' gutterBottom className={classes.heading}>
                Our happy partners
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} lg={4} md={4}>
              <Paper className={classes.paper}>
                <Avatar className={classes.avatar}>H</Avatar>
                <Typography variant='subtitle1' gutterTop className={classes.subtitle1}>
                  We are immensely satisfied with the marketing push Zomato has provided us. We are now exclusive with Zomato and look forward to more growth in our delivery business through online orders.
                </Typography>
                <Typography variant='subtitle2' className={classes.subtitle2}>
                  Pawan Kumar
                  Owner - Chicago BR, Dehradun
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} lg={4} md={4}>
              <Paper className={classes.paper}>
                <Avatar className={classes.avatar}>H</Avatar>
                <Typography variant='subtitle1' gutterTop className={classes.subtitle1}>
                  We are immensely satisfied with the marketing push Zomato has provided us. We are now exclusive with Zomato and look forward to more growth in our delivery business through online orders.
                </Typography>
                <Typography variant='subtitle2' className={classes.subtitle2}>
                  Pawan Kumar
                  Owner - Chicago BR, Dehradun
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} lg={4} md={4}>
              <Paper className={classes.paper}>
                <Avatar className={classes.avatar}>H</Avatar>
                <Typography variant='subtitle1' gutterTop className={classes.subtitle1}>
                  We are immensely satisfied with the marketing push Zomato has provided us. We are now exclusive with Zomato and look forward to more growth in our delivery business through online orders.
                </Typography>
                <Typography variant='subtitle2' className={classes.subtitle2}>
                  Pawan Kumar
                  Owner - Chicago BR, Dehradun
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </>
    )
  }
}

export default withStyles(styles)(Testimonials)
