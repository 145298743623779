import { handleActions } from 'redux-actions'
import immutable from 'immutability-helper'
import { parseError } from 'services/client'
import _ from 'lodash'

import { ActionTypes, STATUS } from 'redux/constants/index'

export const productState = {
  list: {
    message: '',
    data: [],
    status: STATUS.IDLE
  },
  variantlist: {
    message: '',
    data: [],
    status: STATUS.IDLE
  },
  details: {
    message: '',
    data: {},
    status: STATUS.IDLE
  },
  updateProduct: {
    message: '',
    data: {},
    status: STATUS.IDLE
  },
  searchResult: {
    message: '',
    data: {},
    status: STATUS.IDLE
  },
  statusChange: {
    message: '',
    data: {},
    status: STATUS.IDLE
  },
  delete: {
    message: '',
    data: {},
    status: STATUS.IDLE
  },
  downloadSampleFile: {
    message: '',
    data: {},
    status: STATUS.IDLE
  }
}

export default {
  products: handleActions(
    {
      [ActionTypes.PRODUCT_GET_LIST]: (state, { payload }) =>
        immutable(state, {
          list: {
            searchParam: { $set: payload },
            status: { $set: STATUS.RUNNING },
            reachedEnd: { $set: false }
          }
        }),
      [ActionTypes.PRODUCT_GET_LIST_SUCCESS]: (state, { payload }) => {
        let data = _.cloneDeep(state.list.data)

        if (state.list.searchParam && state.list.searchParam.skip === 0) {
          data = payload
        } else {
          data = [...data, ...payload]
        }
        console.log('reducer', state.list.searchParam, data)
        return immutable(state, {
          list: {
            data: { $set: data },
            status: { $set: STATUS.READY },
            reachedEnd: { $set: !payload.length || payload.length !== state.list.searchParam.limit }
          }
        })
      },
      [ActionTypes.PRODUCT_GET_LIST_FAILURE]: (state, { payload }) =>
        immutable(state, {
          list: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        }),
//testing
[ActionTypes.PRODUCT_GET_VARIANTS]: (state, { payload }) =>
        immutable(state, {
          variantlist: {
            searchParam: { $set: payload },
            status: { $set: STATUS.RUNNING },
            reachedEnd: { $set: false }
          }
        }),
      [ActionTypes.PRODUCT_GET_VARIANTS_SUCCESS]: (state, { payload }) => {
        let data = _.cloneDeep(state.variantlist.data)

        if (state.variantlist.searchParam && state.variantlist.searchParam.skip === 0) {
          data = payload
        } else {
          data = [...data, ...payload]
        }
        console.log('reducer', state.variantlist.searchParam, data)
        return immutable(state, {
          variantlist: {
            data: { $set: data },
            status: { $set: STATUS.READY },
            reachedEnd: { $set: !payload.length || payload.length !== state.variantlist.searchParam.limit }
          }
        })
      },
      [ActionTypes.PRODUCT_GET_VARIANTS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          variantlist: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        }),
        

      [ActionTypes.PRODUCT_GET_DETAILS]: (state, { payload }) =>
        immutable(state, {
          details: {
            status: { $set: STATUS.RUNNING }
          }
        }),
      [ActionTypes.PRODUCT_GET_DETAILS_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          details: {
            data: { $set: payload.item },
            status: { $set: STATUS.READY }
          }
        }),
      [ActionTypes.PRODUCT_GET_DETAILS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          details: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        }),

      [ActionTypes.UPDATE_PRODUCT]: (state, { payload }) =>
        immutable(state, {
          updateProduct: {
            status: { $set: STATUS.RUNNING }
          }
        }),
      [ActionTypes.UPDATE_PRODUCT_SUCCESS]: (state, { payload }) => {
        const { list, details } = _.cloneDeep(state)
        let { data } = list
        const index = data.findIndex(item => item.id === payload.id)
        if (index > -1) {
          data[index] = payload
        }
        if (details.data.id === payload.id) {
          details.data = payload
        }
        return immutable(state, {
          list: {
            data: { $set: data },
            status: { $set: STATUS.READY }
          },
          updateProduct: {
            data: { $set: payload },
            status: { $set: STATUS.READY }
          },
          details: { $set: details }
        })
      },
      [ActionTypes.UPDATE_PRODUCT_FAILURE]: (state, { payload }) =>
        immutable(state, {
          updateProduct: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        }),

      [ActionTypes.CREATE_PRODUCT]: (state, { payload }) =>
        immutable(state, {
          updateProduct: {
            status: { $set: STATUS.RUNNING }
          }
        }),
      [ActionTypes.CREATE_PRODUCT_SUCCESS]: (state, { payload }) => {
        const { list } = _.cloneDeep(state)
        let { data } = list
        data.push(payload)
        return immutable(state, {
          list: {
            data: { $set: data },
            status: { $set: STATUS.READY }
          },
          updateProduct: {
            data: { $set: payload },
            status: { $set: STATUS.READY }
          }
        })
      },
      [ActionTypes.CREATE_PRODUCT_FAILURE]: (state, { payload }) =>
        immutable(state, {
          updateProduct: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        }),

      [ActionTypes.PRODUCT_SEARCH_RESULT]: (state, { payload }) =>
        immutable(state, {
          searchResult: {
            status: { $set: STATUS.RUNNING }
          }
        }),
      [ActionTypes.PRODUCT_SEARCH_RESULT_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          searchResult: {
            data: { $set: payload },
            status: { $set: STATUS.READY }
          }
        }),
      [ActionTypes.PRODUCT_SEARCH_RESULT_FAILURE]: (state, { payload }) =>
        immutable(state, {
          searchResult: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        }),

      [ActionTypes.CHANGE_STOCK_STATUS]: (state, { payload }) => {
        return immutable(state, {
          statusChange: {
            data: { $set: payload.statusRequest },
            status: { $set: STATUS.RUNNING }
          }
        })
      },
      [ActionTypes.CHANGE_STOCK_STATUS_SUCCESS]: (state, { payload }) => {
        const { list } = _.cloneDeep(state)
        let { data } = list
        const index = data.findIndex(item => item.id === payload.id)
        if (index > -1) {
          data[index] = payload
        }
        return immutable(state, {
          list: {
            data: { $set: data },
            status: { $set: STATUS.READY }
          },
          statusChange: {
            status: { $set: STATUS.READY }
          }
        })
      },
      [ActionTypes.CHANGE_STOCK_STATUS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          statusChange: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        }),

      [ActionTypes.DELETE_PRODUCT]: (state, { payload }) =>
        immutable(state, {
          delete: {
            status: { $set: STATUS.RUNNING }
          }
        }),
      [ActionTypes.DELETE_PRODUCT_SUCCESS]: (state, { payload }) => {
        const { list } = _.cloneDeep(state)
        let { data } = list
        const index = data.findIndex(item => item.id === payload.id)
        if (index > -1) {
          data.splice(index, 1)
        }
        return immutable(state, {
          list: {
            data: { $set: data },
            status: { $set: STATUS.READY }
          },
          delete: {
            data: { $set: payload },
            status: { $set: STATUS.READY }
          }
        })
      },
      [ActionTypes.DELETE_PRODUCT_FAILURE]: (state, { payload }) =>
        immutable(state, {
          delete: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        }),

      [ActionTypes.DOWNLOAD_SAMPLE_FILE]: (state, { payload }) =>
        immutable(state, {
          downloadSampleFile: {
            status: { $set: STATUS.RUNNING }
          }
        }),
      [ActionTypes.DOWNLOAD_SAMPLE_FILE_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          downloadSampleFile: {
            data: { $set: payload },
            status: { $set: STATUS.READY }
          }
        })
      },
      [ActionTypes.DOWNLOAD_SAMPLE_FILE_FAILURE]: (state, { payload }) =>
        immutable(state, {
          downloadSampleFile: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        })

    },
    productState
  )
}
