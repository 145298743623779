export default theme => ({
  button: {
    width: '100%',
    '& svg': {
      marginRight: '5px'
    }
  },
  buttonContainer: {
    padding: '0 12px',
    marginBottom: 20
  }
})
