import keyMirror from 'fbjs/lib/keyMirror'

/**
 * @namespace Constants
 * @desc App constants
 */

/**
 * @constant {Object} ActionTypes
 * @memberof Constants
 */
export const ActionTypes = keyMirror({
  /**
   * Account
   */

  USER_LOGIN: undefined,
  USER_LOGIN_SUCCESS: undefined,
  USER_LOGIN_FAILURE: undefined,

  USER_SIGNUP: undefined,
  USER_SIGNUP_SUCCESS: undefined,
  USER_SIGNUP_FAILURE: undefined,

  USER_REGISTER: undefined,
  USER_REGISTER_SUCCESS: undefined,
  USER_REGISTER_FAILURE: undefined,

  USER_LOGOUT: undefined,
  USER_LOGOUT_SUCCESS: undefined,
  USER_LOGOUT_FAILURE: undefined,

  FORGOT_PASSWORD: undefined,
  FORGOT_PASSWORD_SUCCESS: undefined,
  FORGOT_PASSWORD_FAILURE: undefined,

  RESET_PASSWORD: undefined,
  RESET_PASSWORD_SUCCESS: undefined,
  RESET_PASSWORD_FAILURE: undefined,

  CHANGE_PASSWORD: undefined,
  CHANGE_PASSWORD_SUCCESS: undefined,
  CHANGE_PASSWORD_FAILURE: undefined,

  SET_AUTHENTICATED: undefined,
  CLEAR_FORGOT: undefined,

  REFRESH_TOKEN: undefined,
  REFRESH_TOKEN_SUCCESS: undefined,
  REFRESH_TOKEN_ERROR: undefined,

  SHOW_ALERT: undefined,
  HIDE_ALERT: undefined,

  GET_SHOP_CATEGORIES: undefined,
  GET_SHOP_CATEGORIES_SUCCESS: undefined,
  GET_SHOP_CATEGORIES_FAILURE: undefined,

  GET_SHOP_TYPES: undefined,
  GET_SHOP_TYPES_SUCCESS: undefined,
  GET_SHOP_TYPES_FAILURE: undefined,
  /**
   * Products
   */
  PRODUCT_GET_LIST: undefined,
  PRODUCT_GET_LIST_SUCCESS: undefined,
  PRODUCT_GET_LIST_FAILURE: undefined,

  PRODUCT_GET_VARIANTS: undefined,
  PRODUCT_GET_VARIANTS_SUCCESS: undefined,
  PRODUCT_GET_VARIANTS_FAILURE: undefined,

  PRODUCT_GET_DETAILS: undefined,
  PRODUCT_GET_DETAILS_SUCCESS: undefined,
  PRODUCT_GET_DETAILS_FAILURE: undefined,

  UPLOAD_PRODUCT_IMAGE: undefined,
  UPLOAD_PRODUCT_IMAGE_SUCCESS: undefined,
  UPLOAD_PRODUCT_IMAGE_FAILURE: undefined,

  UPDATE_PRODUCT: undefined,
  UPDATE_PRODUCT_SUCCESS: undefined,
  UPDATE_PRODUCT_FAILURE: undefined,

  CREATE_PRODUCT: undefined,
  CREATE_PRODUCT_SUCCESS: undefined,
  CREATE_PRODUCT_FAILURE: undefined,

  DELETE_PRODUCT: undefined,
  DELETE_PRODUCT_SUCCESS: undefined,
  DELETE_PRODUCT_FAILURE: undefined,

  DOWNLOAD_SAMPLE_FILE: undefined,
  DOWNLOAD_SAMPLE_FILE_SUCCESS: undefined,
  DOWNLOAD_SAMPLE_FILE_FAILURE: undefined,

  /**
   * Orders
   */
  ORDER_GET_LIST: undefined,
  ORDER_GET_LIST_SUCCESS: undefined,
  ORDER_GET_LIST_FAILURE: undefined,

  COMPLETED_ORDER_GET_LIST: undefined,
  COMPLETED_ORDER_GET_LIST_SUCCESS: undefined,
  COMPLETED_ORDER_GET_LIST_FAILURE: undefined,

  ORDER_GET_DETAILS: undefined,
  ORDER_GET_DETAILS_SUCCESS: undefined,
  ORDER_GET_DETAILS_FAILURE: undefined,

  PRODUCT_SEARCH_RESULT: undefined,
  PRODUCT_SEARCH_RESULT_SUCCESS: undefined,
  PRODUCT_SEARCH_RESULT_FAILURE: undefined,

  CHANGE_STOCK_STATUS: undefined,
  CHANGE_STOCK_STATUS_SUCCESS: undefined,
  CHANGE_STOCK_STATUS_FAILURE: undefined,

  CONFIRM_ORDER: undefined,
  CONFIRM_ORDER_SUCCESS: undefined,
  CONFIRM_ORDER_FAILURE: undefined,

  CONFIRM_PACKED: undefined,
  CONFIRM_PACKED_SUCCESS: undefined,
  CONFIRM_PACKED_FAILURE: undefined,

  CONFIRM_DELIVERY: undefined,
  CONFIRM_DELIVERY_SUCCESS: undefined,
  CONFIRM_DELIVERY_FAILURE: undefined,

  CONFIRM_DELIVERED: undefined,
  CONFIRM_DELIVERED_SUCCESS: undefined,
  CONFIRM_DELIVERED_FAILURE: undefined,

  CANCEL_ORDER: undefined,
  CANCEL_ORDER_SUCCESS: undefined,
  CANCEL_ORDER_FAILURE: undefined,

  UPDATE_DELIVERY: undefined,
  UPDATE_DELIVERY_SUCCESS: undefined,
  UPDATE_DELIVERY_FAILURE: undefined,

  GET_ORDER_LOCATION: undefined,
  GET_ORDER_LOCATION_SUCCESS: undefined,
  GET_ORDER_LOCATION_FAILURE: undefined,

  /**
   * Categories
   */

  LEVEL1_LIST_CATEGORIES: undefined,
  LEVEL1_LIST_CATEGORIES_SUCCESS: undefined,
  LEVEL1_LIST_CATEGORIES_FAILURE: undefined,

  LEVEL2_LIST_CATEGORIES: undefined,
  LEVEL2_LIST_CATEGORIES_SUCCESS: undefined,
  LEVEL2_LIST_CATEGORIES_FAILURE: undefined,

  LEVEL3_LIST_CATEGORIES: undefined,
  LEVEL3_LIST_CATEGORIES_SUCCESS: undefined,
  LEVEL3_LIST_CATEGORIES_FAILURE: undefined,

  CREATE_CATEGORY: undefined,
  CREATE_CATEGORY_SUCCESS: undefined,
  CREATE_CATEGORY_FAILURE: undefined,

  EDIT_CATEGORY: undefined,
  EDIT_CATEGORY_SUCCESS: undefined,
  EDIT_CATEGORY_FAILURE: undefined,

  DELETE_CATEGORY: undefined,
  DELETE_CATEGORY_SUCCESS: undefined,
  DELETE_CATEGORY_FAILURE: undefined,

  CLEAR_LEVEL_DATA: undefined,

  GET_ALL_CATEGORIES: undefined,
  GET_ALL_CATEGORIES_SUCCESS: undefined,
  GET_ALL_CATEGORIES_FAILURE: undefined,

  /**
   * Analytics
   */
  GET_ACCOUNT_STATUS: undefined,
  GET_ACCOUNT_STATUS_SUCCESS: undefined,
  GET_ACCOUNT_STATUS_FAILURE: undefined,

  REQUEST_VERIFICATION: undefined,
  REQUEST_VERIFICATION_SUCCESS: undefined,
  REQUEST_VERIFICATION_FAILURE: undefined,

  GET_TOTAL_ORDERS: undefined,
  GET_TOTAL_ORDERS_SUCCESS: undefined,
  GET_TOTAL_ORDERS_FAILURE: undefined,

  /**
   * Location
   */
  GET_GEO_LOCATION: undefined,
  GET_GEO_LOCATION_SUCCESS: undefined,
  GET_GEO_LOCATION_FAILURE: undefined,

  ADD_SHOP_DETAILS: undefined,
  ADD_SHOP_DETAILS_SUCCESS: undefined,
  ADD_SHOP_DETAILS_FAILURE: undefined,

  ADD_SHOP_ADDRESS: undefined,
  ADD_SHOP_ADDRESS_SUCCESS: undefined,
  ADD_SHOP_ADDRESS_FAILURE: undefined,

  ADD_SHOP_LOGO: undefined,
  ADD_SHOP_LOGO_SUCCESS: undefined,
  ADD_SHOP_LOGO_FAILURE: undefined,

  SET_SHOP_LOGO: undefined,
  SET_USER_INFO: undefined,

  GET_SHOP_DETAILS: undefined,
  GET_SHOP_DETAILS_SUCCESS: undefined,
  GET_SHOP_DETAILS_FAILURE: undefined,

  UPDATE_SHOP_SETTINGS: undefined,
  UPDATE_SHOP_SETTINGS_SUCCESS: undefined,
  UPDATE_SHOP_SETTINGS_FAILURE: undefined,

  UPDATE_USER_SETTINGS: undefined,
  UPDATE_USER_SETTINGS_SUCCESS: undefined,
  UPDATE_USER_SETTINGS_FAILURE: undefined,

  EDIT_SHOP_ADDRESS: undefined,
  EDIT_SHOP_ADDRESS_SUCCESS: undefined,
  EDIT_SHOP_ADDRESS_FAILURE: undefined,

  GET_DELIVERY_DETAILS: undefined,
  GET_DELIVERY_DETAILS_SUCCESS: undefined,
  GET_DELIVERY_DETAILS_FAILURE: undefined,

  UPDATE_DELIVERY_SETTINGS: undefined,
  UPDATE_DELIVERY_SETTINGS_SUCCESS: undefined,
  UPDATE_DELIVERY_SETTINGS_FAILURE: undefined,

  GET_PAYMENT_DETAILS: undefined,
  GET_PAYMENT_DETAILS_SUCCESS: undefined,
  GET_PAYMENT_DETAILS_FAILURE: undefined,

  UPDATE_PAYMENT_SETTINGS: undefined,
  UPDATE_PAYMENT_SETTINGS_SUCCESS: undefined,
  UPDATE_PAYMENT_SETTINGS_FAILURE: undefined,

  SEND_PAYMENT_REMINDER: undefined,
  SEND_PAYMENT_REMINDER_SUCCESS: undefined,
  SEND_PAYMENT_REMINDER_FAILURE: undefined,

  CHANGE_ACCEPT_STATUS: undefined,
  CHANGE_ACCEPT_STATUS_SUCCESS: undefined,
  CHANGE_ACCEPT_STATUS_FAILURE: undefined,

  /* Delivery partners     */

  GET_DELIVERY_PARTNERS: undefined,
  GET_DELIVERY_PARTNERS_SUCCESS: undefined,
  GET_DELIVERY_PARTNERS_FAILURE: undefined,

  CREATE_DELIVERY_PARTNER: undefined,
  CREATE_DELIVERY_PARTNER_SUCCESS: undefined,
  CREATE_DELIVERY_PARTNER_FAILURE: undefined,

  DELETE_DELIVERY_PARTNER: undefined,
  DELETE_DELIVERY_PARTNER_SUCCESS: undefined,
  DELETE_DELIVERY_PARTNER_FAILURE: undefined,

  GET_CADETS: undefined,
  GET_CADETS_SUCCESS: undefined,
  GET_CADETS_FAILURE: undefined,

  GET_CADET_COUNT: undefined,
  GET_CADET_COUNT_SUCCESS: undefined,
  GET_CADET_COUNT_FAILURE: undefined,

  CREATE_PARTNER: undefined,
  CREATE_PARTNER_SUCCESS: undefined,
  CREATE_PARTNER_FAILURE: undefined,

  EDIT_PARTNER: undefined,
  EDIT_PARTNER_SUCCESS: undefined,
  EDIT_PARTNER_FAILURE: undefined,

  DELETE_PARTNER: undefined,
  DELETE_PARTNER_SUCCESS: undefined,
  DELETE_PARTNER_FAILURE: undefined,

  GET_STATIONS: undefined,
  GET_STATIONS_SUCCESS: undefined,
  GET_STATIONS_FAILURE: undefined,

  CREATE_STATION: undefined,
  CREATE_STATION_SUCCESS: undefined,
  CREATE_STATION_FAILURE: undefined,

  EDIT_STATION: undefined,
  EDIT_STATION_SUCCESS: undefined,
  EDIT_STATION_FAILURE: undefined,

  DELETE_STATION: undefined,
  DELETE_STATION_SUCCESS: undefined,
  DELETE_STATION_FAILURE: undefined,

  REGISTER_MEMBER: undefined,
  REGISTER_MEMBER_SUCCESS: undefined,
  REGISTER_MEMBER_FAILURE: undefined,

  SHOP_GET_LIST: undefined,
  SHOP_GET_LIST_SUCCESS: undefined,
  SHOP_GET_LIST_FAILURE: undefined,

  GET_ACTIVE_RESERVATIONS: undefined,
  GET_ACTIVE_RESERVATIONS_SUCCESS: undefined,
  GET_ACTIVE_RESERVATIONS_FAILURE: undefined,

  GET_RESERVATIONS: undefined,
  GET_RESERVATIONS_SUCCESS: undefined,
  GET_RESERVATIONS_FAILURE: undefined,

  CHECKOUT_RESERVATION: undefined,
  CHECKOUT_RESERVATION_SUCCESS: undefined,
  CHECKOUT_RESERVATION_FAILURE: undefined,

  CHECKIN_RESERVATION: undefined,
  CHECKIN_RESERVATION_SUCCESS: undefined,
  CHECKIN_RESERVATION_FAILURE: undefined,

  GET_RESERVATION_COUNT: undefined,
  GET_RESERVATION_COUNT_SUCCESS: undefined,
  GET_RESERVATION_COUNT_FAILURE: undefined,

  GET_RESERVATION_DETAILS: undefined,
  GET_RESERVATION_DETAILS_SUCCESS: undefined,
  GET_RESERVATION_DETAILS_FAILURE: undefined,

  GET_ORDER_REPORT: undefined,
  GET_ORDER_REPORT_SUCCESS: undefined,
  GET_ORDER_REPORT_FAILURE: undefined,

  CHANGE_PAYMENT_STATUS: undefined,
  CHANGE_PAYMENT_STATUS_SUCCESS: undefined,
  CHANGE_PAYMENT_STATUS_FAILURE: undefined,

  CHANGE_ITEM_QUANTITY: undefined,
  CHANGE_ITEM_QUANTITY_SUCCESS: undefined,
  CHANGE_ITEM_QUANTITY_FAILURE: undefined,

  GET_PLAN_PRICING: undefined,
  GET_PLAN_PRICING_SUCCESS: undefined,
  GET_PLAN_PRICING_FAILURE: undefined,

  GET_SUBSCRIPTION_PAYMENT_LINK: undefined,
  GET_SUBSCRIPTION_PAYMENT_LINK_SUCCESS: undefined,
  GET_SUBSCRIPTION_PAYMENT_LINK_FAILURE: undefined
})

/**
 * @constant {Object} STATUS
 * @memberof Constants
 */
export const STATUS = {
  IDLE: 'idle',
  RUNNING: 'running',
  READY: 'ready',
  SUCCESS: 'success',
  ERROR: 'error'
}

// const API_BASE_URL = "http://localhost:5000/api";
// const SHOP_GROUP_BASE_URL = 'https://shop-webserver.herokuapp.com/shops'
// const API_BASE_URL = 'https://shop-webserver.herokuapp.com/business'

const API_BASE_URL = 'https://api-shop.inventlabs.org/business'

const GOOGLE_GEO_LOCATION_URL =
  // 'https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyBujK5bEmXsfoAFjcA2rmF6PFBSoHziQ-I&'
  'https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyCXNvB8u-tKjRrAE52gHvWVqhBm5yDXd6k'

/**
 * @constant {Object} ACCOUNT_API
 * @memberof Constants
 */
export const APIEndpoints = {
  /**
   * Account
   */
  LOGIN: `${API_BASE_URL}/login`,
  SIGNUP: `${API_BASE_URL}/signup`,
  LOGOUT: `${API_BASE_URL}/logout`,
  REFRESH_TOKEN: `${API_BASE_URL}/refresh_token`,
  FORGOT_PASSWORD: `${API_BASE_URL}/forgot_password`,
  RESET_PASSWORD: `${API_BASE_URL}/reset_password_otp`,
  CHANGE_PASSWORD: `${API_BASE_URL}/change_password`,

  GET_SHOP_CATEGORIES: `${API_BASE_URL}/shops/list_shop_item_categories`,
  GET_SHOP_TYPES: `${API_BASE_URL}/shops/list_shop_types`,
  STATISTICS: (shopId) => `${API_BASE_URL}/stat/${shopId}`,
  GET_ACCOUNT_STATUS: (shopId) => `${API_BASE_URL}/dashboard/${shopId}`,
  GET_TOTAL_ORDERS: (shopId) => `${API_BASE_URL}/billing_dashboard/${shopId}`,
  REQUEST_VERIFICATION: (shopId) =>
    `${API_BASE_URL}/shops/${shopId}/request_verification`,
  ADD_SHOP_ADDRESS: (shopId) =>
    `${API_BASE_URL}/shops/${shopId}/add_shop_address`,
  EDIT_SHOP_ADDRESS: (shopId) =>
    `${API_BASE_URL}/shops/${shopId}/change_address`,
  UPLOAD_SHOP_LOGO: (shopId) => `${API_BASE_URL}/shops/${shopId}/business-logo`,
  ORDER_LIST: (
    shopId,
    limit,
    skip,
    status,
    searchString,
    startingDate,
    endingDate
  ) =>
    status && status !== 'all'
      ? `${API_BASE_URL}/shops/${shopId}/open_orders?limit=${limit}&skip=${skip}&filter=${status}${searchString ? `&search=${searchString}` : ''
      }&start_ts=${startingDate}&end_ts=${endingDate}`
      : `${API_BASE_URL}/shops/${shopId}/open_orders?limit=${limit}&skip=${skip}${searchString ? `&search=${searchString}` : ''
      }&start_ts=${startingDate}&end_ts=${endingDate}`,
  COMPLETED_ORDER_LIST: (
    shopId,
    limit,
    skip,
    status,
    searchString,
    startingDate,
    endingDate
  ) =>
    status && status !== 'all'
      ? `${API_BASE_URL}/shops/${shopId}/completed_orders?limit=${limit}&skip=${skip}&filter=${status}${searchString ? `&search=${searchString}` : ''
      }&start_ts=${startingDate}&end_ts=${endingDate}`
      : `${API_BASE_URL}/shops/${shopId}/completed_orders?limit=${limit}&skip=${skip}${searchString ? `&search=${searchString}` : ''
      }&start_ts=${startingDate}&end_ts=${endingDate}`,
  ORDER_GET_DETAILS: (shopId, orderId) =>
    `${API_BASE_URL}/shops/${shopId}/order/${orderId}`,
  CONFIRM_ORDER: (shopId, orderId) =>
    `${API_BASE_URL}/shops/${shopId}/order/${orderId}/confirm`,
  CONFIRM_PACKED: (shopId, orderId) =>
    `${API_BASE_URL}/shops/${shopId}/order/${orderId}/packed`,
  CONFIRM_DELIVERY: (shopId, orderId) =>
    `${API_BASE_URL}/shops/${shopId}/order/${orderId}/delivery`,
  CONFIRM_DELIVERED: (shopId, orderId) =>
    `${API_BASE_URL}/shops/${shopId}/order/${orderId}/delivered`,
  CANCEL_ORDER: (shopId, orderId) =>
    `${API_BASE_URL}/shops/${shopId}/order/${orderId}/cancel`,
  UPDATE_DELIVERY: (shopId, orderId) =>
    `${API_BASE_URL}/shops/${shopId}/order/${orderId}/change_delivery_time`,
  CHANGE_PAYMENT_STATUS: (shopId, orderId) =>
    `${API_BASE_URL}/shops/${shopId}/order/${orderId}/change_payment_status`,

  CHANGE_ITEM_QUANTITY: (shopId, orderId) =>
    `${API_BASE_URL}/shops/${shopId}/order/${orderId}/change_items`,

  PRODUCT_LIST: (shopId, limit, skip, searchString, filter) =>
    searchString
      ? `${API_BASE_URL}/shops/${shopId}/items?limit=${limit}&skip=${skip}&search=${searchString}${filter ? `&filter=${filter}` : ''
      }`
      : `${API_BASE_URL}/shops/${shopId}/items?limit=${limit}&skip=${skip}${filter ? `&filter=${filter}` : ''
      }`,
  PRODUCT_DETAILS: (shopId, productId) =>
    `${API_BASE_URL}/shops/${shopId}/items/${productId}`,

  UPLOAD_PRODUCT_IMAGE_URL: (shopId) =>
    `${API_BASE_URL}/shops/${shopId}/item-image`,
  UPDATE_PRODUCT_URL: (shopId, productId) =>
    `${API_BASE_URL}/shops/${shopId}/item/${productId}`,
  DELETE_PRODUCT_URL: (shopId, productId) =>
    `${API_BASE_URL}/shops/${shopId}/item/${productId}`,
  CREATE_PRODUCT_URL: (shopId) => `${API_BASE_URL}/shops/${shopId}/item`,
  SEARCH_PRODUCT_LIST_URL: (searchString) =>
    `${API_BASE_URL}/master-item?keyword=${searchString}`,
  CHANGE_STOCK_STATUS: (shopId, itemId) =>
    `${API_BASE_URL}/shops/${shopId}/change_stock/${itemId}`,
  UPLOAD_BULK_PRODUCTS: (shopId) =>
    `${API_BASE_URL}/shops/${shopId}/import_products`,
  DOWNLOAD_SAMPLE_EXCEL_FILE: (shopId) =>
    `${API_BASE_URL}/shop/${shopId}/download_products`,

  CATEGORY_LIST: (shopId, level, parent) =>
    parent
      ? `${API_BASE_URL}/shops/${shopId}/item_categories?level=${level}&parent_id=${parent}`
      : `${API_BASE_URL}/shops/${shopId}/item_categories?level=1`,
  CREATE_CATEGORY: (shopId) => `${API_BASE_URL}/shops/${shopId}/item_category`,
  EDIT_CATEGORY: (shopId, id) =>
    `${API_BASE_URL}/shops/${shopId}/item_category/${id}`,
  GET_ALL_CATEGORIES: (shopId) =>
    `${API_BASE_URL}/shops/${shopId}/item_category_tree`,
  DELETE_CATEGORY: (shopId, id) =>
    `${API_BASE_URL}/shops/${shopId}/item_category/${id}`,

  GEO_LOCATION: (location) => `${GOOGLE_GEO_LOCATION_URL}&${location}`,
  ADD_SHOP_DETAILS: `${API_BASE_URL}/shops/add_shop_info`,
  ADD_SHOP_LOGO: (shopId) => `${API_BASE_URL}/shops/${shopId}/edit_logo_url`,
  GET_SHOP_DETAILS: (shopId) => `${API_BASE_URL}/shops/${shopId}/shop_settings`,
  UPDATE_SHOP_SETTINGS: (shopId) =>
    `${API_BASE_URL}/shops/${shopId}/shop_settings`,
  UPDATE_USER_SETTINGS: `${API_BASE_URL}/shops/user_settings`,
  GET_DELIVERY_DETAILS: (shopId) =>
    `${API_BASE_URL}/shops/${shopId}/delivery_options`,
  UPDATE_DELIVERY_SETTINGS: (shopId) =>
    `${API_BASE_URL}/shops/${shopId}/delivery_options`,
  GET_PAYMENT_DETAILS: (shopId) => `${API_BASE_URL}/shops/${shopId}/upi_id`,
  UPDATE_PAYMENT_SETTINGS: (shopId) => `${API_BASE_URL}/shops/${shopId}/upi_id`,
  SEND_PAYMENT_REMINDER: (shopId) =>
    `${API_BASE_URL}/shops/${shopId}/notify_payment`,
  CHANGE_ACCEPT_STATUS: (shopId) =>
    `${API_BASE_URL}/shops/${shopId}/change_status`,
  GET_ORDER_LOCATION: (shopId) => `${API_BASE_URL}/shops/${shopId}/order_map`,

  GET_DELIVERY_PARTNERS: (shopId) =>
    `${API_BASE_URL}/shops/${shopId}/list_volunteer`,
  CREATE_DELIVERY_PARTNER: (shopId) =>
    `${API_BASE_URL}/shops/${shopId}/add_volunteer`,
  DELETE_DELIVERY_PARTNER: (shopId) =>
    `${API_BASE_URL}/shops/${shopId}/remove_volunteer`,

  GET_CADETS: (shopId, limit, skip, searchstring) =>
    searchstring
      ? `${API_BASE_URL}/shops/cpc/${shopId}/list_police?limit=${limit}&skip=${skip}&keyword=${searchstring}`
      : `${API_BASE_URL}/shops/cpc/${shopId}/list_police?limit=${limit}&skip=${skip}`,
  GET_CADET_COUNT: (shopId) =>
    `${API_BASE_URL}/shops/cpc/${shopId}/whitelist_count`,
  CREATE_PARTNER: (shopId) => `${API_BASE_URL}/shops/cpc/${shopId}/add_police`,
  EDIT_PARTNER: (shopId, id) =>
    `${API_BASE_URL}/shops/cpc/${shopId}/edit_police/${id}`,
  DELETE_PARTNER: (shopId) => `${API_BASE_URL}/shops/cpc/${shopId}/remove_user`,

  GET_STATIONS: (shopId, limit, skip, searchstring) =>
    searchstring
      ? `${API_BASE_URL}/shops/cpc/${shopId}/branch?limit=${limit}&skip=${skip}&keyword=${searchstring}`
      : `${API_BASE_URL}/shops/cpc/${shopId}/branch?limit=${limit}&skip=${skip}`,
  CREATE_STATION: (shopId) => `${API_BASE_URL}/shops/cpc/${shopId}/branch`,
  EDIT_STATION: (shopId, id) =>
    `${API_BASE_URL}/shops/cpc/${shopId}/branch/${id}`,
  DELETE_STATION: (shopId) => `${API_BASE_URL}/shops/cpc/${shopId}/branch`,

  REGISTER_MEMBER: (shopId) =>
    `${API_BASE_URL}/shops/request_membership_token/${shopId}`,
  SHOP_GET_LIST: (id) => `${API_BASE_URL}/shops/shop_group_reserve/${id}`,
  GET_ACTIVE_RESERVATIONS: (shopId, limit, skip, searchstring) =>
    searchstring
      ? `${API_BASE_URL}/shops/${shopId}/reservations?status=checked_in&limit=${limit}&skip=${skip}&keyword=${searchstring}`
      : `${API_BASE_URL}/shops/${shopId}/reservations?status=checked_in&limit=${limit}&skip=${skip}`,
  GET_RESERVATIONS: (date, shopId, limit, skip, searchstring) =>
    searchstring
      ? `${API_BASE_URL}/shops/${shopId}/reservations?start_ts=${date}&limit=${limit}&skip=${skip}&keyword=${searchstring}`
      : `${API_BASE_URL}/shops/${shopId}/reservations?start_ts=${date}&limit=${limit}&skip=${skip}`,
  CHECKOUT_RESERVATION: (id) =>
    `${API_BASE_URL}/shops/${id}/reservation/checkout`,
  CHECKIN_RESERVATION: (id) =>
    `${API_BASE_URL}/shops/${id}/reservation/checkin`,
  GET_RESERVATION_COUNT: (id) => `${API_BASE_URL}/shops/${id}/currently_in`,
  GET_RESERVATION_DETAILS: (shopId, id) =>
    `${API_BASE_URL}/shops/${shopId}/reservation_open/${id}`,
  GET_ORDER_REPORT: (shopId, from, to, status) =>
    `${API_BASE_URL}/shops/${shopId}/order_report?start_ts=${from}&end_ts=${to}${status === 'unpaid'
      ? '&paid=false'
      : status === 'paid'
        ? '&paid=true'
        : ''
    }`,

  GET_PLAN_PRICING: `${API_BASE_URL}/shops/pricing-plans`,
  GET_SUBSCRIPTION_PAYMENT_LINK: (shopId, planId) =>
    `${API_BASE_URL}/shops/${shopId}/payment-details?code=${planId}`
}
