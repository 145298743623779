import React, { Component } from 'react'
import { Grid, Typography, withStyles, Paper, Button, StepContent, StepLabel, Step, Stepper } from '@material-ui/core'

import compose from 'recompose/compose'
import { connect } from 'react-redux'
import treeChanges from 'tree-changes'
import { STATUS } from 'redux/constants'

import { AddShopLogo, AddProducts, Submit } from './components'
import { PaymentDetails, DeliverySettings } from 'views/Settings/components'

import style from './style'

class VerificationProgress extends Component {
  state = {
    activeStep: 0,
    buttonActive: false
  }

  componentDidMount = () => {
    const { details } = this.props
    const verificationSteps = details.data.verification_progress
    if (verificationSteps.request_verification && verificationSteps.first_product && verificationSteps.payment_settings && verificationSteps.delivery_options && verificationSteps.added_logo) {
      this.setState({ activeStep: 5 })
    } else if (verificationSteps.first_product && verificationSteps.payment_settings && verificationSteps.delivery_options && verificationSteps.added_logo) {
      this.setState({ activeStep: 4 })
    } else if (verificationSteps.payment_settings && verificationSteps.delivery_options && verificationSteps.added_logo) {
      this.setState({ activeStep: 3 })
    } else if (verificationSteps.delivery_options && verificationSteps.added_logo) {
      this.setState({ activeStep: 2 })
    } else if (verificationSteps.added_logo) {
      this.setState({ activeStep: 1 })
    }
  }

  componentWillReceiveProps = newProps => {
    const { changedTo } = treeChanges(this.props, newProps)
    const { activeStep } = this.state
    if (activeStep === 0 && changedTo('shopLogo.status', STATUS.READY)) {
      this.setState({ buttonActive: true })
    }
    if (activeStep === 1 && changedTo('editDeliverySettings.status', STATUS.READY)) {
      this.setState({ buttonActive: true })
    }
    if (activeStep === 2 && changedTo('editPaymentSettings.status', STATUS.READY)) {
      this.setState({ buttonActive: true })
    }
  }

  handleNext = () => {
    this.setState({ activeStep: this.state.activeStep + 1, buttonActive: false })
  };

  gotoWhatsAppChat = () => {
    const link = `https://api.whatsapp.com/send/?phone=917736043121`
    window.open(link)
  }

  render () {
    const { classes, details } = this.props
    const { activeStep, buttonActive } = this.state

    const getSteps = () => {
      return ['Add Shop Logo', 'Delivery Settings', 'Add Payment Options', 'Add Products', 'Submit for review']
    }
    const steps = getSteps()

    const getStepContent = (step) => {
      switch (step) {
        case 0:
          return <AddShopLogo shop={this.props.details.data} />
        case 1:
          return <DeliverySettings />
        case 2:
          return <PaymentDetails />
        case 3:
          return <AddProducts />
        case 4:
          return <Submit requested={this.props.details.data.verification_progress.request_verification} />
        case 5:
          return 'Completed'
        default:
          return 'Invalid'
      }
    }

    return (
      <Paper square elevation={0} className={classes.root}>
        <Grid container justify='space-between'>
          <Typography variant='h2' className={classes.heading}>Complete Registration</Typography>
          <Button className={classes.button} color='primary' size='small' variant='contained' onClick={this.gotoWhatsAppChat} disableElevation><img src='/images/whatsapp.png' alt='whatsapp' width='22px' height='22px' /> Chat on WhatsApp</Button>
        </Grid>
        <Stepper activeStep={activeStep} orientation='vertical' className={classes.verificationSteps}>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
              <StepContent>
                <Typography>{getStepContent(index)}</Typography>
                <div className={classes.actionsContainer}>
                  {activeStep < 3
                    ? <Button
                      variant='contained'
                      color='primary'
                      onClick={this.handleNext}
                      disabled={!buttonActive}
                    >
                      Next
                    </Button>
                    : null}
                </div>
              </StepContent>
            </Step>
          ))}
        </Stepper>
        {
          details.data.verification_progress.request_verification && (
            <React.Fragment>
              <Typography className={classes.submittedHeading}>Verification in progress</Typography>
              <Typography className={classes.finishedText}>Shop verification will take upto 24 hours to complete. Contact customer support if you need any assistance.</Typography>
            </React.Fragment>
          )
        }
      </Paper >
    )
  }
}

function mapStateToProps (state) {
  return {
    shopLogo: state.settings.addShopLogo,
    editDeliverySettings: state.settings.editDeliverySettings,
    editPaymentSettings: state.settings.editPaymentSettings
  }
}

export default compose(
  withStyles(style)
)(connect(mapStateToProps)(VerificationProgress))
