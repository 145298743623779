import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import compose from 'recompose/compose'
import { withStyles } from '@material-ui/styles'
import { Grid, Typography } from '@material-ui/core'

import Header from '../../components/Header'

import { Totalinsights, Features, RegistrationProcess, Footer, ContactUs } from './components'
import SignUp from '../SignUp/index'

const style = theme => ({
  container: {
    width: '100%',
    overflow: 'hidden'
  },
  heading: {
    marginTop: '10px',
    fontSize: '29px',
    textAlign: 'center',
    fontFamily: 'Poppins, sans-serif',

    fontWeight: '500'
  },
  youtubeVideoConatiner: {
    margin: '100px 0 50px',
    textAlign: 'center'
  },
  youtubeVideo: {
    marginTop: '80px',
    boxShadow: '0 0 150px rgba(57, 177, 87, 0.253)',
    width: '50vw',
    height: '30vw',
    [theme.breakpoints.down('sm')]: {
      width: '90vw',
      height: '60vw'
    }
  },
  signupContainer: {
    marginTop: '70px'
  }
})

class Home extends Component {
  render () {
    const { classes } = this.props
    return (
      <>
        <Header />
        <div className={classes.container}>
          <div className={classes.signupContainer}>
            <SignUp />
          </div>
          <Totalinsights />
          {/* <Testimonials /> */}
          <Features />
          <RegistrationProcess />
          <Grid className={classes.youtubeVideoConatiner}>
            <Typography className={classes.heading}>How to create your own online store?</Typography>
            <iframe className={classes.youtubeVideo} title='YouTube' src='https://www.youtube.com/embed/n2WMhIijiqE?rel=0' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowfullscreen />
          </Grid>
          <ContactUs />
          <Footer />
        </div>
      </>
    )
  }
}

export default compose(
  withStyles(style), withRouter
)((Home))
