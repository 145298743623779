import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/styles'
import Paper from '@material-ui/core/Paper'
import LanguageIcon from '@material-ui/icons/Language'
import StoreIcon from '@material-ui/icons/Store'
import GetAppIcon from '@material-ui/icons/GetApp'

const styles = theme => ({
  container: {
    width: '100vw !important'
  },
  heading: {
    textAlign: 'center',
    margin: '100px 20px 10px',
    fontFamily: 'Poppins, sans-serif',
    [theme.breakpoints.down('md')]: {
      margin: '50px 20px 10px'
    }
  },
  subtitle: {
    margin: '0px 20vw 0px',
    fontFamily: 'Poppins, sans-serif',
    [theme.breakpoints.down('md')]: {
      margin: '0px 10vw 0px'
    }
  },
  h1: {
    fontFamily: 'Poppins, sans-serif'

  },
  boxContainer: {
    paddingTop: '100px',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '80% !important'
  },
  box: {
    width: 'auto',
    minWidth: '120px',
    height: '50px',
    boxShadow: '0 3px 3px rgba(0,0,0,0.2)',
    borderRadius: '15px'
  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
    maxWidth: 250,
    borderRadius: '25px'
  },
  icon: {
    margin: 'auto 10px auto'
  },
  boxText: {
    fontFamily: 'Poppins, sans-serif'
  }
})

class Totalinsights extends Component {
  render () {
    const { classes } = this.props

    return (
      <div className={classes.container}>
        <Grid>
          <Grid item xs={12} className={classes.heading}>
            <Typography variant='h1' component='h2' gutterBottom className={classes.h1}>
              Create your own online store on ShopsApp            </Typography>
            <Typography variant='subtitle1' gutterBottom className={classes.subtitle}>
              Create a store and add products you want to sell. Set a delivery area. Customers in the location will be able to order from your online store.            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} className={classes.boxContainer}>
          <Grid item xs={12} sm={12} lg={4} md={4}>
            <Paper className={classes.paper} elevation={3}>
              <Grid container spacing={2}>
                <Grid item className={classes.icon}>
                  <LanguageIcon fontSize='large' />
                </Grid>
                <Grid item>
                  <Grid item direction='column' spacing={2}>
                    <Grid item xs>
                      <Typography variant='subtitle1' className={classes.boxText}>
                        400+ Shops
                      </Typography>
                      <Typography variant='body2' gutterBottom className={classes.boxText}>
                        in Kerala
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} lg={4} md={4}>
            <Paper className={classes.paper} elevation={3}>
              <Grid container spacing={2}>
                <Grid item className={classes.icon}>
                  <StoreIcon fontSize='large' />
                </Grid>
                <Grid item>
                  <Grid item direction='column' spacing={2}>
                    <Grid item xs>
                      <Typography variant='subtitle1' className={classes.boxText}>
                        10k+
                      </Typography>
                      <Typography variant='body2' gutterBottom className={classes.boxText}>
                        Orders Delivered
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} lg={4} md={4}>
            <Paper className={classes.paper} elevation={3}>
              <Grid container spacing={2}>
                <Grid item className={classes.icon}>
                  <GetAppIcon />
                </Grid>
                <Grid item>
                  <Grid item direction='column' spacing={2}>
                    <Grid item xs>
                      <Typography variant='subtitle1' className={classes.boxText}>
                        60K +
                      </Typography>
                      <Typography variant='body2' gutterBottom className={classes.boxText}>
                        App Downloads
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default withStyles(styles)(Totalinsights)
